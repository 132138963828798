import {BrowserRouter, Route, Switch} from "react-router-dom";
import './App.css';
import Home from './Home';
import MatriculaExpress from './MatriculaExpress';
import Obrigado from './obrigado';
import TicketsDashboard from './TicketsDashboard';
import React, {useEffect} from 'react';
import MatriculaImplacavel from './MatriculaImplacavel';
import LoadingPleaseWait from './LoadingPleaseWait';
import { createMuiTheme, ThemeProvider} from '@material-ui/core';
import Support from './Support'
import SignUp from './SignUp'
import { AuthProvider } from "./context/AuthContext";
import { SnackProvider } from './context/SnackContext';
import { MUIBreakpointsProvider } from "./context/MUIBreakpointsContext"; 
import Dashboard from "./Dashboard";
import Login from "./Login";
import Perpetua from "./Perpetua";
import SpeedLottie from './components/speedLottie.jsx';
import Higor2 from './images/IMG_9339 (1).jpg'
import AmUp from "./AmUp";
import AmDown from "./AmDown";
import ClassVideoWithInteraction from "./ClassVideoWithInteraction";
import Experimental from "./Experimental";
import ReferralUI from "./ReferralUI";
import { ReferralContextProvider } from "./context/ReferralContext";
import { LoadingContextProvider } from "./context/LoadingContext";
import EscolaDeMatriculasLogo from './images/3 (1).png'
import TermosDeUsoEPrivacidade from './pages/TermosDeUsoEPrivacidade'
import { useLocation, Redirect, useHistory } from "react-router-dom";
import NewMi from './pages/NewMi'
import AnnouncementBar from './components/blackFridayUi/AnnouncementBar.jsx'
import Blog from './pages/Blog'
import { IntensivaoDeMatriculas } from "./pages/IntensivaoDeMatriculas"
import LancamentoDois from "./pages/LancamentoDois";
import LancamentoDoisShort from "./pages/LancamentoDoisShort";
import WorkshopIntensivao from './pages/WorkshopIntensivao/WorkshopIntensivao'

 function ScrollToTop() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
   

class App extends React.Component {
   

  constructor(props) {
   super(props);
   this.state = {
     // Those states below are to be used in case of URL manipulation, such as an 'Obrigado' page
     // to have an URl changed to have a 'nome' and a 'email'.
   formRelatednomeCapturado: null,
   formRelatedemailCapturado: null,           
    };
   this.listOfAllowedPages = [
     '/jm1',
     '/jm2',
     '/jm3',
     '/jmobrigado1',
     '/jmobrigado2',
     '/jmobrigado3',    
     '/classvideowithinteraction',
     '/workshopintensivaotest',
    //  '/mexn',
    //  '/mex',
    //  '/mex2',
    //  '/mex3',
    //  '/mex4',
    //  '/mex5',
    //  '/mex6',
    //  '/mex7',
     '/mexobrigado',
     '/mexobrigado2',
     '/nmi',
     '/nmi/amex',
     '/nmi/emex',
     '/nmi/fmex',
     '/nmi/imex',
     '/nmi/wmex',
     '/nmi/wvmex',
     '/nmi/ymex',
     '/nmi/npmex',
     '/amup',
     '/amdown',
   ] 

  }

 // need the url to redirect to another page if it's not the page we want

 

 
  
  
    // The callbackFunction below is going to work by capturing the data from the children
    // [in this case 'matriculaexpress'] and returning to the app to be used by 'obrigado'
    callbackFunction = (type, childData2) => {
     if (type === "nome")      
     {
      this.setState({
        formRelatednomeCapturado: childData2    
        })
     }
     if (type === "email")      
     {
      this.setState({
        formRelatedemailCapturado: childData2     
      })
     }

     console.log(this.state);

    }
         
    higorfabianoTheme = createMuiTheme({
      palette:{
        primary:{
          main: '#ff6a00'
        },
        secondary: {
          main:'#34d399'
        },
        tertiary:{
          main:'#2c2c2c'
        }

        
      }
    
    })


  render(){

    
  
  return (
    

    <SnackProvider>
    <AuthProvider>
    <ThemeProvider  theme={this.higorfabianoTheme}>
    <MUIBreakpointsProvider>
    <LoadingContextProvider>
    <ReferralContextProvider>  
    <div className="App overflow-x-hidden">
     <BrowserRouter>
      {/* <AnnouncementBar/>  */}
     <ScrollToTop />
    
     <Switch>
   

      { this.listOfAllowedPages.indexOf(window.location.pathname) > -1 ?         
      <>          
         <Route component={Home} path='/' exact />   
  
         <Route component={NewMi} path='/newmi' exact />   


         <Route
          path='/jm1'
          exact  
          render={()=>
         <LancamentoDoisShort paginaDeObrigado={`/jmobrigado1`}/>
         } />  

        <Route
          path='/workshopintensivaotest'
          exact  
          render={()=>
         <WorkshopIntensivao/>
         } />  


         <Route
          path='/jm2'
          exact  
          render={()=>
         <LancamentoDois paginaDeObrigado={`/jmobrigado2`}/>
         } />  

         <Route
          path='/jm3'
          exact  
          render={()=>
         <LancamentoDois paginaDeObrigado={`/jmobrigado3`}/>
         } />     

         <Route path='/jmobrigado1' render={(props)=><Obrigado {...props} 
         tituloDoCurso={<p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Falta pouco para você garantir sua vaga NA JORNADA DO MATRICULADOR!</p>}
         whatsAppHref="https://tiny.cc/matlink"
         dataFromParent = {this.state}
         mode={'jm'} />}    
     
         />

        <Route path='/jmobrigado2' render={(props)=><Obrigado {...props} 
         tituloDoCurso={<p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Falta pouco para você garantir sua vaga NA JORNADA DO MATRICULADOR!</p>}
         whatsAppHref="https://tiny.cc/matlink"
         dataFromParent = {this.state}
         mode={'jm'} />}    
      
         />

        <Route path='/jmobrigado3' render={(props)=><Obrigado {...props} 
         tituloDoCurso={<p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Falta pouco para você garantir sua vaga NA JORNADA DO MATRICULADOR!</p>}
         noButtons={true}
         dataFromParent = {this.state}
         mode={'jm'} />}  
       
         />
    

         <Route
          path='/mexn'
          exact  
          render={(props)=>
          <MatriculaExpress
          {...props} 
          type={3.1}
          pageVersion={'default'}
          alternativeIcons ={false}
          alternativeHeroLayout = {false}
          headerText='Matrícula Express'
          mainHeroData = 'Treinamento gratuito de 21 a 24 de Março às 20h22min (horário de Brasília)'
          
          //choose between lottie or image
          isItLottie = {true}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
  } />  


         <Route
          path='/mex'
          exact  
          render={(props)=>
          <MatriculaExpress
          {...props} 
          type={3}
          pageVersion={'default'}
          alternativeIcons ={false}
          alternativeHeroLayout = {false}
          headerText='Matrícula Express'
          mainHeroData = 'Treinamento gratuito de 21 a 24 de Março às 20h22min (horário de Brasília)'
          
          //choose between lottie or image
          isItLottie = {true}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
  } />  

<Route
          path='/mex3'
          exact  
          render={(props)=>
          <MatriculaExpress
          {...props} 
          type={4}
          pageVersion={'default'}
          alternativeIcons ={false}
          alternativeHeroLayout = {false}
          headerText='Matrícula Express'
          mainHeroData = 'Treinamento gratuito de 21 a 24 de Março às 20h22min (horário de Brasília)'
          
          //choose between lottie or image
          isItLottie = {true}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
  } />  

<Route
          path='/mex4'
          exact  
          render={(props)=>
          <MatriculaExpress
          {...props} 
          type={5}
          pageVersion={'default'}
          alternativeIcons ={false}
          alternativeHeroLayout = {false}
          headerText='Matrícula Express'
          mainHeroData = 'Treinamento gratuito de 21 a 24 de Março às 20h22min (horário de Brasília)'
          
          //choose between lottie or image
          isItLottie = {true}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
  } />  

<Route
          path='/mex5'
          exact  
          render={(props)=>
          <MatriculaExpress
          {...props} 
          type={6}
          pageVersion={'default'}
          alternativeIcons ={false}
          alternativeHeroLayout = {false}
          headerText='Matrícula Express'
          mainHeroData = 'Treinamento gratuito de 21 a 24 de Março às 20h22min (horário de Brasília)'
          
          //choose between lottie or image
          isItLottie = {true}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
  } />  

<Route
          path='/mex6'
          exact  
          render={(props)=>
          <MatriculaExpress
          {...props} 
          type={7}
          pageVersion={'default'}
          alternativeIcons ={false}
          alternativeHeroLayout = {false}
          headerText='Matrícula Express'
          mainHeroData = 'Treinamento gratuito de 21 a 24 de Março às 20h22min (horário de Brasília)'
          
          //choose between lottie or image
          isItLottie = {true}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
  } />  

<Route
          path='/mex7'
          exact  
          render={(props)=>
          <MatriculaExpress
          {...props} 
          type={8}
          pageVersion={'default'}
          alternativeIcons ={false}
          alternativeHeroLayout = {false}
          headerText='Matrícula Express'
          mainHeroData = 'Treinamento gratuito de 21 a 24 de Março às 20h22min (horário de Brasília)'
          
          //choose between lottie or image
          isItLottie = {true}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
  } />  
  
  <Route
          path='/mex2'              
          render={(props)=>
          <MatriculaExpress
          {...props} 
          type={2}
          pageVersion={'default'}
          alternativeIcons ={false}
          alternativeHeroLayout = {false}
          headerText='Matrícula Express'
          mainHeroData = ''
         
          //choose between lottie or image
          isItLottie = {true}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
  } />  
  
    <Route
          path='/mexexperimental'
          exact  
          render={(props)=>
          <MatriculaExpress
          {...props} 
          type={1}
          pageVersion={'default'}
          alternativeIcons ={false}
          alternativeHeroLayout = {false}
          headerText='Matrícula Express'
          mainHeroData = 'Treinamento gratuito de 21 a 24 de Março às 20h22min (horário de Brasília)'
          
          //choose between lottie or image
          isItLottie = {true}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
  } />
  
         <Route path='/oldmex' render={(props)=>
          <MatriculaExpress
          {...props}
          
          pageVersion={'oldmex'}
          alternativeHeroLayout = {true}
          headerTextElement={<p className="text-gray-100 noto-sans text-3xl text-center font-sans text-opacity-90 mb-6 md:text-5xl"> A solução definitiva para você matricular <strong className="text-custom-orange-default"> 4&nbsp;alunos </strong> a cada <strong className="text-custom-orange-default">5&nbsp;atendimentos</strong></p>}
          observationText = 'mesmo que você receba objeções todos os dias'
          mainHeroText = 'Treinamento Matrícula Express'
          mainHeroData = 'De 12 a 15 de Julho às 20h21min (horário de Brasília)'
          //choose between lottie or image
          isItLottie = {false}
          mainHeroImage = {Higor2} 
  
  
          parentCallback = {this.callbackFunction}
  
          />}
           /> 
  
  <Route
          path='/matriculaexpress'
          render={(props)=>
          <MatriculaExpress
          {...props} 
          pageVersion={'newDefault'}
          alternativeIcons ={true}
          alternativeHeroLayout = {true}      
          headerTextElement={<p className="text-gray-100 noto-sans pt-4 text-2xl text-center font-sans text-opacity-90 mb-6 md:text-5xl"> Faça o dobro de matrículas trabalhando  <strong className="text-custom-orange-default">MENOS DE 8 HORAS POR DIA! </strong> </p>}
          
          mainHeroText = 'Treinamento Matrícula Express'
          mainHeroImage = {Higor2} 
          mainLogo = {EscolaDeMatriculasLogo}
          mainHeroData = 'De 13 a 16 de Setembro às 20h21min(horário de Brasília)'
          
          //choose between lottie or image
          isItLottie = {false}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
  } />  
  
  <Route
          path='/matriculaexpressoldicons'
          render={(props)=>
          <MatriculaExpress
          {...props} 
          alternativeHeroLayout = {false}
          headerText='Matrícula Express'
          mainHeroData = 'De 12 a 15 de Julho às 20h21min (horário de Brasília)'
          
          //choose between lottie or image
          isItLottie = {true}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
  } />  
  
         <Route path='/ticketsdashboard' render={(props)=><TicketsDashboard {...props} parentCallback = {this.callbackFunction}/>} />
         <Route path='/suporte' render={()=><Support />} />
  
         <Route path='/intensivaodematriculas-kjk1' exact render={()=><IntensivaoDeMatriculas
          headline="Seu Script de Venda Não Funciona Mais?"
          subHeadline="Aprenda o passo a passo para montar um script que converte em matrículas do absoluto zero e nunca mais dependa de ninguém" />}
           />
           
          <Route path='/intensivaodematriculas-szp2' exact render={()=><IntensivaoDeMatriculas
          headline="Quer dobras as suas Matrículas todos os meses?"
          subHeadline="O Passo a passo para você ter seu Script Implacável do absoluto zero" />}
           /> 
  
          <Route path='/intensivaodematriculas-lov3' exact render={()=><IntensivaoDeMatriculas
          headline="Seu Script de Venda Não Funciona Mais?"
          subHeadline="Aprenda o passo a passo para montar um script que converte em matrículas do absoluto zero e nunca mais dependa de ninguém"
          paginaDeObrigado="/mexobrigado2"
          />}
        
          />
  
         <Route path='/blog' render={()=><Blog />} />
         <Route path='/cadastrar' render={()=><SignUp/>} />
         <Route path='/dashboard' render={()=><Dashboard/>} />
         <Route path='/login' render={()=><Login/>} />
         <Route path='/perpetuaabc' render={()=><Perpetua/>} />
         <Route path='/amup' render={()=><AmUp/>} />
         <Route path='/amdown' render={()=><AmDown/>} />
         <Route path='/experimental' render={()=><Experimental/>} />
         <Route path='/classvideowithinteraction' render={()=><ClassVideoWithInteraction/>} />
         <Route path='/termosdeusoeprivacidade' render={()=><TermosDeUsoEPrivacidade/>} />  
        
         <Route path='/referral' render={()=><ReferralUI/>} />  
        
         
     
        <Route path='/obrigado' render={(props)=><Obrigado {...props} 
         tituloDoCurso={<p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Falta pouco para você garantir sua vaga no Curso Gratuito Matricula Express!</p>}
         whatsAppHref="https://tiny.cc/matlink"
         dataFromParent = {this.state} />}    
         />
  
        <Route path='/obrigadoin' render={(props)=><Obrigado {...props} 
         tituloDoCurso={<p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Falta pouco para você garantir sua vaga no Curso Gratuito Intensivão de Matrículas!</p>}       
         dataFromParent = {this.state} />}    
         />
  
        <Route path='/mexobrigado2' render={(props)=><Obrigado {...props} 
         tituloDoCurso={<p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-5xl font-bold p-6 mb-0 text-center">Falta somente um passo para garantir a sua vaga no Treinamento Matrícula Express!</p>}       
         noButtons={true}
         dataFromParent = {this.state} 
         />}  
         />
  
        <Route path='/mexobrigado' render={(props)=><Obrigado {...props} 
         tituloDoCurso={<p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-lg lg:text-5xl font-bold p-6 mb-0 text-center">Falta pouco para você garantir sua vaga no Curso Gratuito Matrícula Express!</p>}       
         noButtons={false}
         dataFromParent = {this.state} 
         />}  
         />
  
         <Route path='/obrigadoam' render={(props)=><Obrigado {...props}  
         tituloDoCurso={<p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-lg lg:text-5xl font-bold p-6 mb-0 text-center">Falta pouco para você garantir sua vaga no Quebrando as <span className="line-through">Regras</span> Objeções!</p>}  
         whatsAppHref="https://omatriculador.com.br/kr/obrigadoam"
         dataFromParent = {this.state} />} 
  
             
         />
  
          <Route path='/nmi' exact render={ (props)=><NewMi {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=mz1x0rib&checkoutMode=10&bid=1620526594532'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 299,11</span> ou 2997,00 à Vista</p>}       
          />} />  
  
         <Route path='/mi' exact render={ (props)=><MatriculaImplacavel {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=mz1x0rib&checkoutMode=10&bid=1620526594532'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}        
          />} />       
  
          <Route path='/nmi/amex' render={ (props)=><NewMi {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=15m957n6&checkoutMode=10&bid=1620530602622'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} />  
  
          <Route path='/mi/amex' render={ (props)=><MatriculaImplacavel {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=15m957n6&checkoutMode=10&bid=1620530602622'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} />  
  
         <Route path='/nmi/emex' render={ (props)=><NewMi {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=8jkx8k50&checkoutMode=10&bid=1620530646215'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} /> 
  
          <Route path='/mi/emex' render={ (props)=><MatriculaImplacavel {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=8jkx8k50&checkoutMode=10&bid=1620530646215'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} /> 
  
          <Route path='/nmi/fmex' render={ (props)=><NewMi {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=exusvrs9&checkoutMode=10&bid=1620530685086'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} /> 
  
          <Route path='/mi/fmex' render={ (props)=><MatriculaImplacavel {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=exusvrs9&checkoutMode=10&bid=1620530685086'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} /> 
  
          <Route path='/nmi/imex' render={ (props)=><NewMi {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=db9x2oxn&checkoutMode=10&bid=1620530711459'
          precoHotmart='De R$3000,00 
         por até 12 x de 249,21 ou 2497 à vista'  
          />} /> 
  
          <Route path='/mi/imex' render={ (props)=><MatriculaImplacavel {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=db9x2oxn&checkoutMode=10&bid=1620530711459'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} /> 
  
         <Route path='/nmi/wmex' render={ (props)=><NewMi {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=hoolxp47&checkoutMode=10&bid=1620530822898'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} /> 
  
          <Route path='/mi/wmex' render={ (props)=><MatriculaImplacavel {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=hoolxp47&checkoutMode=10&bid=1620530822898'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} /> 
  
          <Route path='/nmi/wvmex' render={ (props)=><NewMi {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=mpagt0kn&checkoutMode=10&bid=1620530880023'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} /> 
  
          <Route path='/mi/wvmex' render={ (props)=><MatriculaImplacavel {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=mpagt0kn&checkoutMode=10&bid=1620530880023'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} /> 
  
          <Route path='/nmi/ymex' render={ (props)=><NewMi {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=k4nkvg0l&checkoutMode=10&bid=1620530923127'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} />  
  
          <Route path='/mi/ymex' render={ (props)=><MatriculaImplacavel {...props}
          hotmartLink = 'https://pay.hotmart.com/T27508633O?off=k4nkvg0l&checkoutMode=10&bid=1620530923127'
          precoHotmart={<p>Até <span className={`text-2xl px-2 font-bold bg-custom-orange-default text-white`}>12x 199,31</span> ou 1.997,00 à Vista</p>}  
          />} /> 
  
          <Route path='/nmi/npmex' render={ (props)=><NewMi {...props}
          hotmartLink = 'https://omatriculador.com.br/aguardeim'
          precoHotmart='' 
          esconderPrecoHotmart = {true}
          />} />
  
          <Route path='/mi/npmex' render={ (props)=><MatriculaImplacavel {...props}
          hotmartLink = 'https://omatriculador.com.br/aguardeim'
          precoHotmart='' 
          esconderPrecoHotmart = {true}
          />} />
  
      <Route component={Home} path='/matlink' exact />   
  
      <Route path='/matlink/7e34r' component={() => { 
      window.location.href = 'http://tiny.cc/matlink_'; 
       return (
         <LoadingPleaseWait/>
       );
      }}/>
  
      <Route path='/matlink/2e34r' component={() => { 
      window.location.href = 'http://tiny.cc/matlinkvips?tipo=2'; 
       return (
         <LoadingPleaseWait/>
       );
      }}/>
          
      <Route path='/matlink/3erWz' component={() => { 
       window.location.href = 'http://tiny.cc/matlinkvips?tipo=3'; 
       return (
        <LoadingPleaseWait/>
      );
      }}/>
  
      <Route path='/matlink/4oiopP' component={() => { 
       window.location.href = 'http://tiny.cc/matlinkvips?tipo=4'; 
       return (
        <LoadingPleaseWait/>
      );
      }}/>
  
      
      <Route path='/matlink/5UiuzI' component={() => { 
       window.location.href = 'https://omatriculador.com.br/kr/matlinkytvip'; 
       return (
        <LoadingPleaseWait/>
      );
      }}/>
  
      
      <Route path='/matlink/6ioi9P' component={() => { 
       window.location.href = 'https://omatriculador.com.br/kr/matlinkgrporg'; 
       return (
        <LoadingPleaseWait/>
      );
      }}/>
  
  <Route path='/matlink/7abxy' component={() => { 
      window.location.href = 'https://omatriculador.com.br/kr/matlink1a1org'; 
       return (
         <LoadingPleaseWait/>
       );
      }
    }/>  
       </>  
  :
  <>
  <Redirect to="/jm1"></Redirect>
      {/* <Redirect to="/mex"></Redirect> */}
      <Route
          path='/jm1'
          exact  
          render={()=>
         <LancamentoDoisShort paginaDeObrigado={`/jmobrigado1`}/>
         } />  
      {/* <Route       
          path='/mex'
           exact     
          render={(props)=>
          <MatriculaExpress
          {...props} 
          type={3}
          pageVersion={'default'}
          alternativeIcons ={false}
          alternativeHeroLayout = {false}
          headerText='Matrícula Express'
          mainHeroData = 'Treinamento gratuito de 21 a 24 de Março às 20h22min (horário de Brasília)'
          
          //choose between lottie or image
          isItLottie = {true}
          mainHeroLottie = {<SpeedLottie/>}  
          parentCallback = {this.callbackFunction}/>
          }/>   */}
  
  </>
}         

     </Switch>   
     </BrowserRouter>
    </div>
    </ReferralContextProvider>  
    </LoadingContextProvider>
    </MUIBreakpointsProvider>  
    </ThemeProvider>
    </AuthProvider>
    </SnackProvider>
   
  );
}
}



export default App;
