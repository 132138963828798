import React, {useEffect, useRef} from 'react'
import logoWaterMark from '../../images/3 (1).png'
import gsap from 'gsap'

export default function WaterMark() {

    const floatingLogoRef = useRef(null)


    useEffect(() => {
        let tl = gsap.timeline({})
        tl.fromTo(floatingLogoRef.current, 1,{
            opacity:0,
            x:-160,
        },{
            opacity:1,
            x:0,
            ease: 'power4.Out',
            delay: 4.5,
        })  

        return () => {
            tl.kill()
        }       

    }, [])



    return (
        <div className={`fixed md:opacity-100 opacity-60 md:w-24 w-12 md:h-24 h-12 md:top-10 top-5 md:left-10 left-5 z-30`}>
            <img ref={floatingLogoRef} src={logoWaterMark}></img>            
        </div>
    )
}
