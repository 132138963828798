import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
//import IconCard from './components/Perpetua/UIRelated/IconCard'
import FixedFeedBackAnimateLines from './components/Perpetua/UIRelated/FixedFeedBackAnimateLines'
import Section from './components/Perpetua/UIRelated/Section'
import { useMUIBreakpointsContext } from './context/MUIBreakpointsContext'
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import IconBox from './components/Perpetua/UIRelated/IconBox'
import Title from './components/Perpetua/UIRelated/Title'
import Button from '@material-ui/core/Button'
import StarIcon from '@material-ui/icons/Star';
import Testimonial1 from './images/e.jpg';
import Video from './components/Perpetua/UIRelated/Video'
import FsLightbox from 'fslightbox-react';
import Thumbnail1 from './images/thumb2.jpg'
import Avatar1 from './images/joao.png'
import TestimonialProfileCard from './components/Perpetua/UIRelated/TestimonialProfileCard'
import SocialMediaCard from './components/Perpetua/UIRelated/SocialMediaCard'
import SocialThumb1 from './images/thumb1.jpg'
import Illustration1 from './images/exel.png'
import FAQ from './components/Perpetua/FAQ'
// import Logo from './images/1-1.jpg'
import Logo2 from './images/3 (1).png'
import TemporaryBg2 from './images/chefia.png'
import PagamentosAutorizados from './images/img-55398-20160317094438-1.png'
import Container from '@material-ui/core/Container'
import ReactPlayer from 'react-player/lazy'
import Artistic from './components/Perpetua/UIRelated/Artistic'

function CallToActionForPayment() {
  return (
    <div className={``}>

      <div className={`flex flex-col items-center justify-center gap-8`}>
        <button style={{ boxShadow: `0 0 30px 10px #E66105` }} className={`p-4 sm:p-8 rounded-md bg-custom-orange-default text-white text-lg sm:text-3xl font-lato font-bold`}> Quero Dobrar o Número de Alunos </button>
        <div className={`flex flex-col items-center justify-center`}>
          <p className={`font-roboto text-gray-600`}> Pagamento 100% seguro</p>
          <img className={`opacity-80 -mt-8`} src={PagamentosAutorizados} alt="" />
        </div>
      </div>
    </div>
  )
}

export default function Experimental() {

  // UI/UX animation related stuff. 

  // allow scroll trigger to work with GSAP
  gsap.registerPlugin(ScrollTrigger)

  const thisWholePage = useRef(null)

  useLayoutEffect(() => {
    let allSections = thisWholePage.current.querySelectorAll('.section2')    
    allSections.forEach((element) => {     
      let allItems2 = element.querySelectorAll(`div`)
     
        gsap.fromTo(allItems2, 0.5, {
          y: -30,       
          opacity: 0,       
        },
          {
            scrollTrigger: {
              trigger: element,            
            },          
            stagger: {
              repeat:0,
              each:0.05
            },
            ease:`sine.inOut`,
            y: 0,                   
            opacity: 1,
  
          })
     
   
    })
  }, [])





  const bigFooter = useRef(null)

  const [toggler, setToggler] = useState({
    toggler1: false,
    toggler2: false,
    toggler3: false,
    toggler4: false,
  });

  const handleLightBoxToggle = (togglerId) => {
    setToggler((i) => ({ ...i, [togglerId]: !i[togglerId] }))
    document.title = toggler[togglerId]
    console.log(toggler);
  }



  const { smDown } = useMUIBreakpointsContext()

  const [state, setstate] = useState({
    fixedAnimationState: false,
  })


  return (
    <div ref={thisWholePage}>
      <FsLightbox
        toggler={toggler.toggler1}
        sources={[
          'https://i.imgur.com/fsyrScY.jpg',
          'https://www.youtube.com/watch?v=xshEZzpS4CQ',
          'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
        ]}
      />
      <FsLightbox
        toggler={toggler.toggler2}
        sources={[
          'https://i.imgur.com/fsyrScY.jpg',
          'https://www.youtube.com/watch?v=xshEZzpS4CQ',
          'https://www.youtube.com/watch?v=xshEZzpS4CQ',
          'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
        ]}
      />
      <FsLightbox
        toggler={toggler.toggler3}
        sources={[
          'https://i.imgur.com/fsyrScY.jpg',
          'https://www.youtube.com/watch?v=xshEZzpS4CQ',
          'https://www.youtube.com/watch?v=xshEZzpS4CQ',
          'https://www.youtube.com/watch?v=xshEZzpS4CQ',
          'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
        ]}
      />
      <FsLightbox
        toggler={toggler.toggler4}
        sources={[
          'https://i.imgur.com/fsyrScY.jpg',
          'https://www.youtube.com/watch?v=xshEZzpS4CQ',
          'https://www.youtube.com/watch?v=xshEZzpS4CQ',
          'https://www.youtube.com/watch?v=xshEZzpS4CQ',
          'https://www.youtube.com/watch?v=xshEZzpS4CQ',
          'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
        ]}
      />

      <FixedFeedBackAnimateLines fixedAnimationState={state.fixedAnimationState} />

      <div className={`section2`}>
        <div className={`mt-8 min-h-screen sm:min-h-full h-auto rounded-sm mb-24 sm:mb-0 `}>

          <Section elevation={0} template={'heroBackgroundOriented'} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />}>
            <div className={`sm:bg-hero-pattern  bg-mobile-hero-pattern sm:bg-center bg-start bg-cover bg-no-repeat absolute inset-0 flex flex-col-reverse sm:flex-row items-stretch justify-center w-full min-h-screen sm:min-h-full sm:h-full`}>
              <div className={`absolute z-10 -bottom-10 -right-5`}><Artistic template={`corner1`} entranceDelay={0.2} /> </div>
              <div className={`md:w-1/2 -mb-16 sm:mb-0 flex flex-col items-center justify-center`}>

                <div className={`max-w-sm relative bg-gray-100 border-t border-l border-custom-orange-default p-8  shadow-2xl flex items-center justify-center flex-col gap-6`}>

                  <Title padding={`pl-0`} addon={false}>Lorem ipsum dolor Ipsum <span className={`text-custom-orange-default`}>ipsum dolor nisi autem!</span></Title>

                  <p className={`font-roboto font-light mb-4`}>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, Lorem ipsum dolor nisi autem pariatur!
                  </p>

                  <div className={`w-full flex items-center justify-start gap-4`}>
                    <Button style={{ paddingRight: '50px', paddingLeft: '50px' }} variant="contained" color="primary">
                      <span className={`text-gray-100`}> Example </span>
                    </Button>
                    <Button variant="contained" color="default">
                      Example
                    </Button>
                  </div>
                </div>

              </div>

              <div className={`md:w-1/2 h-96 flex items-center justify-center `}>

              </div>

            </div>

          </ Section>
        </div>
      </div>

      <div className={`section2`}>
        <Container maxWidth='lg'>
          <div className={`flex items-center  relative justify-center`}>
            <div className={`absolute opacity-50 right-1/2 top-1/5`}>
              <div className={`border w-96 h-96 border-custom-orange-default`}></div>
              <div className={`border w-96 h-96 border-custom-orange-default absolute -right-1/2 -bottom-1/2`}></div>
            </div>

            <div className={`flex w-full relative p-4 flex-col items-center justify-center max-w-2xl gap-4`}>
              <div className={`absolute z-10 -bottom-10 -right-5`}><Artistic template={`corner1`} entranceDelay={0.2} /> </div>
              <img className={`w-32`} src={Logo2} alt="" />
              <p className={`font-lato font-bold text-3xl`}>Conheça a Escola de Matriculas</p>
              <ReactPlayer
                width='100%'
                height="400px"
                controls={true}
                url={'https://youtu.be/e-4iyIIUXgE'}
              />
            </div>
          </div>
        </Container>
      </div>

      <div className={`section2`}>
        <div className={``}>
          <CallToActionForPayment />
        </div>
      </div>

      <div className={`section2`}>
        <Container maxWidth="lg">

          <div className={`grid grid-cols-1 justify-items-center md:grid-cols-3 gap-4 pt-8 pb-8`}>

            <IconBox template={1} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
                    Módulo 1`} >Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</IconBox>
            <IconBox template={1} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Acesso
                  imediato e garantido`} title2={`
                    Módulo 1`} >Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</IconBox>
            <IconBox template={1} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`30 dias de
                  garantia`} title2={`
                    Módulo 1`} >Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</IconBox>
          </div>
        </Container>
      </div>




        <Section elevation={1} template={1} title={`Escola de Matrículas`} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />}>

      <div className={`section2`}>
            <div className={`flex-col`}>
              <div>
               <div> <Title addon={true}>Agora veja os <span className={`text-custom-orange-default`}>módulos do treinamento</span></Title></div>
                <div className={`text-lg md:ml-8 w-full max-w-md font-roboto font-light opacity-80 p-8 md:p-0`}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, Lorem ipsum dolor nisi autem pariatur!                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, Lorem ipsum dolor nisi autem pariatur!
                </div>
                <div className={`flex flex-col gap-4`}>
  
                  <div className={`grid grid-cols-1 justify-items-center md:grid-cols-3 gap-4`}>
  
                    <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
      Módulo 1`} >
                      <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                    </IconBox>
                    <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
      Módulo 1`} >
                      <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                    </IconBox>
                    <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
      Módulo 1`} >
                      <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                    </IconBox>
  
                  </div>

      </div>  
                <div className={`grid grid-cols-1 justify-items-center md:grid-cols-3 gap-4`}>

                  <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
    Módulo 1`} >
                    <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                  </IconBox>
                  <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
    Módulo 1`} >
                    <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                  </IconBox>
                  <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
    Módulo 1`} >
                    <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                  </IconBox>
                </div>

              </div>

            </div>
  
              <div className={`flex items-center md:flex-row flex-col justify-center pt-8 pb-8 gap-8`}>
  
                <div id={`icons-wrapper`} className={`flex flex-col items-center justify-center gap-4`}>
  
                  <div className={`flex items-center justify-center gap-4`}>
                    <div className={`w-24 border-t border-l border-custom-orange-default h-24  shadow-md flex items-center gap-4 justify-center`}>
                      <div ><AccountTreeIcon style={{ width: '40px', height: '40px' }} /></div>
                    </div>
                    <p className={`text-4xl font-roboto font-light`}>Módulo Extra 1</p>
  
                  </div>
                  <div className={`flex items-center justify-center gap-4`}>
                    <div className={`w-24 h-24 border-t border-r border-custom-orange-default  shadow-md flex items-center gap-4 justify-center`}>
                      <div ><AccountTreeIcon style={{ width: '40px', height: '40px' }} /></div>
                    </div>
                    <p className={`text-4xl font-roboto font-light`}>Módulo Extra 2</p>
  
                  </div>
                  <div className={`flex items-center justify-center gap-4`}>
                    <div className={`w-24 h-24 border-r border-b border-custom-orange-default shadow-md flex items-center gap-4 justify-center`}>
                      <div ><AccountTreeIcon style={{ width: '40px', height: '40px' }} /></div>
                    </div>
                    <p className={`text-4xl font-roboto font-light`}>Módulo Extra 3</p>
  
                  </div>
                  <div className={`flex items-center justify-center gap-4`}>
                    <div className={`w-24 h-24 border-b border-l border-custom-orange-default shadow-md flex items-center gap-4 justify-center`}>
                      <div ><AccountTreeIcon style={{ width: '40px', height: '40px' }} /></div>
                    </div>
                    <p className={`text-4xl font-roboto font-light`}>Módulo Extra 4</p>
  
                  </div>
                </div>
  
              <div>
                  <img className={`ml-8 sm:m-0`} style={{ maxWidth: '800px' }} src={Illustration1}></img>
    
              </div>
            
    </div>
    </div>
<div className={`section2`}>
              <div className={`flex flex-col gap-4`}>
  
                <div className={`grid grid-cols-1 justify-items-center md:grid-cols-3 gap-4`}>
  
                  <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
      Módulo 1`} >
                    <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                  </IconBox>
                  <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
      Módulo 1`} >
                    <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                  </IconBox>
                  <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
      Módulo 1`} >
                    <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                  </IconBox>
  
                </div>
  
                <div className={`grid grid-cols-1  justify-items-center md:grid-cols-3 gap-4`}>
  
                  <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
      Módulo 1`} >
                    <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                  </IconBox>
                  <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
      Módulo 1`} >
                    <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                  </IconBox>
                  <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
      Módulo 1`} >
                    <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                  </IconBox>
                </div>
  
              </div>
  
          
  

</div> 
<div className={`section2`}>
  
            <div className={`grid grid-cols-1 md:grid-cols-2 gap-4`}>
             <div> <Title addon={true}>O que você recebe de <span className={`text-custom-orange-default`}>presente exclusivo?</span></Title></div>
              <div className={`w-full flex items-center justify-center max-w-lg`}>
                <div className={`text-lg w-full font-roboto font-light opacity-80 p-8 md:p-0`}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, Lorem ipsum dolor nisi autem pariatur!
                </div>
              </div>
            </div>
  
          <div>  <img className={`ml-8 sm:m-0`} style={{ maxWidth: '800px' }} src={Illustration1}></img></div>
  
            <div className={`grid grid-cols-1 md:grid-cols-2 gap-4`}>
            <div>  <Title addon={true}><span className={`text-custom-orange-default`}> Todo dia </span> um matriculador desesperado</Title></div>
              <div className={`w-full flex items-center justify-center max-w-lg`}>
                <div className={`text-lg w-full font-roboto font-light opacity-80 p-8 md:p-0`}>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, Lorem ipsum dolor nisi autem pariatur!
                </div>
              </div>
  
            </div>
</div>
    <div className={`section2`}>
            <div style={{ width: '800px', height: '500px' }} className={`relative`}>
              <div className={`absolute top-0 sm:left-8 -left-8 shadow-2xl`}>
                <SocialMediaCard template={1} innitial={`C`} name={'Charles Cleivin'} image={SocialThumb1} date={`14 de Agosto, 2021`} content={`Socorro! Não bati minhas metas! o perfect party dish and a fun meal to cook together with your
                guests. Add 1 cup of frozen peas along with the mussels, if you like.`} />
              </div>
              <div className={`absolute top-16 left-32 shadow-2xl`}>
                <SocialMediaCard template={1} innitial={`D`} name={'Daniel Soarez'} image={SocialThumb1} date={`14 de Agosto, 2021`} content={`Socorro! Não bati minhas metas! This impressive paella is a perfect party dish and a fun meal to cook together with your
                guests. Add 1 cup of frozen peas along with the mussels, if you like.`} />
              </div>
              <div className={`absolute top-0 left-64 shadow-2xl`}>
                <SocialMediaCard template={1} innitial={`R`} name={'Rafaela Barroso'} image={SocialThumb1} date={`14 de Agosto, 2021`} content={`Socorro! Não bati minhas metas! This impressive paella is a perfect party dish and a fun meal to cook together with your
                guests. Add 1 cup of frozen peas along with the mussels, if you like.`} />
              </div>
            </div>
    </div>

  <div className={`section2`}>
    <div>        <Title addon={true}> Material <span className={`text-custom-orange-default`}> Didatico </span></Title></div>
  
  
            <div className={`flex gap-4 flex-col md:flex-row items-center justify-around p-4`}>
              <IconBox template={1} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} >
                <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
              </IconBox>
              <IconBox template={1} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} >
                <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
              </IconBox>
              <IconBox template={1} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} >
                <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
              </IconBox>
            </div>
  
            <div className={`flex gap-4 ml-4 md:ml-0 flex-col md:flex-row items-center justify-around p-4`}>
              <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
    Módulo 1`} >
                <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
              </IconBox>
              <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
    Módulo 1`} >
                <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
              </IconBox>
              <IconBox template={2} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />} title={`Pagamento Seguro`} title2={`
    Módulo 1`} >
                <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
              </IconBox>
            </div>

  </div> 
        </Section>
     

      <div className={`section2`}>
        <div className={``}>
          <CallToActionForPayment />
        </div>

      </div>


        <Section elevation={1} template={1} title={`Para quem se destina`} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />}>

   <div className={`section2`}>
            <div className={`flex sm:flex-row flex-col justify-between gap-4 `}>
             <div>
                <div className={`mt-8`}>
            <div>
                    <Title addon={false}> "O Favorito e Indisputavel".
                      <span className={`text-custom-orange-default`}> Alto grau de satisfação. </span></Title>
            </div>
    
                  <div className={`ml-8`}>
                    <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                    <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                    <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                    <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                    <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                  </div>
    
                </div>
  
             </div>  
  
        <div>
                <div style={{ height: '700px' }} className={`relative -right-8 sm:right-10  flex items-center justify-center w-96`}>
                  <img className={`absolute top-5 right-5 shadow-xl rounded-2xl`} src={Testimonial1} alt=""></img>
                  <img className={`absolute top-20 right-20 shadow-xl rounded-2xl`} src={Testimonial1} alt=""></img>
                  <img className={`absolute top-36 right-36 shadow-xl rounded-2xl`} src={Testimonial1} alt=""></img>
                </div>
              </div>
        </div>
   </div>

      <div className={`section2`}>
            <div className={`flex sm:flex-row-reverse flex-col justify-between gap-4 `}>
  
  
              <div className={`mt-8`}>
                <Title addon={false}> Qualidade Indisputavel.
                  <span className={`text-custom-orange-default`}> Alto grau de satisfação. </span></Title>
  
                <div className={`ml-8`}>
                  <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                  <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                  <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                  <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                  <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                </div>
  
              </div>
  
              <div className={`grid p-4 justify-items-center grid-cols-1 sm:grid-cols-2 sm:grid-rows-2 gap-4`}>
                <Video thumbnail={Thumbnail1} togglerId={'toggler1'} handleToggler={handleLightBoxToggle} />
                <Video thumbnail={Thumbnail1} togglerId={'toggler2'} handleToggler={handleLightBoxToggle} />
                <Video thumbnail={Thumbnail1} togglerId={'toggler3'} handleToggler={handleLightBoxToggle} />
                <Video thumbnail={Thumbnail1} togglerId={'toggler4'} handleToggler={handleLightBoxToggle} />
              </div>
  
            </div>

        </div>  

         
              <div className={`section2`}>
                  <div className={`flex flex-col justify-between gap-4 `}>
                    <div className={`mt-16 mb-16`}>
                      <Title addon={true}> "O Favorito e Indisputavel".
                        <span className={`text-custom-orange-default`}> Alto grau de satisfação. </span></Title>
        
                      <div className={`ml-8`}>
                        <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                        <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                        <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                        <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                        <StarIcon className={`text-yellow-400`} style={{ width: '40px', height: '40px' }} />
                      </div>
        
                    </div>
  
                  </div> 
              </div>
              
           <div className={`section2`}>
              <div className={`grid relative pt-8 z-20 grid-cols-1 justify-items-center md:grid-cols-3 sm:gap-8 gap-32`}>
  
                <TestimonialProfileCard avatar={Avatar1}>
                  <div className={`grid grid-cols-1 justify-items-center gap-4 p-8 pt-20`}>
                    <div className={`w-full`}>
                      <div className={`text-xl w-full font-sansPro text-custom-orange-default font-light pb-2`}>
                        João Pedro - CEO Hotmart
                      </div>
                      <div className={`text-2xl w-full font-lato font-bold pb-2`}>
                        Maior plataforma de produtos digitais do Brasil
                      </div>
                    </div>
                    <div className={`text-md w-full font-roboto font-light opacity-80`}>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, Lorem ipsum dolor nisi autem pariatur!
                    </div>
                  </div>
  
                </TestimonialProfileCard>
                <TestimonialProfileCard avatar={Avatar1}>
                  <div className={`grid grid-cols-1 justify-items-center gap-4 p-8 pt-20`}>
                    <div className={`w-full`}>
                      <div className={`text-xl w-full font-sansPro text-custom-orange-default font-light pb-2`}>
                        João Pedro - CEO Hotmart
                      </div>
                      <div className={`text-2xl w-full font-lato font-bold pb-2`}>
                        Maior plataforma de produtos digitais do Brasil
                      </div>
                    </div>
                    <div className={`text-md w-full font-roboto font-light opacity-80`}>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, Lorem ipsum dolor nisi autem pariatur!
                    </div>
                  </div>
                </TestimonialProfileCard>
                <TestimonialProfileCard avatar={Avatar1}>
                  <div className={`grid grid-cols-1 justify-items-center gap-4 p-8 pt-20`}>
                    <div className={`w-full`}>
                      <div className={`text-xl w-full font-sansPro text-custom-orange-default font-light pb-2`}>
                        João Pedro - CEO Hotmart
                      </div>
                      <div className={`text-2xl w-full font-lato font-bold pb-2`}>
                        Maior plataforma de produtos digitais do Brasil
                      </div>
                    </div>
                    <div className={`text-md w-full font-roboto font-light opacity-80`}>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, Lorem ipsum dolor nisi autem pariatur!
                    </div>
                  </div>
                </TestimonialProfileCard>

           </div> 
            </div>
     


        </Section>



      <div className={`section2`}>

        <div className={``}>
          <CallToActionForPayment />
        </div>

      </div>

      

        <Section elevation={1} template={1} title={`De onde vêm tudo isso?`} icon={<AccountTreeIcon style={{ height: '40px', width: '40px' }} />}>
        <div className={`section2`}>
          <div className={`w-full rounded-lg `}>

            <div className={`sm: ml-4`}>
              <Title addon={true}> <span className={``}>Outras Perguntas</span>
                <span className={`text-custom-orange-default`}> Frequentes </span></Title>
            </div>

            <div className={`p-4`}><FAQ perpetua={true} /></div>

          </div>
          </div>

          <div className={`section2`}>

          <div ref={bigFooter} className={`w-full mt-16 rounded-lg`}>

            <div style={{ backgroundColor: '#9a999e' }} className={`flex p-8 items-center md:justify-between gap-4 justify-center flex-col md:flex-row  `}>
              <div>
                <Title addon={false}><span className={`text-white`}> Com quem você vai aprender? </span>
                </Title>
                <div className={`text-2xl ml-0 md:ml-8 text-gray-100  max-w-md w-full font-lato font-light pb-2`}>
                  Você vai aprender com os gestores
                  responsáveis pela operação e
                  estratégias do Grupo WDC.
                </div>
              </div>

              <div className={`text-xl text-gray-100  max-w-lg w-full font-roboto font-light opacity-80`}>
                WDC é o nome da empresa por trás dos produtos digitais mais vendidos no Brasil. Responsável pela comunicação, estratégia e vendas de todos os produtos de Cátia Damasceno e Leandro Ladeira. Ao todo o grupo já realizou mais de 130 mil vendas de infoprodutos a um ticket médio de R$600,00.
              </div>

            </div>

            <div style={{ backgroundImage: `url(${TemporaryBg2})`, height: '600px' }} className={`bg-cover bg-center w-full`}></div>

            <div style={{ backgroundColor: '#12172e' }} className={`w-full pb-32 pt-8 gap-4 flex items-center justify-center flex-col`}>

              <img className={`w-32 shadow-2xl`} src={Logo2}></img>

              <div className={`text-md text-center text-gray-300 max-w-2xl p-4 w-full font-roboto font-light opacity-80`}>
                Aviso Legal: “Nenhuma informação contida neste produto deve ser interpretada como uma afirmação da obtenção de resultados. Qualquer referência ao desempenho passado ou potencial de uma estratégia abordada no conteúdo não é, e não deve ser interpretada como uma recomendação ou como garantia de qualquer resultado específico.”
              </div>

            </div>


          </div>
          </div>

        </Section>

      </div>

    
  )
}
