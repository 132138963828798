import React, {useEffect} from 'react';
import ErrorLottie from './components/errorLottie.jsx';
import whatsApp from './images/124034.png';
import lock from './images/lock.svg';
import letsEncript from './images/le-logo-twitter-noalpha-1-p-500.png'; 
//import ReactPixel from 'react-facebook-pixel';
import { useHistory } from 'react-router';

export default function Obrigado(props)  {

const history = useHistory()
//const name = history.location.state.name

if (history.location.state) {
    const email = history.location.state.email
}
else {
    const email = "entroudiretamentenapagina@higorfabiano.com.br" 
}
    
useEffect(() => {

    const script0 = document.createElement('script')
    script0.id = 'scrip-lead'
    script0.textContent = `console.log('gtag worked'); gtag('event', 'conversion', {'send_to': 'AW-784433637/lPSDCJL08bkDEOWDhvYC'});`
    script0.async = true
    script0.defer = true
    script0.crossOrigin = 'anonymous'
    document.body.appendChild(script0)

  return () => {
    document.body.removeChild(script0)
  }

}, [])

useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

   //  componentDidMount(){

      
        //this.props.history.push(`/obrigado?nome=${this.props.dataFromParent.formRelatednomeCapturado}&email=${this.props.dataFromParent.formRelatedemailCapturado}`)
    
    //     console.log(this.props);
    //     const advancedMatching = { em: 'contato@higorfabiano.com.br' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    //     const options = {
    //      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    //      debug: false, // enable logs
    //    }

    //    function facebooPixelStuff() {
    //     ReactPixel.pageView();
    //     ReactPixel.trackSingle('700597194128609', 'ObgMex') 
    //     ReactPixel.trackSingle('477353900172815', 'ObgMex')
    //     ReactPixel.trackSingle('1105383856490571', 'ObgMex')    
    // } 
    
    
    
            // ReactPixel.init('700597194128609', advancedMatching, options);  
            // ReactPixel.init('477353900172815', advancedMatching, options);         
            // ReactPixel.init('1105383856490571', advancedMatching, options);
    
    
            // facebooPixelStuff() 
            
    
            // const jQueryScript = document.createElement("script");  
           
    
            // jQueryScript.src = "https://code.jquery.com/jquery-3.5.1.slim.min.js";    
            // jQueryScript.type = "text/javascript";
    
            // document.getElementsByTagName("head")[0].appendChild(jQueryScript);   


    //}
  

let href = `https://www.higorfabiano.com.br/join.php?v=1`     

return (

  <>
      <>
           { props.mode === undefined &&                
           <div >
                <div className="obrigado-body p-6">          
                <ErrorLottie />
                <h2 className="obrigado-texts opacity-90 font-sans lg:text-8xl text-5xl font-bold p-6 mb-0 mt-4 text-center">QUASE</h2>
                {props.tituloDoCurso}
        
        
             {
                 !props.noButtons
                 ? 
                 
                 
                <>
                     <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-lg lg:text-5xl font-bold p-6 mb-0 text-center">Se programe para não perder nada!<br></br>
                     Enviaremos aulas extras de conteúdo para quem quiser aprender de verdade
                     </p> 
                     <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-lg lg:text-5xl font-bold p-6 mb-0 text-center">Por esse motivo criei um:</p>
                </>
                 : <>
                      <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-lg lg:text-5xl font-bold p-6 mb-0 text-center">Vá até seu e-mail e confirme sua inscrição, somente assim receberá conteúdo específico para desenvolver suas técnicas de matrículas.</p>
                      <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-lg lg:text-5xl font-bold p-6 mb-0 text-center">Se programe para não perder nada!<br></br>
                       Enviaremos aulas extras de conteúdo para quem quiser aprender de verdade
                       </p> 
                    <div className={`flex items-center justify-center w-full`}>
                       <p className="obrigado-texts max-w-screen-md obrigado-paragraphs opacity-90 font-sans lg:text-lg text-lg p-2 mb-0 text-center">Eu autorizo receber ligações e SMS da Escola de Matrículas nos termos da Lei Geral de Proteção de Dados</p> 
                     </div>  
                   </> 
             }   
        
        
                
         { !props.noButtons &&
         <>
                    <div onClick={(e) => (window.location = href)} className="bgsupergreen cursor-pointer min-h-20 lg:w-5/6 w-auto center rounded-lg m-auto flex lg:flex-row flex-col-reverse  items-center justify-center p-4 mt-8 mb-8" > 
                          <div className="flex items-center justify-end"><p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-4xl text-2xl font-bold p-6 mb-0 text-center">GRUPO DE ALUNOS NO WHATSAPP</p></div> 
                          <div className="flex items-center justify-start min-w-20"><img className="h-20 w-20 object-scale-down" src={whatsApp} alt="WhatsApp Icon"/></div>
                    </div>         
        
                       <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-5xl text-lg font-bold p-6 mb-0 text-center">Para te enviar materiais complementares, enviar alguns testes de desempenho e te lembrar sempre que ela começar.</p>       
                       <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-5xl text-lg font-bold p-6 mb-0 text-center">Para entrar no grupo é só clicar no botão abaixo!</p>       
                       <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-5xl text-lg font-bold p-6 mb-0 text-center">Obs: grupo ficará fechado para não te atrapalhar.</p>       
        
                   
        </>
        }
          { !props.noButtons &&             
                      <>
                           <div onClick={(e) => (window.location = href)} className="bg-yellow-600 cursor-pointer min-h-20 lg:w-5/6 w-auto center rounded-lg m-auto flex items-center justify-center p-4 lg:pb-4 pb-6  mt-8 mb-8" > 
                                 <div className="flex items-center justify-end"><p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-4xl text-3xl font-bold p-6 mb-0 text-center">Quero Entrar no Grupo de Alunos!</p></div> 
                           </div>    
        
                           <div className={`flex items-center justify-center w-full`}>
                                 <p className="obrigado-texts max-w-screen-md obrigado-paragraphs opacity-90 font-sans lg:text-lg text-lg p-2 mb-0 text-center">Eu autorizo receber ligações e SMS da Escola de Matrículas nos termos da Lei Geral de Proteção de Dados</p> 
                           </div>                 
                          
                      </>
          }
                       
        
                       </div>
        
        
        
                       <div className="bg-white opacity-75 min-h-30 w-full flex flex-row items-center justify-center p-6">
                           <div className="h-12 w-12 object-scale-down"><img src={lock} alt=""/></div>
                           <div className="ml-6 mr-6 font-sans text-2xl font-bold text-center"><p>Suas Informações Estão Seguras</p></div>
                           <div className="h-20 w-20 object-scale-down"><img src={letsEncript} alt=""/></div>
                       </div>
            </div>
        }  
      </>
     <>
          { props.mode === 'jm' &&
           <div >
           <div className="obrigado-body p-6">
           <ErrorLottie />
           <h2 className="obrigado-texts opacity-90 font-sans lg:text-8xl text-5xl font-bold p-6 mb-0 mt-4 text-center">QUASE</h2>
           {props.tituloDoCurso}
    
    
        {
            !props.noButtons
            ? 
            
            
           <>
                <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-lg lg:text-5xl font-bold p-6 mb-0 text-center">Se programe para não perder nada!<br></br>
                Enviaremos aulas extras de conteúdo para quem quiser aprender de verdade
                </p> 
                <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-lg lg:text-5xl font-bold p-6 mb-0 text-center">Por esse motivo criei um:</p>
           </>
            : <>
                 <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-lg lg:text-5xl font-bold p-6 mb-0 text-center">Vá até seu e-mail e confirme sua inscrição, somente assim receberá conteúdo específico para desenvolver suas técnicas de matrículas.</p>
                 <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans text-lg lg:text-5xl font-bold p-6 mb-0 text-center">Se programe para não perder nada!<br></br>
                  Enviaremos aulas extras de conteúdo para quem quiser reduzir os bolos em até 80% e dobrar o número de Matrículas
                  </p> 
               <div className={`flex items-center justify-center w-full`}>
                  <p className="obrigado-texts max-w-screen-md obrigado-paragraphs opacity-90 font-sans lg:text-lg text-lg p-2 mb-0 text-center">Eu autorizo receber ligações e SMS da Escola de Matrículas nos termos da Lei Geral de Proteção de Dados</p> 
                </div>  
              </> 
        }   
    
    
           
        { !props.noButtons &&
        <>
                <div onClick={(e) => (window.location = href)} className="bgsupergreen cursor-pointer min-h-20 lg:w-5/6 w-auto center rounded-lg m-auto flex lg:flex-row flex-col-reverse  items-center justify-center p-4 mt-8 mb-8" > 
                        <div className="flex items-center justify-end"><p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-4xl text-2xl font-bold p-6 mb-0 text-center">GRUPO DE ALUNOS NO WHATSAPP</p></div> 
                        <div className="flex items-center justify-start min-w-20"><img className="h-20 w-20 object-scale-down" src={whatsApp} alt="WhatsApp Icon"/></div>
                </div>         
    
                    <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-5xl text-lg font-bold p-6 mb-0 text-center">Para te enviar materias complementares, aulas e exercícios extras. E te lembrar quando a aula começar.</p>       
                    <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-5xl text-lg font-bold p-6 mb-0 text-center">Para entrar no grupo é só clicar no botão abaixo!</p>       
                    <p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-5xl text-lg font-bold p-6 mb-0 text-center">Obs: Fique tranquilo pois o grupo ficará silenciado.</p>       
    
                
        </>
        }
        { !props.noButtons &&             
                    <>
                        <div onClick={(e) => (window.location = href)} className="bg-yellow-600 cursor-pointer min-h-20 lg:w-5/6 w-auto center rounded-lg m-auto flex items-center justify-center p-4 lg:pb-4 pb-6  mt-8 mb-8" > 
                                <div className="flex items-center justify-end"><p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-4xl text-3xl font-bold p-6 mb-0 text-center">Quero Entrar no Grupo de Alunos!</p></div> 
                        </div>    
    
                        <div className={`flex items-center justify-center w-full`}>
                                <p className="obrigado-texts max-w-screen-md obrigado-paragraphs opacity-90 font-sans lg:text-lg text-lg p-2 mb-0 text-center">Eu autorizo receber ligações e SMS da Escola de Matrículas nos termos da Lei Geral de Proteção de Dados</p> 
                        </div>                 
                        
                    </>
        }
                    
    
                    </div>
    
    
    
                    <div className="bg-white opacity-75 min-h-30 w-full flex flex-row items-center justify-center p-6">
                        <div className="h-12 w-12 object-scale-down"><img src={lock} alt=""/></div>
                        <div className="ml-6 mr-6 font-sans text-2xl font-bold text-center"><p>Suas Informações Estão Seguras</p></div>
                        <div className="h-20 w-20 object-scale-down"><img src={letsEncript} alt=""/></div>
                    </div>
        </div>
            }
     </>
   
  </>
  )
}



