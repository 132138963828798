import React from 'react';
import './App.css';

class LoadingPleaseWait extends React.Component  {   

    componentDidMount(){
        document.title = "Redirecionando..."
      }

    componentWillUnmount(){
        document.title = "Escola de Matriculas"
    }

    render(){

        return(
            <div className="please-wait-w w-screen h-screen flex items-center justify-center bg-green-600">
                <h1 className="p-8 font-bold text-center text-white text-6xl">
                    Por Favor, Aguarde...
            </h1>
            </div>
            
        )
    }
}

export default LoadingPleaseWait