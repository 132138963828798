import React from 'react'
import TimelineDot from '@material-ui/lab/TimelineDot';

export default function TimeLineCustomDot(props) {
    return (
      <div className="">
        <TimelineDot color="primary">
        <div className="circ w-12 h-12  flex items-center justify-center">
            <p className="text-2xl font-bold text-gray-200">{props.text}</p>
        </div>
      </TimelineDot>
      </div>
    )
}
