import React, {useEffect, useState} from 'react'


export default function UpSellDefault() {


    useEffect(() => {
        const script = document.createElement("script");
        script.text = `
        opts = {
        key: 'USL-52124-126917-953bfe74b494461f2de1d2f1afba8fe5',
        width: '650' ,
        height: '260'
        }
        Upsell.Widget.show(opts);
        `
        script.async = true;
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        }
    }, [])

  
    return (

        <div className={`flex flex-col items-center justify-center pt-8 pb-8`}>   
        

        <div id="box_hotmart"></div>
             

  
        
    </div>
    )
}
