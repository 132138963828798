import React from 'react'

export default function IconBox(props) {
    return (               
        <div>
            {props.template === 1 &&
              <div>
                <div className={`w-64 ${props.height ? props.height : 'h-64'} bg-white shadow-xl p-2 relative`}>
                    <div className={`absolute -top-0 -left-5`}>
                        <div className={`bg-white border-custom-orange-default border-t border-l opacity-80 w-16 h-16 flex items-center justify-center`}>{props.icon}</div>
                    </div>
                    { props.title2 && <div className={`text-xl pl-4 ml-8 font-sansPro text-custom-orange-default font-light pb-0`}>{props.title2}</div> }
                    <div className={`${props.titleSize ? props.titleSize : 'text-2xl'} text-2xl ml-8 font-sansPro text-custom-orange-default pt-8 p-4 pb-2`}>{props.title}</div>
                    <div className={`text-md font-roboto font-light p-4 opacity-80`}>{props.children}</div>
    
                </div>
              </div>
            } 

            {props.template === 2 &&
            <div>

                
                <div className={`text-xl ml-8 font-sansPro text-custom-orange-default font-light p-4 pb-0`}>{props.title2}</div>
                <div className={`text-2xl ${props.maxWidth ? props.maxWidth : 'max-w-xs'}  ml-8 font-lato font-bold pt-0 p-4 pb-2`}>{props.title}</div>
                 <div className={`w-64 shadow-xl pl-8 h-auto p-2 relative border-l border-custom-orange-default`}>
                 <div style={{left:'-33px', top:'-22px'}} className={`border-custom-orange-default z-10 border-l border-b  transform rotate-45 w-16 h-16 absolute`}></div>
                 <div style={{marginTop: '-3px'}} className={`bg-custom-orange-default opacity-40 h-32 w-px absolute -left-8 top-12`}></div>
                    <div className={`absolute -top-6 -left-8`}>
                        <div className={`bg-white rounded-full opacity-80 w-20 h-20 flex items-center justify-center`}>{props.icon}</div>
                    </div>                    
                    <div className={`text-md font-roboto font-light p-4 opacity-80`}>{props.children}</div>
                 </div>   
            </div>
            } 
         
          
        </div>
    )
}
