import React from 'react'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

export default function Video(props) {
    return (
        <div style={{backgroundImage: `url(${props.thumbnail})`, backgroundSize:'cover', backgroundPosition:'center'}} onClick={() => {props.handleToggler(props.togglerId)}} className={`cursor-pointer relative shadow-2xl bg-gray-300 flex items-center justify-center rounded-lg sm:w-64 w-72 h-64`}>
            <div className={`absolute rounded-lg bg-black bg-opacity-40 inset-0 z-10`}></div>
            <PlayCircleFilledIcon className={`relative z-20`} style={{height:'60px', width:'60px', color:'white' }}/>
        </div>
    )
}
