import React from 'react'
import Typography from '@material-ui/core/Typography'



export default function AdicionalOPoderDosArgumentos() {
    return (
        <div>
            <Typography variant="h2" color="initial">
            O Poder dos Argumentos
            </Typography>
            <Typography variant="h3" color="initial">
            Eu vou te dar acesso a um treinamento Exclusivo para Diretores de Escolas onde eu mostro como utilizar os argumentos para potencializar seus resultados e crescer negócio.
            </Typography>

            <Typography variant="h4" color="initial">                
            Bônus Especial: Treinamento o Poder dos Argumentos de R$997,00 por ZERO
            </Typography>
        </div>
    )
}
