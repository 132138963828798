import React, {useEffect, useRef} from 'react'
import PanToolIcon from '@material-ui/icons/PanTool';
import gsap from 'gsap'


export default function HeaderWarning(props) {

    let besideIconText = "ESPERE!"

    if (props.iconPhrase !== undefined && props.iconPhrase !== null) {
        besideIconText = props.iconPhrase
    }
 
    const espereRef = useRef(null)

    useEffect(() => {
        if (props.toAnimate) {
            gsap.to(espereRef.current, {
                duration: 0.4,
                yoyo:true,
                repeat:-1,
                scale:1.2,
                ease: 'sine.inOut'
              })
        }
    }, [])

    return (
        <div className={`flex items-center justify-around sm:flex-row flex-col sm:gap-16 gap-4 md:p-16 p-8`}>
            <div ref={espereRef} className={`flex items-center md:flex-row flex-col gap-4 justify-center`}>
                <div style={{transform: 'rotateY(3.142rad)'}} className={`w-24 h-24 flex  items-center justify-center rounded-full border-2`}>
                        <div className={`text-gray-100`}>
                            <PanToolIcon style={{marginLeft:'-10px',height:'60px', width:'60px'}} />
                        </div>
                </div>
                 <h1 className={`text-4xl text-center text-gray-100 font-bold `}>{besideIconText}</h1>
            </div>
            {props.children}
        </div>
    )
}
