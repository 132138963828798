import React, { useState, useRef } from 'react'
//import IconCard from '../components/Perpetua/UIRelated/IconCard'
import FixedFeedBackAnimateLines from '../components/Perpetua/UIRelated/FixedFeedBackAnimateLines'
import Section from '../components/Perpetua/UIRelated/Section'
import { useMUIBreakpointsContext } from '../context/MUIBreakpointsContext'
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SupportAgentIcon from '@material-ui/icons/PermPhoneMsg';
import PsychologyIcon from '@material-ui/icons/WbIncandescent';

import IconBox from '../components/Perpetua/UIRelated/IconBox'
import Title from '../components/Perpetua/UIRelated/Title'
// import Button from '@material-ui/core/Button'
// import StarIcon from '@material-ui/icons/Star';
// import Testimonial1 from '../images/e.jpg';
// import Video from '../components/Perpetua/UIRelated/Video'
import FsLightbox from 'fslightbox-react';
// import Thumbnail1 from '../images/thumb2.jpg'
// import Avatar1 from '../images/joao.png'
// import TestimonialProfileCard from '../components/Perpetua/UIRelated/TestimonialProfileCard'
// import SocialMediaCard from '../components/Perpetua/UIRelated/SocialMediaCard'
// import SocialThumb1 from '../images/thumb1.jpg' 
// import Illustration1 from '../images/exel.png'
// import FAQ from '../components/Perpetua/FAQ'
// // import Logo from '../images/1-1.jpg'
import Logo2 from '../images/3 (1).png'
// import TemporaryBg2 from '../images/chefia.png'
import PagamentosAutorizados from '../images/img-55398-20160317094438-1.png'
import Covidfree from '../images/Covidfree (2).jpg'
import Container from '@material-ui/core/Container'
import ReactPlayer from 'react-player'
import Artistic from '../components/Perpetua/UIRelated/Artistic'
import Higor1 from '../images/HigorMEx (1) (1).png';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import JayissaTestimonial from '../images/Screenshot_20181206-1845142222-img-2222111-20200409215307-1.png'
import SimpleAccordion from '../components/miAccordionFaq';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import PeopleIcon from '@material-ui/icons/People';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import UpdateIcon from '@material-ui/icons/Update';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckIcon from '@material-ui/icons/Check';
import Slider from "react-slick";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Mango from '../images/higorretangulo.png'
import SaveMoney from '../images/FotoHigor (1).png'
import SmillingMan from '../images/Foto trabalhando (2).png'
import PersonIcon from '@material-ui/icons/Person';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import BusinessIcon from '@material-ui/icons/Business';
import CancelIcon from '@material-ui/icons/Cancel';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ThumbnailMainVideo from '../images/imagemdefundovideo2 (1).jpg'

import GiftWoman from '../images/Foto do Perfil (1) (1).png'


import Image1 from '../images/1.png';
import Image2 from '../images/2.png';
import Image3 from '../images/3.png';
import Image4 from '../images/4.png';
import Image5 from '../images/5.png';
import Image6 from '../images/6.png';

import ImageA from '../images/a.jpg';
import ImageB from '../images/b.jpg';
import ImageC from '../images/c.jpg';
// import ImageD from '../images/d.jpg';
import ImageE from '../images/e.jpg';
// import ImageF from '../images/f.jpg';
// import ImageG from '../images/g.jpg';
// import ImageH from '../images/h.jpg';
// import ImageI from '../images/i.jpg';

// import ImageA1 from '../images/a1.jpg'
// import ImageA2 from '../images/a2.jpg'
// import ImageA3 from '../images/a3.jpg'
// import ImageA4 from '../images/a4.jpg'
// import ImageA5 from '../images/a5.jpg'
// import ImageA6 from '../images/a6.jpg'
// import ImageA7 from '../images/a7.jpg'
// import ImageA8 from '../images/a8.jpg'
// import ImageA9 from '../images/a9.jpg'
import IconButton from '@material-ui/core/IconButton'

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import EventIcon from '@material-ui/icons/Event';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CallMadeIcon from '@material-ui/icons/CallMade';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import WarningIcon from '@material-ui/icons/Warning';
import AirplayIcon from '@material-ui/icons/Airplay';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SchoolIcon from '@material-ui/icons/School';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import GavelIcon from '@material-ui/icons/Gavel';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import MicOffIcon from '@material-ui/icons/MicOff';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
// import SignalCellularOffIcon from '@material-ui/icons/SignalCellularOff';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import SearchIcon from '@material-ui/icons/Search';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';


function CallToActionForPayment(props) {
    return (
        <div className={`pt-8`}>

            <div className={`flex flex-col items-center justify-center gap-8`}>
                <button type="button" onClick={() => { window.location.href = props.hotmartLink }} style={{ boxShadow: `0 0 30px 10px #E66105` }} className={`p-4 sm:p-8 rounded-md bg-custom-orange-default text-white text-lg sm:text-3xl font-lato font-bold`}> {props.buttonText ? props.buttonText : 'Quero Matricular Todo Dia!'} </button>
                <div className={`flex flex-col items-center justify-center`}>
                    <p className={`font-roboto text-gray-600`}> Pagamento 100% seguro</p>
                    <img className={`opacity-80 -mt-8`} src={PagamentosAutorizados} alt="" />
                </div>
            </div>
        </div>
    )
}

export default function NewMi(props) {

    const slider1 = useRef(null)
    const slider2 = useRef(null)

    const bigFooter = useRef(null)

    const [isFirstVideoLoading, setisFirstVideoLoading] = useState(true)

    const [toggler, setToggler] = useState({
        toggler1: false,
        toggler2: false,
        toggler3: false,
        toggler4: false,
    });

    const handleLightBoxToggle = (togglerId) => {
        setToggler((i) => ({ ...i, [togglerId]: !i[togglerId] }))
        document.title = toggler[togglerId]
        console.log(toggler);
    }



    const { smDown } = useMUIBreakpointsContext()

    const [state, setstate] = useState({
        fixedAnimationState: false,
    })

    let settings = {}

    if (smDown) {
        settings = {
            dots: false,
            infinite: true,
            speed: 500,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: null,
            prevArrow: null
        }
    }

    if (!smDown) {
        settings = {
            className: "center",
            centerMode: true,
            centerPadding: "60px",
            dots: false,
            infinite: true,
            speed: 500,
            swipeToSlide: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            nextArrow: null,
            prevArrow: null
        }
    }








    return (
        <div>
            <FsLightbox
                toggler={toggler.toggler1}
                sources={[
                    'https://i.imgur.com/fsyrScY.jpg',
                    'https://www.youtube.com/watch?v=xshEZzpS4CQ',
                    'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
                ]}
            />
            <FsLightbox
                toggler={toggler.toggler2}
                sources={[
                    'https://i.imgur.com/fsyrScY.jpg',
                    'https://www.youtube.com/watch?v=xshEZzpS4CQ',
                    'https://www.youtube.com/watch?v=xshEZzpS4CQ',
                    'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
                ]}
            />
            <FsLightbox
                toggler={toggler.toggler3}
                sources={[
                    'https://i.imgur.com/fsyrScY.jpg',
                    'https://www.youtube.com/watch?v=xshEZzpS4CQ',
                    'https://www.youtube.com/watch?v=xshEZzpS4CQ',
                    'https://www.youtube.com/watch?v=xshEZzpS4CQ',
                    'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
                ]}
            />
            <FsLightbox
                toggler={toggler.toggler4}
                sources={[
                    'https://i.imgur.com/fsyrScY.jpg',
                    'https://www.youtube.com/watch?v=xshEZzpS4CQ',
                    'https://www.youtube.com/watch?v=xshEZzpS4CQ',
                    'https://www.youtube.com/watch?v=xshEZzpS4CQ',
                    'https://www.youtube.com/watch?v=xshEZzpS4CQ',
                    'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
                ]}
            />

            <FixedFeedBackAnimateLines fixedAnimationState={state.fixedAnimationState} />
            {/* <div className={`fixed w-auto h-auto pointer-events-none z-40 bottom-5 right-5 lg:top-10 lg:left-10`}> <img className={`h-20 lg:h-28`} src={Logo2} alt=""/> </div>          */}

            <Container maxWidth='lg'>

                <div className={`flex items-center flex-col lg:flex-row  relative justify-center`}>

                    <div className={`absolute opacity-20 pointer-events-none right-1/2 top-1/5 z-0`}>
                        <div className={`border w-96 h-96  border-custom-orange-default`}></div>
                        <div className={`border w-96 h-96 border-custom-orange-default absolute -right-1/2 -bottom-1/2`}></div>
                    </div>

                    {/* Titulo que fica ao lado do video no desktop mas acima do video no mobile  */}
                    <div>
                        <Title maxWidth={`max-w-xl`} addon={true}>Reduza em até 80% os bolos e <span className={`text-custom-orange-default`}>Dobre o Número de Matrículas</span></Title>
                        <p className={`font-roboto max-w-lg pl-8 font-light text-md`}><span className={`font-bold`}>Observação importante:</span> Mesmo se você estiver Home Office ou for EAD.</p>
                    </div>

                    {/* Parte que contem titulo secundario e video */}

                    <div className={`flex w-full relative pt-4 lg:pt-20 p-0 flex-col-reverse md:flex-col-reverse items-center justify-center sm:max-w-2xl gap-4 z-10`}>
                        <div className={`absolute z-10  pointer-events-none -bottom-15 md:-bottom-10 -right-5`}><Artistic template={`corner1`} entranceDelay={0.2} /> </div>

                        <div>   
                            <p className={`font-sansPro md:font-lato font-bold text-xl md:text-3xl`}>Descubra o passo a passo técnico, simples e prático para você matricular diariamente, parar de sobreviver e começar a finalmente Viver de Matrículas.</p>
                            <p className={`font-sansPro md:font-lato font-light text-lg md:text-2xl`}>Se livre deste perigo de não bater as metas todos os meses  <span className={`text-custom-orange-default`}>de uma vez por todas!</span></p>

                        </div>

                        {/* <div style={{height: '400px'}} className={`w-full bg-gray-800`}>
                            <ReactPlayer          
                            width='100%'        
                            height="400px"  
                            controls={true}                                  
                            url={'https://www.youtube.com/watch?v=UlI1Iu6ULEU'} 
                            />
                     </div> */}
                        <div className={` border-2 relative border-custom-orange-default rounded-lg w-full lg:h-96 md:h-72 h-48`}>                         
                            {/* <img src={Covidfree} /> */}
                            {isFirstVideoLoading && <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
                                <div className={`h-12 w-12 border-2 animate-spin border-custom-orange-default`}></div>
                                </div>}                            <ReactPlayer                                        
                                        onReady={()=>{setisFirstVideoLoading(false)}}
                                        width='100%'
                                        height="100%"                                       
                                        controls={true}
                                        url={'https://youtu.be/L8xSRpJGCY8'}
                            />

                        </div>
                    </div>
                </div>

            </Container>


            <div className={`relative z-10 pt-4`}>
                <CallToActionForPayment hotmartLink={props.hotmartLink} buttonText={`Quero Dobrar minhas Matriculas`} />
            </div>





            <Section elevation={2} template={1} title={`Os Problemas.`} icon={<WarningIcon style={{ height: '40px', width: '40px' }} />}>


                <div className={`flex gap-4 flex-col md:flex-row items-center md:items-start justify-around p-4`}>
                    <IconBox height={`h-auto`} template={1} icon={<CancelScheduleSendIcon style={{ height: '40px', width: '40px' }} />} title={`Agendei com uma pessoa e ela não foi ao atendimento`} >
                        <p className={``}>Acho que meu script está ruim. Preciso mudar algumas coisas. Isso sempre acontece comigo e eu não sei mais o que fazer.</p>
                    </IconBox>
                    <IconBox height={`h-auto`} template={1} icon={<SearchIcon style={{ height: '40px', width: '40px' }} />} title={`Ficou de voltar para matricular e desapareceu`} >
                        <p className={``}>Poxa! Fiquei mais de uma hora atendendo. Fiz tudo certinho. Ele disse que amou o método e a escola.... Não entendo!</p>
                    </IconBox>
                    <IconBox height={`h-auto`} template={1} icon={<SentimentVeryDissatisfiedIcon style={{ height: '40px', width: '40px' }} />} title={`"Me liga daqui 10 minutinhos". Nunca mais me atendeu`} >
                        <p className={``}>Por que as pessoas fazem isso? Será que estou falando demais? Acho que ninguém mais quer fazer curso hoje em dia.</p>
                    </IconBox>
                </div>

                <div className={`flex gap-4 ml-4 md:ml-0 flex-col md:flex-row items-center md:items-start  justify-around p-4`}>
                    <IconBox template={2} icon={<PermContactCalendarIcon style={{ height: '40px', width: '40px' }} />} title={`Não tenho mais nenhum contato para ligar`} title2={`
`} >
                        <p className={``}>As pessoas não gostam de indicar os amigos. Os leads da internet estão ruins e eu sempre fico sem contato. Está realmente difícil.</p>
                    </IconBox>
                    <IconBox template={2} icon={<CancelPresentationIcon style={{ height: '40px', width: '40px' }} />} title={`Fiz a pré-matrícula e o cliente foi abduzido`} title2={`
`} >
                        <p className={``}>A única explicação é a abdução. Só pode. Eu disse tudo: "Vou garantir sua vaga na turma", "Vou dar baixa no meu sistema". Até marcamos um horário e NADA!</p>
                    </IconBox>
                    <IconBox template={2} icon={<CancelIcon style={{ height: '40px', width: '40px' }} />} title={`"Vou pensar melhor e depois eu volto"`} title2={`
`} >
                        <p className={``}>No que essa criatura precisa pensar? Ficamos 1 hora esclarecendo dúvidas e ele disse que estava ok. Eles falam isso só pra ir embora e nunca mais atender.</p>
                    </IconBox>
                </div>



            </Section>

            {/*Sessão quem somos */}

            <Section elevation={2} template={1} title={`Quem Somos?`} icon={<PersonIcon style={{ height: '40px', width: '40px' }} />}>

                <div className={`grid grid-cols-1 md:grid-cols-2 gap-4 pt-8 pb-8`}>
                    {/* Wrapper da imagem do higor com texto*/}
                    <div className={`relative`}>
                        {/* Image Manipulation Wrapper */}

                        {smDown &&
                            <div style={{ width: '150vw', height: 'auto', marginLeft: '-35vw' }} className={`flex m-auto relative items-center justify-center`}>
                                <img className={`object-none object-center w-full h-auto`} src={Higor1} />
                            </div>}

                        {!smDown &&
                            <div className={`flex m-auto relative items-center justify-center`}>
                                <img className={`object-none object-center w-full h-auto`} src={Higor1} />
                            </div>}


                        <div className={`pl-8 md:pl-16 pt-4`}>
                            <p className={`font-lato font-bold text-xl`}>Higor Fabiano</p>
                            <p className={`font-roboto font-light`}>Matriculador Profissional</p>
                        </div>
                    </div>
                    {/* wrapper do texto e das redes sociais */}
                    <div className={`flex flex-col gap-4 max-w-sm p-8 border-custom-orange-default`}>
                        <p className={`font-roboto font-light md:text-lg`}>
                            E ai!!! Eu sou Higor de Almeida Fabiano e eu também fico 1 hora ouvindo lamentações, alegrias, sendo um psicólogo/amigo e as vezes consultor. E foi assim que entendi o poder que tinha nas mãos: Transformar a vida das pessoas, e a minha, por meio da Educação. Foi no dia que entendi isso que decidi largar o Direito e me profissionalizar, me tornando um "Matriculador" ‍ Sempre digo que cada matrícula que faço é um tijolinho a mais no meu objetivo. Se não fossem as matrículas eu não estaria onde estou hoje. Se você está aqui é porque também sabe o valor da matrícula, tanto para o próximo quanto para você
                        </p>
                        {/* Wrapper das redes sociais */}
                        <div className={`flex items-center justify-end gap-2`}>
                            <div className={``}><a href="https://twitter.com/matriculemais"><TwitterIcon style={{ fontSize: 35, color: '#ff6a00' }} /> </a></div>
                            <div className={``}><a href="https://www.youtube.com/channel/UCFqWTYmZZNwTSDBVEEjPR4Q"><YouTubeIcon style={{ fontSize: 35, color: '#ff6a00' }} /></a></div>
                            <div className={``}><a href="https://www.facebook.com/aescoladematriculas?_rdc=2&_rdr"><FacebookIcon style={{ fontSize: 35, color: '#ff6a00' }} /></a></div>
                            <div className={``}><a href="https://www.instagram.com/escoladematriculas/"><InstagramIcon style={{ fontSize: 35, color: '#ff6a00' }} /></a></div>
                        </div>

                    </div>
                </div>

            </Section>

            <Section elevation={2} template={1} titleElement={

                <div className={`flex flex-col gap-4`}>
                    <strong className={'font-bold text-3xl md:text-6xl font-lato'}> O que é o TREINAMENTO <br /><span className={`text-custom-orange-default`}>Matrícula Implacável?</span>  </strong>
                    <p className={`text-xl text-gray-600 md:text-2xl font-lato`}> Nós pegamos na sua mão e transformamos você em um Profissional,  um Matriculador Implacável</p>
                </div>
            } icon={<AirplayIcon style={{ height: '40px', width: '40px' }} />}>
                <div className={`grid grid-cols-1 gap-16`}>

                    <div className={`grid grid-cols-1 md:grid-cols-2 gap-4`}>
                        <div>
                            <Title addon={true}>Como fazemos isso?</Title>
                            <p className={`max-w-lg font-roboto font-light text-lg pl-8`}>São 16 módulos com mais de 60 aulas rápidas e práticas que te leva do amadorismo a profissionalização em 30 dias. <br/>

Suporte técnico com especilistas respondendo suas perguntas todos os dias.<br/>

Atividades extras. Materiais exclusivos encontros ao vivo e muito mais.<br/>

<strong>Veja a seguir o que você vai encontrar no treinamento.</strong></p>

                        </div>
                        <div className={`w-full h-full flex items-center justify-center`}>
                            <img className={`rounded-xl shadow-4xl md:w-96 md:h-96`} src={GiftWoman} alt=""></img>
                        </div>


                    </div>

                    <div className={`grid grid-cols-1 pt-4 gap-2 relative`}>

                        <div className={`w-1/2 h-1/2 border border-custom-orange-default z-10 opacity-30 absolute left-32 top-1/2 transform -translate-y-1/2`}>

                        </div>


                        <div className={`grid grid-cols-1 relative z-20 justify-items-center md:grid-cols-3 pt-4 pb-4 gap-4`}>


                            <IconBox height={`h-auto`} template={1} icon={<CheckIcon style={{ height: '40px', width: '40px' }} />} title={`
                                                      Comunicar`} >
                                <p className={``}>No final do treinamento você conseguirá fazer o cliente dizer SIM para matricular.</p>
                            </IconBox>

                            <IconBox height={`h-auto`} template={1} icon={<EventIcon style={{ height: '40px', width: '40px' }} />} title={`
                                                      Agendar`} >
                                <p className={``}>Sua agenda terá pouca marcação e muito atendimento.</p>
                            </IconBox>

                            <IconBox height={`h-auto`} template={1} icon={<AssessmentIcon style={{ height: '40px', width: '40px' }} />} title={`
                                                     Analisar`} >
                                <p className={``}>Só de falar 1 minuto com a pessoa você já saberá com quem está conversando.</p>
                            </IconBox>

                        </div>
                        <div className={`grid grid-cols-1 relative z-20 pt-4 pb-4 justify-items-center md:grid-cols-3 gap-4`}>

                            <IconBox height={`h-auto`} template={1} icon={<CallMadeIcon style={{ height: '40px', width: '40px' }} />} title={`Orientar`} >
                                <p className={``}>Saberá aplicar a técnica específica de Matriculador no momento certo.</p>
                            </IconBox>

                            <IconBox height={`h-auto`} template={1} icon={<SentimentSatisfiedIcon style={{ height: '40px', width: '40px' }} />} title={`Aplicar`} >
                                <p className={``}>Nunca mais vai ficar na dúvida e sem saber se vai funcionar ou não</p>
                            </IconBox>

                            <IconBox height={`h-auto`} template={1} icon={<DoneAllIcon style={{ height: '40px', width: '40px' }} />} title={`Multiplicar`} >
                                <p className={``}>Com o ciclo de leads infinitos nunca mais ficará sem contato pra matricular.</p>
                            </IconBox>


                        </div>
                        <div className={`grid grid-cols-1 relative z-20 pt-4 pb-4 justify-items-center md:grid-cols-3 gap-4`}>

                            <IconBox height={`h-auto`} template={1} icon={<QuestionAnswerIcon style={{ height: '40px', width: '40px' }} />} title={`Argumentar`} >
                                <p className={``}>Objeção nunca mais será um problema. Você eliminará antes dela surgir.</p>
                            </IconBox>

                            <IconBox height={`h-auto`} template={1} icon={<LocalLibraryIcon style={{ height: '40px', width: '40px' }} />} title={`Matricular`} >
                                <p className={``}>O cliente vai se conscientizar que assinar o contrato é a melhor escolha</p>
                            </IconBox>

                            <IconBox height={`h-auto`} template={1} icon={<SentimentVerySatisfiedIcon style={{ height: '40px', width: '40px' }} />} title={`Desfrutar`} >
                                <p className={``}>Dobrar suas comissões e desfrutar de todos os bônus que você perdeu até hoje.</p>
                            </IconBox>





                        </div>

                    </div>

                    <div className={`grid grid-cols-1 gap-4 border border-custom-orange-default p-4 md:p-8`}>
                        <div className={`grid grid-cols-1 md:grid-cols-2 gap-32 md:gap-4 p-4`}>

                            <div className={``}>
                                <Title addon={true}><span className={`text-custom-orange-default`}> Primeiro Módulo: </span></Title>
                                <p className={`max-w-lg font-roboto font-light text-lg pl-8`}>O mundo mudou, o cliente mudou e o jeito de matricular também mudou. Tudo o que você precisa saber sobre as Novas Regras do Jogo com o Primeiro Encontro Ao Vivo com O Matriculador  Higor Fabiano.</p>


                            </div>

                            <div style={{ maxHeight: '250px' }} className={`border h-96 shadow-4xl overflow-visible relative border-custom-orange-default`}>
                                <img style={{ minWidth: `200px`, minHeight: `300px` }} className={`h-96 absolute bottom-0 right-1/2 transform translate-x-1/2`} src={Mango} alt="" />
                            </div>

                        </div>

                        {/* <div className={`grid grid-cols-1 md:grid-cols-3 gap-4`}>

                                          <IconBox height={`h-auto`} template={2} icon={<GavelIcon style={{height: '40px', width: '40px'}}/>} title2={`Módulo 01`} title={`
                                                          As Regras do Jogo`} >
                                                                <p className={``}>No final do treinamento você conseguirá fazer o cliente dizer SIM para matricular.</p>
                                           </IconBox>
    
                                           <IconBox height={`h-auto`} template={2} icon={<RecordVoiceOverIcon style={{height: '40px', width: '40px'}}/>} title2={`Módulo 02 `} title={`
                                                          Master Class Ao Vivo`} >                                                               
                                                             <div>
                                                                    <p>2 aulas sobre como utilizar o whatsapp em suas matrículas e 1 aula sobre como captar mais 429 contatos em apenas 1 dia</p>
                                                                
                                                             </div>
                                           </IconBox>
    
                                           <IconBox height={`h-auto`} template={2} icon={<BusinessIcon style={{height: '40px', width: '40px'}}/>} title2={`Módulo 03`} title={`
                                                          Gestão de Tráfego para Escolas`} >
                                                                <p className={``}>Treinamento completo com mais de 15 aulas para você começar suas primeiras campanhas de captação pelo Facebook business.</p>
                                           </IconBox>

                    </div> */}
                    </div>

                    <div className={`grid grid-cols-1 gap-4 border border-custom-orange-default p-4 md:p-8`}>
                        <div className={`grid grid-cols-1 md:grid-cols-2 gap-32 md:gap-4 p-4`}>

                            <div className={``}>
                                <Title addon={true}><span className={`text-custom-orange-default`}> Método 6 As: </span></Title>
                                <p className={`max-w-lg font-roboto font-light text-lg pl-8`}>Quer saber exatamente o que dizer para atrair a atenção do seu cliente e ele não desligar? Está tudo aqui.</p>
                            </div>

                        </div>

                        <div className={`grid place-items-center grid-cols-1 items-start md:grid-cols-4 gap-y-8 gap-4`}>

                            <IconBox height={`h-auto`} titleSize={`text-4xl`} template={1} icon={<DynamicFeedIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 02`} title={`
                                                          A1`} >
                                <p className={``}>Eu Desenvolvi um Programa de Estudos para você se transformar em um Matriculador em apenas 30 dias</p>
                            </IconBox>

                            <IconBox height={`h-auto`} titleSize={`text-4xl`} template={1} icon={<DynamicFeedIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 03`} title={`
                                                          A2`} >
                                <p className={``}>As técnicas de agendamento que você ainda usa e não funcionam mais. Entenda o que fazer</p>
                            </IconBox>

                            <IconBox height={`h-auto`} titleSize={`text-4xl`} template={1} icon={<DynamicFeedIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 04`} title={`
                                                          A3`} >
                                <p className={``}>Técnica poderosa de persuasão para o seu cliente entregar todas as informações que você precisa</p>
                            </IconBox>

                            <IconBox height={`h-auto`} titleSize={`text-4xl`} template={1} icon={<DynamicFeedIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 05`} title={`
                                                          A4`} >
                                <p className={``}>Saiba de uma coisa: o agendamento não é para falar do seu curso. Aprenda a usar o equilíbrio e conquiste o SIM</p>
                            </IconBox>

                            <IconBox height={`h-auto`} titleSize={`text-4xl`} template={1} icon={<DynamicFeedIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 06`} title={`
                                                          A5`} >
                                <p className={``}>Pare de espantar seus clientes com frases e perguntas prontas. Descubra aquilo que ninguém te ensina na hora de agendar</p>
                            </IconBox>

                            <IconBox height={`h-auto`} titleSize={`text-4xl`} template={1} icon={<DynamicFeedIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 07`} title={`
                                                          A6`} >
                                <p className={``}>Seu cliente já conhece as técnicas que você usa com ele, por isso você toma bolo. Aprenda como inovar nesta hora</p>
                            </IconBox>

                            <IconBox height={`h-auto`} template={2} icon={<RecordVoiceOverIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 8`} title={`
                                                          Encontro Ao Vivo`} >
                                <p className={``}>Tenha um Encontro ao Vivo com o Matriculador para esclarecer suas dúvidas do Método 6 As e aplicar o conteúdo com atividades práticas.</p>
                            </IconBox>

                            <IconBox height={`h-auto`} template={2} icon={<LiveTvIcon style={{ height: '40px', width: '40px' }} />} title2={`Especial`} title={`
                                                          Estudo de caso Ao vivo`} >
                                <p className={``}>Você receberá atividades específicas durante a sua Jornada de 30 dias para aplicar, validar e colher os resultados do seu treinamento.</p>
                            </IconBox>

                        </div>
                    </div>


                    <div className={`grid grid-cols-1 gap-4 border border-custom-orange-default p-4 md:p-8`}>
                        <div className={`grid relative grid-cols-1 md:grid-cols-2 gap-32 md:gap-4 p-4`}>

                            <div className={`relative`}>
                                <Title addon={true}><span className={`text-custom-orange-default`}>Método MATRÍCULA</span></Title>
                                {/* <p className={`max-w-lg font-roboto font-light text-lg pl-8`}></p> */}
                                <div className={`absolute z-10 -bottom-12 -left-5`}><Artistic template={`corner1`} entranceDelay={0.2} />
                                </div>

                            </div>

                            <div className={`border z-20 h-64 shadow-4xl overflow-visible relative border-custom-orange-default`}>
                                <img style={{ minWidth: `350px` }} className={`h-96 absolute bottom-0 right-1/2 transform translate-x-1/2`} src={SaveMoney} alt="" />
                            </div>

                        </div>

                        <div className={`grid grid-cols-1 md:grid-cols-4 place-items-center gap-4`}>

                            <IconBox height={`h-auto`} template={1} icon={<MicOffIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 9`} title={`A linguagem silenciosa`} >
                                <p className={``}>Aplique PNL, Linguagem hipnótica e técnicas de demonstração de poder em seu cliente e gere credibilidade</p>
                            </IconBox>
                            <IconBox height={`h-auto`} template={1} icon={<AccessibilityNewIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 10`} title={`A matriz de relacionamento humano`} >

                                <p className={``}>Descubra como aplicar a Matriz de Relacionamento Humano e já plante a semente da decisão</p>
                            </IconBox>
                            <IconBox height={`h-auto`} template={1} icon={<SchoolIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 11`} title={`A sua escola e a rotina do cliente`} >

                                <p className={``}>Você não precisa de um “Curso Bonito” para matricular e seu cliente não escolhe horário. descubra como criar uma conexão real com seu cliente</p>
                            </IconBox>
                            <IconBox height={`h-auto`} template={1} icon={<CheckIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 12`} title={`Antecipando o SIM do seu cliente`} >

                                <p className={``}>Não é seu cliente que escolhe o horário e como vai estudar. Contorne esta objeção com uma técnica de persuasão</p>
                            </IconBox>
                            <IconBox height={`h-auto`} template={1} icon={<NetworkCheckIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 13`} title={`Transitando entre o racional e o emocional`}>

                                <p className={``}>Antecipe o SIM do seu cliente antes mesmo dele conhecer seu curso. Aqui você já vai começar a regar a semente que plantou</p>
                            </IconBox>
                            <IconBox height={`h-auto`} template={1} icon={<AttachMoneyIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 14`} title={`Fechando a matrícula antes do preço`} >
                                <p className={``}>Prepare seu cliente para o fechamento financeiro antes mesmo dele saber “quanto custa</p>
                            </IconBox>

                            <IconBox height={`h-auto`} template={1} icon={<AllInclusiveIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 15`} title={`Leads infinitos`} >
                                <p className={``}>Crie um sistema sustentável e nunca mais dependa de ninguém para bater suas metas e fazer suas matrículas</p>
                            </IconBox>
                            <IconBox height={`h-auto`} template={1} icon={<AirplanemodeActiveIcon style={{ height: '40px', width: '40px' }} />} title2={`Módulo 16`} title={`Matricule como um especialista`} >
                                <p className={``}>Negocie como um Especialista aplicando estratégia que conecta com o emocional do seu cliente</p>
                            </IconBox>
                            {/* <IconBox height={`h-auto`} template={1} icon={<LiveTvIcon style={{height: '40px', width: '40px'}}/>} title2={`Módulo 18`} title={`Mentoria Ao vivo`} >
                                                                <p className={``}>Tenha um momento único com O Matriculador Higor Fabiano e esclareça todas as suas dúvidas</p>
                                          </IconBox> */}


                        </div>


                    </div>
                    <div>
                        <div className={`grid grid-cols-1 pb-8 md:grid-cols-2 gap-32 lg:gap-16 md:gap-4 p-4`}>

                            <div className={`bg-custom-orange-default`}>
                                <Title addon={true}><span className={`text-gray-100`}> O&nbsp;TREINAMENTO: </span></Title>

                            </div>

                        </div>

                        {/* <div className={`grid grid-cols-1 md:grid-cols-3 gap-4 `}>
                                                                                    <IconBox  height={`h-auto`} template={2} icon={<EmojiFlagsIcon style={{height: '40px', width: '40px'}}/>} title2={`Master Class `} title={`
                                                                                                    Como contratar um Matriculador`} >
                                                                                                            <p className={``}> Contratar um consultor ou ser contratado é um dos maiores desafios hoje em dia. Aprenda o passo a passo de um processo seletivo profissional </p>
                                                                                    </IconBox>
                                                
                                                                                    <IconBox height={`h-auto`} template={2} icon={<SupervisedUserCircleIcon style={{height: '40px', width: '40px'}}/>} title2={`Master Class `} title={`
                                                                                                    Gestão de um Time Matriculador`} >
                                                                                                            <p className={``}>  Você sabe como manter seu time produtivo e batendo as metas da sua Escola?</p>
                                                                                    </IconBox>
                                            
                                                                                    <IconBox height={`h-auto`} template={2} icon={<OpenInNewIcon  style={{height: '40px', width: '40px'}}/>} title2={`Master Class `} title={`
                                                                                                    O Início de uma nova fase`} >
                                                                                                            <p className={``}>Quando acabamos um ciclo sempre inicia uma nova fase. O que fazer então?</p>
                                                                                    </IconBox>
                                                                            </div> */}
                    </div>

                    {/* <div className={`border bg-custom-orange-default border-custom-orange-default flex flex-col items-center justify-center gap-4 p-8`}>

                                        <h3 className={`font-sansPro text-gray-100 text-4xl`}>OS PRIMEIROS 10 ALUNOS RECEBERÃO</h3>
                                        <h1 className={`text-4xl text-gray-200 font-lato font-bold`}>1 Mentoria em Grupo sobre Gestão Estratégica Comercial</h1>
                                        <h3 className={`font-sansPro text-gray-300 font-light md:text-5xl text-4xl`}>R$8000,00</h3>

                                    </div> */}



                    <div className={`grid grid-cols-1 md:grid-cols-2 gap-4 md:p-8 p-2`}>
                        <div className={`md:p-8`}>
                            <img className={`shadow-3xl object-fit`} src={SmillingMan} alt="" />
                        </div>
                        <div className={`flex flex-col items-center justify-center gap-4 md:p-4`}>
                            {/* <p className={`font-lato font-bold text-center md:text-start text-3xl`}>A Mentoria</p>              */}
                            <p className={`max-w-lg font-roboto font-light text-lg md:pl-8`}>Eu imagino o que você está passando hoje, pois já passei por isso, como por exemplo: ter dúvidas e não poder perguntar, não saber o que fazer e não ter a quem recorre. Querer dar um passo a mais e não saber por onde começar. Por esse motivo o <strong>aluno do Treinamento Matrícula Implacável</strong> tem acompanhamento e suporte em um grupo de Alunos Exclusivo com diversos especialistas em matrículas muito bem direcionados e focados em ajudá-lo a  matricular diariamente e dobrar suas matrículas.
                            </p>
                        </div>
                    </div>

                </div>
            </Section>

            <Section elevation={2} template={1} titleElement={<strong className={'font-bold text-3xl md:text-6xl font-lato'}> Veja os resultados de alguns <br /><span className={`text-custom-orange-default`}>Matriculadores Implacáveis.</span>  </strong>} icon={<EmojiEventsIcon style={{ height: '40px', width: '40px' }} />}>

                <div className={`grid gri-cols-1 gap-16 pt-16 pb-16`}>

                    <div className={`grid grid-cols-1  gap-4 shadow-3xl rouned-lg`}>

                        <div className={`grid grid-cols-1 md:grid-cols-3 gap-4`}>


                            <div className={`grid grid-cols-1 gap-4`}>
                                <h3>Jucélia | Diretora de Graduação e Pós</h3>
                                <div style={{ height: '300px' }} className={`w-full bg-gray-200`}>
                                    <ReactPlayer
                                        width='100%'
                                        height="300px"
                                        controls={true}
                                        url={'https://youtu.be/oL0DMmwh9Vs'}
                                    />
                                </div>
                            </div>
                            <div className={`grid grid-cols-1 gap-4`}>
                                <h3>Fernando | Franqueado Curso de Idiomas</h3>
                                <div style={{ height: '300px' }} className={`w-full bg-gray-200`}>
                                    <ReactPlayer
                                        width='100%'
                                        height="300px"
                                        controls={true}
                                        url={'https://youtu.be/5s8ewLXMs2Q'}
                                    />
                                </div>
                            </div >
                            <div className={`grid grid-cols-1 gap-4`}>
                                <h3>Fábio | Franqueado de Curso Profissionalizante</h3>
                                <div style={{ height: '300px' }} className={`w-full bg-gray-200`}>
                                    <ReactPlayer
                                        width='100%'
                                        height="300px"
                                        controls={true}
                                        url={'https://youtu.be/XAP9YbGb5LI'}
                                    />
                                </div>
                            </div>

                            <div className={`grid grid-cols-1 gap-4`}>
                                <h3>Paulo | Gestor Cursos Livre e Reforço Escolar</h3>
                                <div style={{ height: '300px' }} className={`w-full bg-gray-200`}>
                                    <ReactPlayer
                                        width='100%'
                                        height="300px"
                                        controls={true}
                                        url={'https://youtu.be/SXyTPPtPBnA'}
                                    />
                                </div>
                            </div>
                            <div className={`grid grid-cols-1 gap-4`}>
                                <h3>Liz | Consultora Curso Técnico</h3>
                                <div style={{ height: '300px' }} className={`w-full bg-gray-200`}>
                                    <ReactPlayer
                                        width='100%'
                                        height="300px"
                                        controls={true}
                                        url={'https://youtu.be/DPc-tmU105M'}
                                    />
                                </div>
                            </div >
                            <div className={`grid grid-cols-1 gap-4`}>
                                <h3>Jennifer | Gerente Cursos de Idiomas</h3>
                                <div style={{ height: '300px' }} className={`w-full bg-gray-200`}>
                                    <ReactPlayer
                                        width='100%'
                                        height="300px"
                                        controls={true}
                                        url={'https://youtu.be/D1Vc7LjnnOc'}
                                    />
                                </div>
                            </div>

                            <div className={`grid grid-cols-1 gap-4`}>
                                <h3>Yuli | Supervisora Curso Profissionalizante</h3>
                                <div style={{ height: '300px' }} className={`w-full bg-gray-200`}>
                                    <ReactPlayer
                                        width='100%'
                                        height="300px"
                                        controls={true}
                                        url={'https://youtu.be/U4KdNmMRHGQ'}
                                    />
                                </div>
                            </div>
                            <div className={`grid grid-cols-1 gap-4`}>
                                <h3>Jéssica | Franqueada Cursos Profissionalizantes</h3>
                                <div style={{ height: '300px' }} className={`w-full bg-gray-200`}>
                                    <ReactPlayer
                                        width='100%'
                                        height="300px"
                                        controls={true}
                                        url={'https://youtu.be/2b4D5fB3dTY'}
                                    />
                                </div>
                            </div >
                            <div className={`grid grid-cols-1 gap-4`}>
                                <h3>Elizangela | Franqueada Curso Idiomas</h3>
                                <div style={{ height: '300px' }} className={`w-full bg-gray-200`}>
                                    <ReactPlayer
                                        width='100%'
                                        height="300px"
                                        controls={true}
                                        url={'https://youtu.be/_Gga9vItLmo'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`grid grid-cols-1 md:grid-cols-2 gap-4 shadow-3xl rouned-lg`}>
                        <Title addon={true}>Mais alunos que <span className={`text-custom-orange-default`}>Dobraram suas Matrículas</span></Title>
                        <div style={{ maxWidth: '1000px' }} className={`relative`}>
                            <Slider ref={slider2} {...settings}>
                                <div key={1}>
                                    <img className={``} src={ImageA} alt="" />
                                </div>
                                <div key={2}>
                                    <img className={``} src={ImageB} alt="" />
                                </div>
                                <div key={3}>
                                    <img className={``} src={ImageC} alt="" />
                                </div>
                                <div key={4}>
                                    <img className={``} src={ImageE} alt="" />
                                </div>
                            </Slider>

                            {/* import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'; */}
                            <div className={`absolute top-1/2 -right-5 transform-y-1/2`}>
                                <IconButton aria-label="" onClick={() => { slider2.current.slickNext() }}>
                                    <div className={``}><ArrowForwardIosIcon style={{ height: '35px', width: '35px', padding: '5px', backgroundColor: '#ff6a00', borderRadius: '100%', color: 'white' }} /></div>
                                </IconButton>
                            </div>
                            <div style={{ marginTop: '5px' }} className={`absolute top-1/2 -left-5 transform-y-1/2`}>
                                <IconButton aria-label="" onClick={() => { slider2.current.slickPrev() }}>
                                    <div className={`transform rotate-180`}><ArrowForwardIosIcon style={{ height: '35px', width: '35px', padding: '5px', backgroundColor: '#ff6a00', borderRadius: '100%', color: 'white' }} /></div>
                                </IconButton>
                            </div>
                        </div>

                    </div>

                    {/* <div className={`grid grid-cols-1 md:grid-cols-2 gap-4 shadow-3xl rouned-lg`}>
                        <Title addon={true}>Quer que sua turma leve seu nome?<span className={`text-custom-orange-default`}> Faça como Jucélia Ribeiro</span></Title>
                        <div style={{ maxWidth: '1000px' }} className={`relative`}>
                            <Slider ref={slider1} {...settings}>
                                <div key={1}>
                                    <img className={``} src={Image1} alt="" />
                                </div>
                                <div key={2}>
                                    <img className={``} src={Image2} alt="" />
                                </div>
                                <div key={3}>
                                    <img className={``} src={Image3} alt="" />
                                </div>
                                <div key={4}>
                                    <img className={``} src={Image4} alt="" />
                                </div>
                                <div key={5}>
                                    <img className={``} src={Image5} alt="" />
                                </div>
                                <div key={6}>
                                    <img className={``} src={Image6} alt="" />
                                </div>
                            </Slider>

                          
                            <div className={`absolute top-1/2 -right-5 transform-y-1/2`}>
                                <IconButton aria-label="" onClick={() => { slider1.current.slickNext() }}>
                                    <div className={``}><ArrowForwardIosIcon style={{ height: '35px', width: '35px', padding: '5px', backgroundColor: '#ff6a00', borderRadius: '100%', color: 'white' }} /></div>
                                </IconButton>
                            </div>
                            <div style={{ marginTop: '5px' }} className={`absolute top-1/2 -left-5 transform-y-1/2`}>
                                <IconButton aria-label="" onClick={() => { slider1.current.slickPrev() }}>
                                    <div className={`transform rotate-180`}><ArrowForwardIosIcon style={{ height: '35px', width: '35px', padding: '5px', backgroundColor: '#ff6a00', borderRadius: '100%', color: 'white' }} /></div>
                                </IconButton>
                            </div>
                        </div>

                    </div> */}

                </div>
            </Section>



            <Section elevation={2} template={1} titleElement={<strong className={'font-bold text-3xl md:text-6xl font-lato'}> Garanta sua vaga e receba <br /><span className={`text-custom-orange-default`}>Bônus Exclusivos!</span>  </strong>} icon={<CardGiftcardIcon style={{ height: '40px', width: '40px' }} />}>



                <div className={`grid grid-cols-1 pt-4 gap-8 relative`}>

                    <div className={`w-1/2 h-1/2 border border-custom-orange-default z-10 opacity-30 absolute left-32 top-1/2 transform -translate-y-1/2`}></div>


                    <div className={`grid grid-cols-1 relative z-20 justify-items-center md:grid-cols-3 gap-4`}>

                        {/* <IconBox height={`h-auto`} template={1} icon={<FreeBreakfastIcon style={{height: '40px', width: '40px'}}/>} title={`
                            Grupo do cafezinho [R$1000,00]`} >
                                                        <p className={``}>Único grupo do Brasil com Gestores e Matriculadores que não te vêem como um concorrente e sim um Parceiro. Sempre ajudando e trocando informações valiosas</p>
                                                    </IconBox> */}

                        <IconBox height={`h-auto`} template={1} icon={<FreeBreakfastIcon style={{ height: '40px', width: '40px' }} />} title={`
                            Passaporte Premium Vitalício [Imensurável]`} >
                            <p className={``}>Uma vez que você se torna um MI [Matriculador(a) Implacável], você terá acesso a todos os nossos treinamentos/mentorias e <strong>eventos secretos</strong> com descontos exclusivos e com exclusividade.</p>
                        </IconBox>
                        {/* 
                                                    <div className="relative md:mt-16">
                                                                                  
                                                                                   <div className={`relative z-40`}>
                                                                                        <IconBox height={`h-auto`} template={1} icon={<LiveTvIcon style={{height: '40px', width: '40px'}}/>} title={`
                                                                                                    Aulas Ao Vivo`} >   
                                                                                                    <p className={``}>Ao final de cada fase você tem uma aula ao vivo para alinhar todo conteúdo que foi entregue.</p>
                                                                                        </IconBox>
                                                                                   </div>
                                                                                                                    
                                                                                    <div className={`absolute z-10 bottom-12 -right-5`}><Artistic template={`corner1`} entranceDelay={0.2} />                                                                                    
                                                                                    </div>
                                                                                
                                                    </div> */}

                        <IconBox height={`h-auto`} template={1} icon={<GroupWorkIcon style={{ height: '40px', width: '40px' }} />} title={`
                            12 meses de Acompanhamento [R$ 3.000,00]`} >
                            <p className={``}>Nosso time de especialistas responderá a todas suas dúvidas no grupo de alunos durante os 30 dias e também na plataforma durante 12 meses. Você NUNCA MAIS ficará com uma dúvida técnica sobre matrícula.</p>
                        </IconBox>

                        <IconBox height={`h-auto`} template={1} icon={<PeopleIcon style={{ height: '40px', width: '40px' }} />} title={`
                            Encontros Ao vivo [R$ 1.500,00 cada]`} >
                            <p className={``}>Você terá a oportunidade de ter encontros ao vivo com o Higor Fabiano para fazer suas perguntas direto para ele em encontros específicos, dinâmicos e práticos.</p>
                        </IconBox>
                        {/* <IconBox height={`h-auto`} template={1} icon={<SupportAgentIcon style={{ height: '40px', width: '40px' }} />} title={`
                            Intervenção do Matrículador [R$5.000,00]`} >
                            <p className={``}>Mais de 10 anos de estudos em Neuropersuasão e Neuromarketing aplicado ao mundo das Durante 60 dias você poderá perguntar tudo o que quiser e esclarecer todas suas dúvidas sobre a Mentoria para nosso time de especialistasMatrículas para você entender a mente do seu cliente.</p>
                        </IconBox>
                        <IconBox height={`h-auto`} template={1} icon={<PsychologyIcon style={{ height: '40px', width: '40px' }} />} title={`
                            NeuroMatrículas [R$1.000,00]`} >
                            <p className={``}>Durante 60 dias você poderá perguntar tudo o que quiser e esclarecer todas suas dúvidas sobre a Mentoria para nosso time de especialistas</p>
                        </IconBox>

                        <div className={``}>
                            <IconBox height={`h-auto`} template={1} icon={<AccessTimeIcon style={{ height: '40px', width: '40px' }} />} title={`Acesso ao Treinamento por 1 Ano [R$3.000,00]`}>
                                <p className={``}>O Objetivo da Mentoria não é apenas te gerar resultado rápido. Por isso vamos dar acesso de 1 ano para o Treinamento Gravado e a Mentoria Ao vivo.</p>
                            </IconBox>
                        </div> */}


                    </div>
                    <div className={`grid grid-cols-1 relative z-20 pt-8 pb-8 justify-items-center md:grid-cols-3 gap-4`}>

                        {/* <IconBox height={`h-auto`} template={1} icon={<UpdateIcon style={{height: '40px', width: '40px'}}/>} title={`Atualização da Próxima Turma [R$997,00]`} >
                                                        <p className={``}>Todo o conteúdo novo que vier na Próxima Turma do método Matrícula Implacável você receberá SEM CUSTO. Isso significa que se entrar algum vídeo novo, aula nova, conteúdo novo ou atualização de material você receberá GRATUITAMENTE.</p>
                                                    </IconBox> */}


                        {/* <div className={`relative`}>
                                                      <div className={`relative z-30`}>
                                                            <IconBox height={`h-auto`} template={1} icon={<CheckIcon  style={{height: '40px', width: '40px'}}/>} title={`Treinamento Matrícula Express [R$997,00]`}>
                                                                <p className={``}>Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.</p>
                                                            </IconBox>
                                                      </div>
                                                      <div className={`absolute z-10 bottom-28 -left-4`}><Artistic template={`corner2`} entranceDelay={0.2} />                                                                                    
                                                       </div>
                                                  </div> */}

                    </div>
                </div>


                {/* <div className={`border pt-16 pb-16 bg-custom-orange-default border-custom-orange-default flex flex-col items-center justify-center gap-4 p-8`}>

<h1 className={`text-4xl text-gray-200 font-lato font-bold`}>BÔNUS EXTRA PARA O PRIMEIRO ALUNO QUE SE MATRICULAR</h1>
<h3 className={`font-sansPro text-gray-300 font-light md:text-5xl text-4xl line-through`}>R$2.500,00</h3>                 
<h3 className={`font-sansPro text-gray-300 font-light text-lg`}>  Nesta consultoria você terá 1 HORA comigo para podermos tratar um problema pontual. Aquilo que tira o seu sono e você NÃO SABE A SOLUÇÃO. As vezes até imagina, mas não sabe se está certo ou não sabe como executar.
Como Extra eu entrego uma REVISÃO de mais 1 HORA, depois de 15 dias, para você me trazer as dificuldades na execução e eu REFINAR os seus processos. Este bônus é cumulativo com todos os outros apresentados nesta página.
</h3> 

</div> */}
            </Section>

            <div className={`bg-green-600 mb-4 flex flex-col items-center justify-center gap-4 p-8`}>

                <h1 className={`text-4xl text-gray-200 font-lato font-bold`}>Valor aproximado somente dos bônus:</h1>
                <h3 className={`font-sansPro text-gray-300 font-light md:text-5xl text-4xl line-through`}>R$ 6000,00</h3>

            </div>

            <Section elevation={2} template={1} titleElement={<strong className={'font-bold text-3xl md:text-6xl font-lato'}> Só você é capaz de mudar  <br /><span className={`text-custom-orange-default`}>Seus Resultados!</span>  </strong>} icon={<TrendingUpIcon style={{ height: '40px', width: '40px' }} />}>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 pt-8 pl-0 md:pl-8">
                    <div>
                        <img className="w-full md:w-2/3 mt-8" src={JayissaTestimonial} alt="Testemunho de Estudante" />
                    </div>
                    <div className={`gap-2`}>
                        <p className={`font-lato font-bold text-3xl text-gray-800`}>Ninguém no seu curso vai te pegar pela mão e ensinar o que fazer para matricular todo dia.</p>
                        <p className={`font-roboto font-light text-gray-700`}>Mas eu vou! Eu estou aqui justamente para isso! Seu resultado é o meu resultado. Vem comigo! Essa decisão é 100% sua! Ou você decide mudar ou você continua com os mesmos resultados.</p>

                        <div className=" w-full flex items-center justify-start pt-8 pb-8 flex-row mb-4">
                            <div className="left"><LabelImportantIcon style={{ fontSize: 48, color: '#ff6a00' }} /></div>
                            <div className="right"><h2 className="text-gray-800 text-lg font-roboto">Treinamento Matrícula Implacável</h2></div>
                        </div>

                        <Title addon={true}>E receba <span className={`text-custom-orange-default`}>Também:</span></Title>

                        {/* <div className=" w-full flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">Grupo de Networking 30 dias</h2></div>
                                </div> */}

                        <div className=" flex items-center justify-start flex-row">
                            <div className="left"><LabelImportantIcon style={{ fontSize: 48, color: '#ff6a00' }} /></div>
                            <div className="right"><h2 className="text-gray-800 text-lg font-roboto">12 meses de acompanhamento [R$3.000,00]</h2></div>
                        </div>


                        <div className=" flex items-center justify-start flex-row">
                            <div className="left"><LabelImportantIcon style={{ fontSize: 48, color: '#ff6a00' }} /></div>
                            <div className="right"><h2 className="text-gray-800 text-lg font-roboto">2 encontros ao vivo [ R$3.000,00]</h2></div>
                        </div>

                        {/* <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">Atualização da Próxima Turma</h2></div>
                                </div> */}

                        {/* <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">Treinamento Matrícula Express -  Acesso a todas as aulas por 1 ano
                                </h2></div>
                                </div> */}
                        {/* <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">Treinamento Completo sobre Gestão de Tráfego para Escolas (anúncios online)</h2></div>
                                </div> */}

                        {/* <h2 className="text-gray-800 text-lg font-roboto"> Aulas ao vivo:</h2>
                                                              
                                <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">1 – As regras do Jogo</h2></div>
                                </div>
    
                                <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">
                                    2 – Estudo de caso</h2></div>
                                    
                                </div>
                                <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">3 - Mentoria</h2></div>
                                </div>

                                <h2 className="text-gray-800 text-lg font-roboto">   MasterClass Ao vivo:</h2>
                              
                                <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">1 - Whatsapp e as Matrículas </h2></div>
                                </div>
                                <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">2 - Whatapp é melhor que ligação </h2></div>
                                </div>
                                <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">3 -  Como fazer 429 contatos com ações externas em apenas um dia</h2></div>
                                </div>
                                <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">4 -  Faça matrícula com apenas uma ligação</h2></div>
                                </div>
                                <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">5 -  Como contratar um Matriculador</h2></div>
                                </div>
                                <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">6 -  Gestão de um time Matriculador </h2></div>
                                </div>
                                <div className=" flex items-center justify-start flex-row">
                                    <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                    <div className="right"><h2 className="text-gray-800 text-lg font-roboto">7 -  O Início da nova fase</h2></div>
                                </div> */}
                        <div className=" flex items-center justify-start flex-row">
                            <div className="left"><LabelImportantIcon style={{ fontSize: 48, color: '#ff6a00' }} /></div>
                            <div className="right"><h2 className="text-gray-800 text-lg font-roboto">Passaporte Premium Vitalício [Imensurável]</h2></div>
                        </div>
                        {/* 
                        <div className=" flex items-center justify-start flex-row">
                            <div className="left"><LabelImportantIcon style={{ fontSize: 48, color: '#ff6a00' }} /></div>
                            <div className="right"><h2 className="text-gray-800 text-lg font-roboto">1 Consultoria Individual Personalizada</h2></div>
                        </div>
                        <div className=" flex items-center justify-start flex-row">
                            <div className="left"><LabelImportantIcon style={{ fontSize: 48, color: '#ff6a00' }} /></div>
                            <div className="right"><h2 className="text-gray-800 text-lg font-roboto">Intervenção do Matriculador para os Primeiros Mentorados</h2></div>
                        </div>
                        <div className=" flex items-center justify-start flex-row">
                            <div className="left"><LabelImportantIcon style={{ fontSize: 48, color: '#ff6a00' }} /></div>
                            <div className="right"><h2 className="text-gray-800 text-lg font-roboto">Treinamento em NeuroMatrículas</h2></div>
                        </div> */}
                        <div className={`flex items-center flex-col justify-center gap-4 pt-8 pb-8`}>
                            <p className={`font-roboto font-light text-gray-700`}>...e se você não achar que é o suficiente, não tem problema, eu assumo essa responsabilidade. Eu te dou 7 dias de garantia para pedir TODO o seu dinheiro de volta. Ou seja, seu RISCO É ZERO.</p>
                            {!props.esconderPrecoHotmart && <div className={`text-2xl border p-4 text-center border-custom-orange-default font-bold font-lato`}>{props.precoHotmart}</div>}
                        </div>
                        <CallToActionForPayment hotmartLink={props.hotmartLink} buttonText={`Quero fazer o dobro de matrículas`} />
                    </div>

                </div>



            </Section>

            <Section elevation={2} template={1} titleElement={<strong className={'font-bold text-3xl md:text-6xl font-lato'}> Alguma dúvida? <span className={`text-custom-orange-default`}>Ela pode estar aqui.</span>  </strong>} icon={<HelpOutlineIcon style={{ height: '40px', width: '40px' }} />}>
                <div className={`pt-16 pb-16`}><SimpleAccordion /></div>
                <p className={`font-roboto font-light text-lg p-2 text-gray-700`}>© Copyright 2018 Matrícula Implacável ® Escola de Matrículas ®</p>
                <p className={`font-roboto font-light text-lg p-2 text-gray-700`}>Todos os Direitos Reservados</p>
            </Section>
        </div>
    )
}