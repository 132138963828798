import { Strong } from 'gsap'
import React from 'react'
import Button from '@material-ui/core/Button'

export default function DescricaoDois() {

    const defaultParagraphTailwindStyle = "text-2xl font-sans text-gray-700 pb-8"

    return (
        <div className={`pb-16 pt-16 md:pt-0`}>

<h1 className={`text-center text-gray-800 md:text-4xl text-3xl font-sans font-bold md:pt-16 pt-8 pb-8 `}>TODAS AS ARGUMENTOS PRONTOS NA PALMA A SUA MÃO</h1>
  
   <div className={`border-2 border-custom-black-default p-2 md:pl-8 md:pr-8 `}>
       <p className={defaultParagraphTailwindStyle}>Durante toda minha vida como matriculador, a minha principal “pedra no sapato” <strong>eram as objeções</strong>.
       </p>
       <p className={defaultParagraphTailwindStyle}>Eu ficava me perguntando <strong>o porque será</strong> que as pessoas sempre dizem “Tá caro, não tenho tempo, vou pensar direitinho, preciso falar com a minha esposa, já passei da idade, <strong>tenho outras prioridades no momento..</strong>”
       </p>
       <p className={defaultParagraphTailwindStyle}>Além de listar e anotar <strong>todas as objeções que eu recebi</strong> durante durante <strong>16 anos como Matriculador</strong>, eu mapeei e desenvolvi um argumento <strong>para cada uma delas</strong>. O melhor é que eles estão nesse treinamento.
       </p>
   </div>

<div className={`mt-8`}>    
       <Button variant="contained" size="large" color="primary" fullWidth={true}>
    <div className={`text-gray-200`}>
        Quero Dobrar Minhas Matriculas
        </div>
       </Button>
</div>
            
        </div>
    )
}
