import React from 'react'

export default function TestimonialProfileCard(props) {
    return (
   <div className={`w-96 h-96 shadow-2xl relative rounded-lg`}>
     <div style={{backgroundImage: `url(${props.avatar})`}} className={`rounded-full bg-center bg-cover bg-no-repeat w-32 h-32 absolute top-0 right-1/2 transform translate-x-1/2 -translate-y-1/2`}></div>
     {props.children}
   </div>
    )
}
