import React, {useEffect, useRef, useState} from 'react'
import gsap from 'gsap'
import logoPageLoader from '../../images/3 (1).png'

export default function PageLoader() {
 
    const pageLoader = useRef(null)   
    const pageLoaderBehindSheet = useRef(null)   
    const logo = useRef(null)  

    useEffect(() => {

               let  tl = gsap.timeline({repeat: 0});
               
               document.querySelector('body').style.overflow = 'hidden';      
               tl.add("afterLogo", 1.1)     
               tl.fromTo(logo.current, 1.1,{
                opacity: 0,
                rotateY: '-260',    
                rotate: '-260',         
                ease: 'sine.inOut',   
                scale: 0.8,
               },
               { 
               opacity:1,
               rotateY: '0',    
               rotate: '0',    
               ease: 'sine.inOut',
               scale: 1,   
               }
               )
               tl.to(pageLoader.current, 1,{
                x: '100vw',
                ease: 'sine.in',   
                delay: '0.3',                
               })
               tl.to(pageLoaderBehindSheet.current, 0.9,{
                x: '100vw',
                ease: 'sine.in',   
                delay: '0.5',     
                onComplete: () => {document.querySelector('body').style.overflow = 'auto';}
               }, "afterLogo" )
            
               return () => {
                tl.kill()
               }  
    }, [])



    return (
        <div>
        <div ref={pageLoader} className="fixed  flex items-center justify-center top-0 right-0 bg-custom-black-light z-50 h-full w-full">
                  <img ref={logo} className="opacity-0 h-40 w-40" src={logoPageLoader} alt="" />
        </div>
        <div ref={pageLoaderBehindSheet} className="fixed w-full h-full bg-custom-orange-default z-40">

        </div>
        </div>
        
    )
}
