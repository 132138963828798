import React, {useState} from 'react'
import { Section,ActiveForm, WhoAmI  } from '../components/LancamentoDois/UI'
import Higor2 from '../images/higornovao.png'
import Logo from '../images/logonova1.png'

export default function LancamentoDoisShort({paginaDeObrigado}) {

      // Data do WhoAmIq
      const [dataWhoAmI, setdataWhoAmI] = useState({
        image1: Higor2,
        nome: <span>Higor Fabiano -  O Matriculador</span>,
        titulo: <span>Fundador da primeira Escola específica para quem vende cursos, a Escola de Matrículas</span>,
        text: <div>            
            Higor teve sua primeira experiência profissional aos 17 anos como Street, fazendo captação externa de clientes. <br/><br/>
            Durante todos esses anos Higor se aprofundou no comportamento humano e nas técnicas mais poderosas de Comunicação e Vendas. <br/><br/>
            Higor é líder de um movimento que luta através da excelência, desenvolvendo Consultores e Gestores Educacionais para que se tornem Matriculadores Implacáveis. Mais de 1000 alunos formados fazem parte dessa comunidade que cresce a cada dia. <br/>
            Pioneiro, desbravador e extremamente sedento por resultado, Higor criou o MÉTODO MATRÍCULA, que faz seus alunos matricularem todos os dias.<br/><br/>
            A Escola de Matrículas já transformou vidas, resgatou empresas e desenvolveu autoconfiança em profissionais que se sentiam perdidos e desvalorizados.<br/><br/>
            Não somos apenas uma Escola, somos a primeira Escola de Matrículas do Brasil pois carregamos conosco cada vida que passou e passará por aqui.<br/><br/>
        </div>
    })

  return (
    <div className='grid p-8 gap-8 bg-gray-900 min-h-screen text-gray-100 grid-cols-1 place-items-center justify-items-center'>
        <Section> 
           <div className={`grid grid-cols-1 lg:p-8  lg:grid-cols-2 lg:grid-rows-auto place-items-stretch justify-items-stretch gap-4`}>
              
                <div className={`flex order-1 lg:row-span-3  flex-col items-center lg:justify-end lg:items-start  justify-center gap-4`}>
                    <img src={Logo} alt="" className={`w-64`}/>
                    <div className={`grid grid-cols-1 lg:grid-cols-4 place-items-center justify-items-center gap-4 `}>
                       <div className={`lg:col-span-3 col-span-1`}>
                            <p className={`font-roboto font-bold text-2xl`}>Descubra os erros que os amadores comentem na hora de ligar para o cliente e reduza até 80% dos bolos que você leva</p>                     
                       </div>
                        <div className={`px-4 py-2 w-full lg:w-auto text-center font-bold text-lg relative text-red-500  border border-red-500 rounded-md`}>
                               Ao Vivo
                            <div className={`absolute rounded-full top-1 right-1 w-2 h-2 bg-red-500 animate-ping`}></div>
                            <div className={`absolute rounded-full top-1 right-1 w-2 h-2 bg-red-500`}></div>
                         
                        </div>
                    </div>     
                </div>

                <div className={`p-4 order-2 lg:row-span-5 flex items-center gap-4 justify-center flex-col border-custom-orange-default border-t lg:border-0 border-b py-8`}>
                    <p className={`text-lg text-center font-roboto pb-4`}>Reserve sua Vaga Gratuitamente</p>
                    <ActiveForm paginaDeObrigado={paginaDeObrigado} textDoBotao={'Quero reservar minha vaga'} />
                </div>

                <div className={`order-3`}>
                        <div className={`w-8 h-1 lg:block hidden bg-custom-orange-default`}></div>
                        <p className={`text-custom-orange-default text-2xl`}>Quando será?</p>
                        <div className={`text-lg`}>
                            <span className={`text-5xl text-custom-orange-default`}>09</span>, <span className={`text-5xl text-custom-orange-default`}>10</span>, <span className={`text-5xl text-custom-orange-default`}> 11 </span> e <span className={`text-5xl text-custom-orange-default`}>12</span> de Maio
                        </div>
                        <p className={`font-light font-roboto`}>Sempre às 20:22h (horário de Brasília)</p>
                </div>
    
              
           </div>
        </Section>

        {/* WhoAmI Section */}  
        <Section>
            <WhoAmI data={dataWhoAmI}/>
        </Section>
    </div>
  )
}
