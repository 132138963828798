import React, {useRef, useState, useEffect} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import firebase, {functions} from './Firestore';
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container'
import { IconButton } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Input, InputLabel, Button  } from '@material-ui/core';
import { useAuth } from './context/AuthContext'
import { useSnack } from './context/SnackContext'
import { Link, useHistory } from 'react-router-dom';


export default function SignUp() {
   

    const history = useHistory()
    const { signup, receiveCurrentUser, setUserIdToken } = useAuth()
    const { handleSnack } =  useSnack()
    const grantReferralToUser = functions.httpsCallable('grantReferralToUser')
    


    // Set sweetalert

    const MySwal = withReactContent(Swal)  

    const [values, setValues] = React.useState({    
        password: '',   
        passwordConfirmation: '',
        email: '',
        loading: false,
        showPassword: false,
        showPassword2: false,
      });



      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });        
      };
    
       const handleSubmit = async (e) => {

        e.preventDefault()    
        if ( values.password === values.passwordConfirmation ) {
          setValues({ ...values, loading: true })
          await signup(values.email, values.password)
          .then( response => {
            const queryId = history.location.search.slice(history.location.search.lastIndexOf('=') + 1)
            receiveCurrentUser()
            .getIdToken(true)
            .then((doc) => {
              setUserIdToken(doc)              
              if (queryId !== ''){              
                grantReferralToUser({   
                  justSignedUpUserIdToken: doc,
                  referralUid: queryId           
                })
              }           
            })
            .catch((err) => {console.log(err);})

             
              history.push('/dashboard')
             })
          .catch( err => {

            if (err.code.includes('email-already-in-use')) {
              handleSnack('warning', 'Email já em uso')              
            } else{
              handleSnack('error', 'Houve algum erro. Cheque sua conexão ou tente mais tarde.')              
            }
            console.log(err.code);
          })    
        }
        else{
          handleSnack('warning', 'Senhas diferentes, por favor escreva sua senha denovo.')     
      }

        setValues({ ...values, loading: false })
       
      }

      const handleClickShowPassword = (prop) => { 
          // this if only exists because of a pointless syntax error 
          // the error did not affect the code at all but keept appearing so
          // I decided to add this if to remove it.       
          if (prop==='showPassword')  {
            setValues({ ...values, [prop]: !values.showPassword });
          } if (prop==='showPassword2') {
            setValues({ ...values, [prop]: !values.showPassword2 });
          }
     
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    
 
    // Material Ui Styling
    const useStyles = makeStyles((theme)=>({
        button:{
           color:"white",
           marginTop: "2em",
           marginBottom: "1em" 
        },
        emailInputLabel:{
            marginBottom:"2em"
        },
        passwordInput:{
            marginBottom:"1em"
        },

    }))

    const classes = useStyles();
    // End of Material Ui Styling
     

    const textField = useRef(null)

    return (
        <div className="min-h-screen p-8 w-full flex flex-col items-center justify-center">    
                
        <Container maxWidth="xs">       

            <div className="wrapper bg-white p-8 shadow-2xl border-2">
               

            <h2 className="text-4xl font-sans font-bold text-gray-700 mb-4">Cadastre-se</h2>

            <FormControl fullWidth={true} component="fieldset">
                   <InputLabel>Email</InputLabel>       
                    <Input
                    ref={textField}              
                    fullWidth={true}        
                    value={values.email}                    
                    id=""
                    label="Email"
                    onChange={handleChange('email')}                   
                    />
       
            <FormHelperText className={classes.emailInputLabel}>Use um e-mail atual que você tenha acesso. </FormHelperText>
            </FormControl>
       

          <FormControl fullWidth={true} >
          <InputLabel htmlFor="standard-adornment-password">Senha</InputLabel>
          <Input
            className={classes.passwordInput}
            id="standard-adornment-password"
            fullWidth={true}
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>handleClickShowPassword('showPassword')}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility />  : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth={true} >
          <InputLabel htmlFor="standard-adornment-password">Repita sua senha</InputLabel>
          <Input
            className={classes.passwordInput}           
            fullWidth={true}
            type={values.showPassword2 ? 'text' : 'password'}
            value={values.passwordConfirmation}
            onChange={handleChange('passwordConfirmation')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>handleClickShowPassword('showPassword2')}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword2 ? <Visibility />  : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Button disabled={values.loading} onClick={(e)=>handleSubmit(e)} className={classes.button} fullWidth={true} variant="contained" color="primary">
          Cadastrar
        </Button>

       { history.location.search === '' && <Link to="/login"> <p className="text-center mt-4 cursor-pointer">Já tem uma conta? Logar.</p> </Link>}

            </div> 
            </Container>  
        </div>
    )
}
