import React, {useState,useEffect} from 'react';
import {Button, AppBar, Tabs, Checkbox, FormControlLabel, TextField, ButtonGroup, IconButton, Toolbar, Typography, Drawer} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { db } from './Firestore';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import gsap from 'gsap';
import CloseIcon from '@material-ui/icons/Close';
import Transition from 'react-transition-group/Transition';
import { Menu as MenuIcon } from '@material-ui/icons'
import { List, Divider, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help';
import TicketsDisplay from './components/dbRelatedComponents/TicketsDisplay'
import NotificationsIcon from '@material-ui/icons/Notifications';
import {useAuth} from './context/AuthContext'
import UserDashboard from './components/ticketSystem/UserDashboard'
import AdminDashboard from './components/ticketSystem/AdminDashboard'

export default function TicketsDashboard() { 

const {currentUser} = useAuth()

// Setting up firebase firestore database  

// Declaring some states for further use

// Form related states
const [titleFormData, setTitleFormData] = useState(null)
const [subTitleFormData, setSubTitleFormData] = useState(null)
const [contentFormData, setContentFormData] = useState(null)




// Animations related to the side add item menu 

const closingAddItemMenu = ()=>{
  gsap.to('#external-menu-add-item', 0.3, {    
    x:'100%', 
    ease: 'power2.in'
    })
} 

const openingAddItemMenu = ()=>{
  gsap.to('#external-menu-add-item', 0.3, {
    x:'0%', 
    ease: 'power2.out'
    })
} 








  //Material-ui stylization stuff. Remember that there is a theme in the app.js that is "passing props" here 

   const useStyles = makeStyles((theme)=>({
    typographyAppBar: {
      flexGrow: 1,
    }, 
    fixedIconButton:{
    backgroundColor: theme.palette.primary.main, 
    } ,
    appBar:{
      width: '100%',   
      height: '60px',
      backgroundColor: 'white'
    },
    paper:{      
      height: `calc(100% - 60px) !important`,
      marginTop: '60px',
      width: 200,  
    },    
    drawer: {    
       flexShrink: 0,
    },
    button:{      
      backgroundColor: theme.palette.primary.main,
      margin: '5px',
      borderStyle: 'none', 
      color: 'white',
      '&:hover':{
        backgroundColor: theme.palette.primary.light,  
      },
      '&:focus':{
        borderStyle: 'none',  
        outline: 'none',
      },
         
    }
  }))



      const classes = useStyles()

      const [checkboxTestState, checkboxTestStateSetstate] = useState(true)
      const [checkboxTestState2, checkboxTestStateSetstate2] = useState(false)


  



      const [addItemMenuState, setAddItemMenuState] = useState(false)

    
      return(
  


        <div className=" p-4" style={{marginLeft: '200px', width: 'calc(100% - 200px)', height:'calc(100% - 60px)', marginTop: '60px' }}>



<AppBar position="fixed" color="primary" className={classes.appBar}>
  <Toolbar>
    <IconButton edge="start" color="inherit" aria-label="menu">
      <MenuIcon />
    </IconButton>
      
    
    <Typography classes={{root: classes.typographyAppBar}} variant="h6">
      Hello
    </Typography>
    
    <IconButton edge="start" color="inherit" aria-label="menu">
    <NotificationsIcon/>
    </IconButton>

    
    <Button
                 variant="contained"        
                 onClick={()=> {
                  setAddItemMenuState(true)
                 }}
                 className={classes.button}
                 size="medium"
                 startIcon={ <AddCircleIcon />}
               >
                 Quero Abrir um Ticket
                </Button>  
  </Toolbar>
</AppBar>

<Drawer
  variant="permanent"
  anchor="left"
  classes={{
    root: classes.drawer,
    paper: classes.paper
  }}
  
>

<Divider />
        <List>
          
          {['Resumo', 'Novos', 'Abertos', 'Concluidos'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <HelpIcon /> : <HelpIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <HelpIcon /> : <HelpIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
</Drawer>

 
 <Transition in={addItemMenuState} onEntered={()=>{openingAddItemMenu()}}  onExit={()=>{closingAddItemMenu()}} unmountOnExit={true} mountOnEntering={true} timeout={{exit: 300 }}>
  
          <div id="external-menu-add-item" className="external-menu-add-item-w fixed flex flex-col items-center justify-start right-0 top-0 h-full p-8 w-96 bg-gray-100" style={{transform: 'translateX(100%)', zIndex:99999}}>
 
 <div className="x-to-cancel-w absolute top-5 right-5">
   <IconButton aria-label="" onClick={()=>{
      setAddItemMenuState(false)
     }
   }>
          <CloseIcon/>
   </IconButton>
 </div>

 <TextField            
   variant="outlined"            
   type="text"            
   label="Titulo"            
   onChange={(event)=>{
     setTitleFormData(event.target.value)
     console.log();
   }}
   
 />
  <TextField
   variant="outlined"            
   type="text"            
   label="Subtitle"            
   onChange={(event)=>{
     setSubTitleFormData(event.target.value)
   }}
   
 />
   <TextField            
   variant="outlined"            
   type="text"            
   label="Content"            
   onChange={(event)=>{
     setContentFormData(event.target.value)
   }}   
 />

 <ButtonGroup variant="text" color="default" aria-label="">
   <Button  variant="contained" onClick={ async ()=>{
     if (titleFormData !== null && subTitleFormData !== null && contentFormData !== null  ) {
      
      await db.collection('testcollection').add({
         title: titleFormData,
         subtitle: subTitleFormData,
         content: contentFormData
     })
     .then(()=>{
      setAddItemMenuState(false)
     })

     .catch(err => console.log(err))

     } else {
       alert('Fill the fields!')
     }
  

   }}> Create </Button>
   
 </ButtonGroup>

 </div>


          </Transition>
      
          
          <TicketsDisplay />

          
          {false ? <AdminDashboard /> : <UserDashboard />}
          
          


        </div> 


  
  


)
}

