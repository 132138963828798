import React from 'react'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography'


export default function Section(props) {

    const theme = useTheme();

    // Material Ui Styling
    const useStyles = makeStyles( (theme)=>({
        root:{          
          marginBottom: '80px',
        },
        paper:{         
            minHeight: '500px', 
            width: '100%',
        }
    }))

    const classes = useStyles();
    // End of Material Ui Styling


    return (        
        
        <Container maxWidth="lg" className={classes.root}>
            <Paper className={classes.paper} elevation={props.elevation}>  

            {props.template === 1 &&              
            <>
                    <div className={`border ${props.overflow ? props.overflow : null }  border-custom-orange-default relative flex items-center justify-center sm:justify-between w-full mb-8 p-8 pb-16 pt-12`}>
                 
                    {props.titleElement !== undefined &&
                     props.titleElement
                    }

                    {props.titleElement === undefined &&
                        <div className={`pb-6 md:pb-0`}>
                             <strong className={'font-bold text-3xl md:text-6xl font-lato'}>
                                 {props.title}
                             </strong>
                        </div>
                    }       

                    <div className={`absolute -bottom-10 right-1/2 transform rotate-45 translate-x-1/2 border border-custom-orange-default bg-white p-2 flex items-center justify-center w-20 h-20`}>
                       <div className={`transform -rotate-45`}>{props.icon}</div> 
                    </div>
                    </div>
                        <div className={`p-4`}>
                        
                        {props.children}   
                            
                        </div>                  
            </>
            }

        {props.template === 'hero' &&              
            <>
                    <div style={{minHeight: '500px'}} className={`relative w-full h-full`}>
                    
                    {props.children}   
                           
                    </div>                         
            </>
            }    

        {props.template === 'heroBackgroundOriented' &&              
            <>
                    <div style={{minHeight: '500px'}} className={`relative w-full h-full`}>
                    
                    {props.children}   
                           
                    </div>                         
            </>
            }    
            
                            
            </Paper>
        </Container>
      
    )
}
