import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimeLineCustomDot from '../TimeLineCustomDot';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';	
import { Button } from '@material-ui/core'; 
import TituloPadrao from './TituloPadrao';

export default function TimeLineSection() {

    const theme = useTheme();
  //  const xs = useMediaQuery(theme.breakpoints.up('xs'))
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
  //  const md = useMediaQuery(theme.breakpoints.up('md'))
  //  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  //  const xl = useMediaQuery(theme.breakpoints.up('xl'))  

    
  let conditionalTimelineAlignMaterialProps = null

  if (sm) {
    conditionalTimelineAlignMaterialProps = 'alternate'   
  } else {
    conditionalTimelineAlignMaterialProps = 'left'
  }
  
    // Material Ui Styling
    const useStyles = makeStyles((theme)=>({

        accordionRoot: {
          width: '100%',
        },
        heading: {
          fontSize: theme.typography.pxToRem(15),
          fontWeight: theme.typography.fontWeightRegular,
        },
            timelineRoot:{
              padding: 0,
            },
            paper: {
              padding: '12px 20px',
            },      
            secondaryTail: {
              backgroundColor: theme.palette.secondary.main,
            },
            media: {
              height: 140,
            },
            missingOppositeContent:{
              '&:before':{
                [theme.breakpoints.up('xs')]:{display:'none'}, 
                [theme.breakpoints.up('sm')]:{display:'block'},
                            
                
              }
            },
     
  
      }))
  
      const classes = useStyles();
      // End of Material Ui Styling

    return (
        <div className={`pb-16 pt-16 md:pt-0`}>
 <TituloPadrao textColor="gray-100">
Agora Veja os Módulos do Treinamento
</TituloPadrao>

<Timeline classes={{
  root: classes.timelineRoot

}} align={conditionalTimelineAlignMaterialProps}> 
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
       <TimeLineCustomDot text="1"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
             Os Argumentos Na Matrícula
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem  
      classes={{
  missingOppositeContent : classes.missingOppositeContent
}}
>   
        <TimelineSeparator>
            <TimeLineCustomDot text="2"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
             Objeção X Argumento
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
        <TimeLineCustomDot text="3"/>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Mas é muito tempo de curso"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
        <TimeLineCustomDot text="4"/>
        <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Nem tenho tanto interesse assim"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>



      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
       <TimeLineCustomDot text="5"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Eu não tenho tempo pra fazer curso"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>   
        <TimelineSeparator>
            <TimeLineCustomDot text="6"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Já passei da idade"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
        <TimeLineCustomDot text="7"/>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Estou desempregado(a)"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
        <TimeLineCustomDot text="8"/>
        <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Já fiz uma vez e não adiantou nada"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
       <TimeLineCustomDot text="9"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Achei muito caro"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>   
        <TimelineSeparator>
            <TimeLineCustomDot text="10"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Eu não gosto de [curso que está oferecendo]
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
        <TimeLineCustomDot text="11"/>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Não tenho dinheiro"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
        <TimeLineCustomDot text="12"/>
        <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Vou pensar direitinho"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
       <TimeLineCustomDot text="13"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Tenho outras prioridades"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>   
        <TimelineSeparator>
            <TimeLineCustomDot text="14"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Não tenho cheque nem cartão"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
        <TimeLineCustomDot text="15"/>
          <TimelineConnector className={classes.secondaryTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Tenho muitas contas"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem  classes={{
  missingOppositeContent : classes.missingOppositeContent
}}>
        <TimelineSeparator>
        <TimeLineCustomDot text="16"/>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
            "Preciso falar com [cônjuge ou investidor]"
            </Typography>
            <Typography> lorem ipsum lorem ipsum</Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>

    <div className={`bg-green-300 text-lg text-green-800  rounded-lg p-4 mt-16`}>
        <p>
Todas as aulas são em vídeos e você pode acessar do Celular, tablet ou do PC e com acesso por 1 ano.
Durante este período  é só pegar o argumento pronto, dar ctrl C + ctrl V e já quebrar as objeções e fazer o dobro de matrículas!
</p>
 </div>


<div className={`mt-8`}>
     <Button fullWidth={true} size="large" variant="contained" color="primary">
         <div className={`text-gray-200`}>Já tomei a minha decisão e quero o dobro de matrículas</div>   
     </Button>
</div>

    </div>
    )
}
