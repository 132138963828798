import React from 'react'
import { makeStyles } from "@material-ui/styles"
import { createMuiTheme, ThemeProvider  } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

import NativeSelects from './components/fundamentalComponents/Dropdown1'
import TicketForm from './components/TicketForm'
import gsap from 'gsap'
import NoticeToUserComponent from './components/NoticeToUserComponent'
import { Transition } from 'react-transition-group';

export default class Support extends React.Component{

    constructor(props) {
        super(props)
        this.state={            
            queroAbrirTicket: false,
            selectedTurma: "",
            selectedSubject: "",
            topAlertState:true,
            callBackDropDown1: (data)=>{
                this.setState({selectedTurma: data})
            },
            callBackDropDown2: (data)=>{
                this.setState({selectedSubject: data})
            },
        }

    }

     

    componentDidMount() {
        gsap.fromTo('#suporte-title', 1.2,{
            y:100
        },
        {
        y:0,    
        ease: 'power1.out'
        }
        )
    }
    
 

    render(){


    const themeButton = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      MuiButton: {
        // Name of the rule
        text: {
          // Some CSS
          background: 'linear-gradient(135deg, #e66105 30%, #e66105 300%)',
          borderRadius: 5,
          border: 0,
          color: 'white',
          height: 48,
          padding: '0 30px',
          boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        },
      },
    },
  });
        return(
            <div className="support-w flex justify-center">
                <div className="max-width-w w-full">

                <div className="hero-section-w h-screen flex items-center justify-center flex-col  p-4 md:p-0" style={{backgroundColor:"#232528", minHeight:"600px"}}>
                <div className="div fixed top-5 left-50 md:w-5/6 w-full z-50" style={{maxWidth:'1200px'}}>
                <Transition in={this.state.topAlertState} onExiting={()=>{gsap.to('#notice-div1', 0.5, {y:-20, opacity:0})}} timeout={{exit:500}} unmountOnExit={true}>
                <div id="notice-div1" className="notice-div1">
                <NoticeToUserComponent
                ButtonSideExist="true"
                PreworkedColors="yellowNotice"
                Text1="Esta sessão de tickets é experimental e deve passar por atualizações antes de ser disponibilizada para os usuários finais"
                ButtonOnClick= {()=>{this.setState({topAlertState:false})}}
                />
                </div>
                </Transition>    
                </div>
         
               
                    
                    <div className="div overflow-hidden border-2 border-yellow-600 p-4 pl-8 pr-8">
                    <h1 id="suporte-title" className="pb-2 text-gray-200 text-center text-4xl md:text-6xl font-bold font-sans">Suporte ao Usuário</h1>
                    </div>
                   
                    <p className="text-gray-400 mt-4">Somente para alunos participantes das turmas A, B e C.</p>
                 
                 {this.state.queroAbrirTicket 
                  ?
                  <div className="p-8 md:p-0 md:pt-8 w-full md:w-96">
                 <TicketForm
                 selectedTurma= {this.state.selectedTurma}
                 selectedSubject ={this.state.selectedSubject}
                 />
                 </div>
                   :
                   <div>
                  <div className="div mt-4">
                  <NativeSelects
                   callBackDropDown = {this.state.callBackDropDown1}
                   label = "Minha Turma"
                   textoAuxiliar = "Por favor, escolha com atenção!" 
                   option1 = 'Turma 1'
                   option2 = 'Turma 2'
                   option3 = 'Turma 3'
                   
                   />
                  </div>
               
                   {  this.state.selectedTurma !== '' &&                     
                    <div className="div mb-4">
                    <NativeSelects 
                    callBackDropDown = {this.state.callBackDropDown2} 
                    label = "Meu Assunto"
                    textoAuxiliar = "O Tempo de Resposta Varia Dependendo do Assunto!" 
                    option1 = 'Duvida sobre pagamento. '
                    option2 = 'Problemas de acesso'
                    option3 = 'Problemas abc'
                    />
                    </div>                    
                    }

                   {  (this.state.selectedSubject !== '' & this.state.selectedTurma !== '')?                    
                   
                   <ThemeProvider theme={themeButton}> 
                   <div className="div mt-6 flex items-center justify-center flex-col ">
                   <Button onClick={()=>{this.setState({queroAbrirTicket:true})}}>Quero Abrir um Ticket</Button>
                   {this.state.selectedSubject.toLowerCase().includes('acesso') && <button className="ml-0  mt-4 p-4 rounded-lg text-white bg-green-600">WhatsApp</button>}
                   </div>    
                   </ThemeProvider>    
               
                    :   null 
                    }
                    </div>
                   }
                 

                     
                </div>
         
                </div>
            </div>
        )
    }
    
}