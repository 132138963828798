import React from 'react'

export default function DescricaoUm() {

    const defaultParagraphTailwindStyle = "text-2xl font-sans text-gray-300 pb-8"
    return (
        <div className={`pb-16 pt-16 md:pt-0`}>

<div>
                 <h1 className={`text-center text-gray-200 md:text-4xl text-3xl font-sans font-bold md:pt-16 pt-8 `}>O QUE É O TREINAMENTO</h1>
                 <h1 className={`text-center text-custom-orange-default md:text-4xl text-3xl font-sans font-bold md:pb-12 pb-8`}>ARGUMENTOS MATRICULADORES?</h1>
 <div className="p-4 md:pl-8 md:pr-8 border-2 border-custom-orange-default">             
 <p className={defaultParagraphTailwindStyle}>Depois de 16 anos juntando <strong>todas as desculpas</strong> que os clientes falavam para mim e para meu time eu consegui entender exatamente como argumentar <strong>com cada uma delas</strong>. Estas desculpas são as famosas <strong>objeções</strong>
</p>
<p className={defaultParagraphTailwindStyle}>Este é o <strong>ÚNICO treinamento do Brasil</strong>, específico para você que é Matriculador e já cansou de ouvir objeções e perder matrículas. 
</p>
<p className={defaultParagraphTailwindStyle}>Neste treinamento você terá as <strong>respostas prontas</strong> das 14 objeções universais do mundo das matrículas.
</p>
<p className={defaultParagraphTailwindStyle}>Além disso, ainda vou te passar como <strong>construir um argumento do zero</strong>. 
Você vai entender como seu cliente pensa, como ele se comporta e o porque ele te dá uma objeção
</p>
<p className={defaultParagraphTailwindStyle}>Você terá todo o <strong>passo a passo</strong> para criar um argumento e eu vou te mostrar tudo que eu faço para converter <strong>40% a mais de matrículas</strong>.
</p>
</div> 


   
   
      </div>
            
        </div>
    )
}
