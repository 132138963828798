import React from 'react'
import TituloPadrao from './TituloPadrao'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';


export default function SeVoce() {

    const columnsStyle = "rounded-lg flex flex-col items-center text-center justify-start flex-1 bg-red-200 md:w-1/3 p-4 m-2 w-full text-lg text-red-800" 

    return (
        <div className="flex  flex-col items-center justify-center p-4 pb-32">
            <TituloPadrao textColor="gray-900">
            Se você:
            </TituloPadrao>
            <h1 className="text-2xl font-sans mb-2"> ➔ Está cansado de escutar as mesmas <strong>“desculpinhas”</strong> todos os dias: </h1>
                    <div className="flex md:flex-row flex-col items-stretch justify-stretch mb-4">
                                <div className={columnsStyle}>
                                    <FormatQuoteIcon />
                                    <p>"Vou pensar melhor e fazer minhas contas"</p>
                                </div>
                                <div className={columnsStyle}>
                                <FormatQuoteIcon />
                                <p>"Preciso conversar com minha esposa/marido"</p>
                                </div>
                                <div className={columnsStyle}>
                                <FormatQuoteIcon />
                                <p>"Achei muito caro"</p>
                                </div>
                    </div>
                    <h1 className="text-2xl font-sans mb-4"> ➔ Sente que <strong>não consegue</strong> quebrar as objeções e <strong>bater suas metas</strong>.  </h1>
                    <h1 className="text-2xl font-sans mb-4"> ➔ Trabalha, trabalha, trabalha e no final do dia <strong>não faz matrícula</strong>. </h1>
                    <h1 className="text-2xl font-sans mb-4"> ➔ <strong>Trava durante o atendimento</strong> quando o cliente diz que vai pensar. </h1>
            <h1>


</h1>
            
        </div>
    )
}
