import React from 'react'
import SinglePost from '../components/BlogComponents/SinglePost/SinglePost.jsx'
import BlogFooter from '../components/BlogComponents/BlogFooter/BlogFooter.jsx'

import PostPreviewDisplayer from '../components/BlogComponents/PostPreviewDisplayer/PostPreviewDisplayer.jsx'

export default function Blog() {
    return (
        <div>
            <BlogFooter />
            <SinglePost /> 
            <PostPreviewDisplayer />
            
        </div>
    )
}
