import React from 'react'
import TituloPadrao from './TituloPadrao'
import thumb1 from '../../images/0.jpg'
import thumb2 from '../../images/0 (1).jpg'
import thumb3 from '../../images/0 (2).jpg'
import VideoComponent from './VideoComponent';

export default function Testemunhos(props) {

    let defaultTextColorTitle = `gray-200`  
    let defaultTextColorSubTitle = `text-gray-300`

    if (props.colorTextTitle !== undefined) {
        defaultTextColorTitle = props.colorTextTitle      
    } 

    if (props.colorTextSubTitle !== undefined) {
        defaultTextColorSubTitle = props.colorTextSubTitle      
    } 

    return (
        <div className="pt-8 pb-8">
                 <TituloPadrao textColor={`${defaultTextColorTitle}`}>
                     Você não está sozinho
                 </TituloPadrao>

                 <h2 className={`text-2xl font-sans ${defaultTextColorSubTitle} mb-8 text-center`}>Veja o que os nossos alunos falam sobre o treinamento:</h2>

                 <div className="flex md:flex-row flex-col items-center justify-center">
                        <div><VideoComponent  thumbnail={thumb1} callBackFunction={props.triggerLightBox} stateIdentifier='t1' /></div>
                        <div><VideoComponent  thumbnail={thumb2} callBackFunction={props.triggerLightBox} stateIdentifier='t2' /></div>
                        <div><VideoComponent  thumbnail={thumb3} callBackFunction={props.triggerLightBox} stateIdentifier='t3' /></div>                     
                 </div>
            
        </div>
    )
}
