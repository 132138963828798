import React from 'react'

export default function Title(props) {
    return (
        <div className={`${props.padding ? props.padding : 'p-8'} font-lato font-bold text-3xl md:text-6xl relative ${props.maxWidth ? props.maxWidth : 'max-w-md' }   ${props.addon && 'border-l border-custom-orange-default'}`}>  
        {props.addon && <div className={`border-l border-t border-custom-orange-default w-20 h-20 absolute border-opacity-60 -left-4 top-6 md:-top-10 md:-left-10 z-10`}></div>} 
   
            {props.children}
        </div>
    )
}
