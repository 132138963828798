import React from 'react'
import Carousel, { Dots, slidesToShowPlugin, autoplayPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import testimonial1 from '../../images/testimonialmex1.jpg'
import testimonial2 from '../../images/testimonialmex2.jpg'
import testimonial3 from '../../images/testimonialmex3.jpg'
import testimonial4 from '../../images/testimonialmex4.jpg'
import testimonial5 from '../../images/testimonialmex5.jpg'
import testimonial6 from '../../images/testimonialmex6.jpg'
import testimonial7 from '../../images/testimonialmex7.jpg'
import testimonial8 from '../../images/testimonialmex8.jpg'
import testimonial9 from '../../images/testimonialmex9.jpg'
import testimonial10 from '../../images/testimonialmex10.jpg'
import testimonial11 from '../../images/testimonialmex11.jpg'
import testimonial12 from '../../images/testimonialmex12.jpg'
import testimonial13 from '../../images/testimonialmex13.jpg'
import testimonial14 from '../../images/testimonialmex14.jpg'
import testimonial15 from '../../images/testimonialmex15.jpg'
import testimonial16 from '../../images/testimonialmex16.jpg'
import testimonial17 from '../../images/testimonialmex17.jpg'
import testimonial18 from '../../images/testimonialmex18.jpg'
import testimonial19 from '../../images/testimonialmex19.jpg'
import testimonial20 from '../../images/testimonialmex20.jpg'
import testimonial21 from '../../images/testimonialmex21.jpg'

import testimoniala from '../../images/mextestimonials/1 ação e 5 matrículas.jpeg'
import testimonialb from '../../images/mextestimonials/1 matrícula em 2 meses.jpeg'
import testimonialc from '../../images/mextestimonials/5 matrículas em 1 dia.jpeg'
import testimoniald from '../../images/mextestimonials/6 matrículas em 1 dia.jpeg'
import testimoniale from '../../images/mextestimonials/16 matrículas no 1º mês.jpeg'
import testimonialf from '../../images/mextestimonials/20 matrículas em fevereiro.jpeg'
import testimonialg from '../../images/mextestimonials/Fazendo Matrícula dormindo.jpeg'
import testimonialh from '../../images/mextestimonials/Melhor resultado dos últimos 2 anos.jpeg'
import testimoniali from '../../images/mextestimonials/Meta de 140 Matrículas antes da data.png'
import testimonialj from '../../images/mextestimonials/Não se conectava com as pessoas.jpeg'
import testimoniall from '../../images/mextestimonials/O Destravar da primeira matrícula.jpeg'
import testimonialm from '../../images/mextestimonials/Superando a meta de matrícula.jpeg'

import { useMUIBreakpointsContext } from '../../context/MUIBreakpointsContext'


function Slide({img,title}){
    return(
        <div className={`flex flex-col items-center justify-center gap-4 p-2`}>
          <span className={`text-base border-l-2 pl-4 border-custom-orange-default font-light font-sans text-center`}>{title && title.toUpperCase()}</span>
          <img src={img} />
        </div>

    )
}

export default function Testimonials({type}) {
    const {smDown } = useMUIBreakpointsContext()
    let numberOfSlides = 3.6 

    if (smDown) {
        numberOfSlides = 1.6
    } 

    return (
        <div className={`w-screen h-auto`}>        

          {type === "mex2" &&
              <Carousel
              plugins={[
                  'centered',
                  'infinite',                          
                  {
                      resolve: autoplayPlugin,
                      options: {
                        interval: 2000,
                      }
                    },
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                     numberOfSlides: numberOfSlides
                    }
                  },
                ]}         
              >
                  <Slide img={testimonial1} />             
                  <Slide img={testimonial2} />
         
                  <Slide img={testimonial8} />             
                  <Slide img={testimonial9} />
                  <Slide img={testimonial13} />  
                  <Slide img={testimonial20} /> 
                  <Slide img={testimonial21} />             
                  
  
                        
                  <Slide img={testimonial5} />
             
                  <Slide img={testimonial7} />                   
                  <Slide img={testimonial10} />
                  <Slide img={testimonial11} />
                  <Slide img={testimonial12} />                
                  <Slide img={testimonial14} />
                  <Slide img={testimonial15} />
                  <Slide img={testimonial16} />
                  <Slide img={testimonial17} />
                  <Slide img={testimonial18} />
                  <Slide img={testimonial19} />           
              </Carousel>
            }

{type === "mex" &&
              <Carousel
              plugins={[
                  'centered',
                  'infinite',                          
                  {
                      resolve: autoplayPlugin,
                      options: {
                        interval: 2000,
                      }
                    },
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                     numberOfSlides: numberOfSlides
                    }
                  },
                ]}         
              >
                  <Slide img={testimoniala}  title={`1 ação e 5 matrículas`} />             
                  <Slide img={testimonialb}  title={`1 matrícula em 2 meses`}  />
                  <Slide img={testimonialc}  title={`5 matrículas em 1 dia`} />             
                  <Slide img={testimoniald}  title={`6 matrículas em 1 dia`} />
                  <Slide img={testimoniale}  title={`16 matrículas no 1º mês`} />  
                  <Slide img={testimonialf}  title={`20 matrículas em fevereiro`} /> 
                  <Slide img={testimonialg}  title={`Fazendo Matrícula dormindo`} />  
                  <Slide img={testimonialh}  title={`Melhor resultado dos últimos 2 anos`} />
                  <Slide img={testimoniali}  title={`Meta de 140 Matrículas antes da data`} />                   
                  <Slide img={testimonialj}  title={`Não se conectava com as pessoas`} />
                  <Slide img={testimoniall}  title={`O Destravar da primeira matrícula`} />
                  <Slide img={testimonialm}  title={`Superando a meta de matrícula`} />                
                 
              </Carousel>
            }
        </div>
    )
}
