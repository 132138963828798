import React from 'react';
import {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container'
import FsLightbox from 'fslightbox-react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import ScreenProgressBar from './components/Perpetua/ScreenProgressBar';
import PageLoader from './components/Perpetua/PageLoader'
import HeroSection from './components/Perpetua/HeroSection';
import BgTexture from './images/asfalt-dark.png' 
import SeVoce from './components/Perpetua/SeVoce'
import Testemunhos from './components/Perpetua/Testemunhos'
import DescricaoDois from './components/Perpetua/DescricaoDois';
import DescricaoUm from './components/Perpetua/DescricaoUm';
import ParaQuemSeDestina from './components/Perpetua/ParaQuemSeDestina'
import TestimonialsDois from './components/Perpetua/TestimonialsDois';
import WaterMark from './components/Perpetua/WaterMark';
import ComQuemVaiAprender from './components/Perpetua/ComQuemVaiAprender';
import TimeLineSection from './components/Perpetua/TimeLineSection';
import OQueVoceRecebe from './components/Perpetua/OQueVoceRecebe';
import FAQ from './components/Perpetua/FAQ';
import Loading from './components/Perpetua/Loading';
import AdicionalOPoderDosArgumentos from './components/Perpetua/AdicionalOPoderDosArgumentos'



export default function Perpetua() {

  // Stablishing Media for easier Material UI use

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up('xs'))
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme.breakpoints.up('xl'))  

  // Conditinal to Media Props

  let conditionalTimelineAlignMaterialProps = null

  if (sm) {
    conditionalTimelineAlignMaterialProps = 'alternate'   
  } else {
    conditionalTimelineAlignMaterialProps = 'left'
  }

    //FSlightBox Togglers

    const [lightBoxToggler, setLightBoxToggler] = useState({
        t1:false,
        t2:false,
        t3:false,
    });

    // Fslightbox state change callbackfunction

    const triggerLightBox = (t) => {

        setLightBoxToggler({
            ...lightBoxToggler,
            [t]:!lightBoxToggler[t]
        })      
    }

 
    // Material Ui Styling
    const useStyles = makeStyles((theme)=>({

      accordionRoot: {
        width: '100%',
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
          timelineRoot:{
            padding: 0,
          },
          paper: {
            padding: '12px 20px',
          },      
          secondaryTail: {
            backgroundColor: theme.palette.secondary.main,
          },
          media: {
            height: 140,
          },
          missingOppositeContent:{
            '&:before':{
              [theme.breakpoints.up('xs')]:{display:'none'}, 
              [theme.breakpoints.up('sm')]:{display:'block'},
                          
              
            }
          },
   

    }))

    const classes = useStyles();
    // End of Material Ui Styling


    return (

        <div>

{/** Fixed components */}         
<WaterMark/>
<PageLoader/>
<ScreenProgressBar />
            

<FsLightbox
toggler={lightBoxToggler.t1}
sources={[
'https://www.youtube.com/watch?v=Mj0CfUiW8xs&feature=youtu.be',
]}
/>

<FsLightbox
toggler={lightBoxToggler.t2}
sources={[
'https://www.youtube.com/watch?v=P4pE68_Uh_w&t=4s',
]}
/>

<FsLightbox
toggler={lightBoxToggler.t3}
sources={[
'https://www.youtube.com/watch?v=KH89dXLChRU',
]}
/>

<FsLightbox
toggler={lightBoxToggler.t4}
sources={[
'https://www.youtube.com/watch?v=KH89dXLChRU',
]}
/>

{/** Start of the actual page */}
            <div className={`bg-custom-black-default w-full`} style={{backgroundImage:`url(${BgTexture})`, backgroundRepeat:"repeat"}}>
            <Container maxWidth="md">
              <HeroSection />
            </Container>
            </div>

            <div className="bg-gray-200">
              <Container maxWidth="md">
                <SeVoce />
              </Container>              
            </div>

            <div className={`bg-custom-black-default`}>
              <Container maxWidth="md">
                <Testemunhos triggerLightBox={triggerLightBox} setLightBoxToggler={setLightBoxToggler} />
              </Container>              
            </div>

            <div className={`bg-custom-black-default`}>
              <Container maxWidth="md">
              <DescricaoUm/>
              </Container>              
            </div>

            <div className={`bg-gray-200`}>
              <Container maxWidth="md">
              <DescricaoDois/>
              </Container>              
            </div>

            <div className={`bg-custom-black-default`}>
              <Container maxWidth="md">
              <ParaQuemSeDestina/>
              </Container>              
            </div>

            <div className={`bg-custom-black-default`}>
              <Container maxWidth="md">
              <TestimonialsDois/>
              </Container>              
            </div>

            <div className={`bg-gray-200`}>
              <Container maxWidth="md">
                  <ComQuemVaiAprender/>
              </Container>              
            </div>

            <div className="wrapper min-h-screen bg-custom-black-default">
            <Container maxWidth="md">
                  <TimeLineSection/>
            </Container>
            </div>    

            <div className="wrapper min-h-screen bg-gray-200">
            <Container maxWidth="md">
            <OQueVoceRecebe/>
            </Container>
            </div>    

            <div className="wrapper min-h-screen bg-gray-200">
            <Container maxWidth="md">
            <AdicionalOPoderDosArgumentos/>
            </Container>
            </div>    

            <div className="wrapper min-h-screen bg-gray-200">
            <Container maxWidth="md">
            <FAQ/>
            </Container>
            </div>    



</div>
    )
}
