import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Sou Gestor/Dono de um curso ou  escola. Esse Programa MI é para mim?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Ele foi feito para você. É muito importante você e seus consultores/colaboradores estarem alinhados e falando a mesma língua. Imagina só se seu consultor participa do programa, dispara de matricular e você não ter noção de como ele está conseguindo esse resultado. Em 90% dos casos isso vai gerar no consultor um sentimento de superioridade e você perderá a sua liderança. Acredite, eu já vivi isso mais de uma vez. Contudo, é importante ressaltar que não são todos consultores que fazem isso. Então, se você for consultor e está lendo isso, não deixe seus resultados subirem a sua cabeça. Porque uma coisa eu garanto, você vai matricular muito mais e a vontade de "jogar na cara" do gerente que você conseguiu isso sozinho vai vir na ponta da língua.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>No Programa MI ensina captação de Leads?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Sim, temos um módulo inteiro e específico falando sobre como viver de indicações.

          </Typography>
        </AccordionDetails>
      </Accordion>    
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Você ensina a fazer matrícula online?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Sim-  Você pode utilizar as estratégias no whatsapp, redes sociais, aplicativos de videoconferência e todos os recursos que precisar para fazer uma matrícula a distância. 

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>O Programa MI é em qual formato?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Programa Matrícula Implacável - Aulas gravadas em vídeo com tempo médio de 10 minutos. também temos aulas ao vivo - Por meio do Aplicativo Zoom Grupo do Telegram. Todas as perguntas são respondidas em áudio para uma melhor compreensão e também fazemos encontros bônus.  OBS: Todos os alunos terão acesso às gravações das aulas que acontecem ao vivo.

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Sou do ensino Regular. Funciona para mim?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          SIM. Você pode fazer como a nossa aluna Conceição: Ela entrou no Programa de Mentoria, pegou as técnicas, aplicou em sua Escola e montou 3 turmas de fundamental em um mês Lembrando que falamos diretamente para Cursos Livres. Contudo, o conteúdo é SUPER aplicável no Ensino Regular.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>É só para quem trabalha com sistema Presencial? Ou funciona também para quem é EAD e Híbrido? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Claro, pois não se trata da maneira que a sua Instituição de Ensino entrega o conteúdo e sim na estratégia que você utiliza em seu time comercial. Temos muitos casos de sucesso de Alunos EAD, Hibrído e Presenciais.     </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}