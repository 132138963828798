import React, {useState,useEffect} from 'react';
import  DeleteIcon from '@material-ui/icons/Delete';
import {Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import firebase from '../../Firestore';

export default function TicketsDisplay() {

// Setting up firebase firestore database  
const db = firebase.firestore();

// Declaring some states for further use

// Main data gathering state for firestore 
const [firestoreApiData, setFirestoreApiData] = useState([])

//add observer for realtime sync with the database       
//also fetch innitial data from the database  


useEffect(() => {

    const observer = db.collection('testcollection').onSnapshot((docSnapshot) => { 
    
      const temporaryArray = [];
    
      for (let index = 0; index < docSnapshot.docs.length; index++) {    
        const ObjectCoreData =  docSnapshot.docs[index].data()
        const ObjectOnlyIdData = {id: docSnapshot.docs[index].id}
        const ObjectCoreDataPlusIdData = Object.assign(ObjectCoreData , ObjectOnlyIdData)         
        temporaryArray.push(ObjectCoreDataPlusIdData)
      }
      console.log(temporaryArray);
      setFirestoreApiData(temporaryArray)
      
    }, err => {
      console.log(`Encountered error on trying to receive the snapshot from observer: ${err}`);
    });
    return observer
      }, [])
    

// Delete an item

const handleDeletion = async (id) => {
  
    const newApiData = firestoreApiData.map( async item => {
     if (item.id === id) {
      await db.collection('testcollection').doc(id).delete()
     .then((response)=> console.log(`Item with id ${id} deleted`) )
     .catch(err => alert(err))
     }    
    })
  }  

    // Add favorite to an item function
const handleFavorite = (id) => {

    const newApiData = firestoreApiData.map( (item) => {
  
      if (item.id === id) {
        let temporaryObject = {}  
        temporaryObject = {...item, favorite: !item.favorite}
        db.collection('testcollection').doc(id).set(temporaryObject)
      }
          
    })
  
  
  }  

 //Material-ui stylization stuff. Remember that there is a theme in the app.js that is "passing props" here 

 const useStyles = makeStyles((theme)=>({  
    button:{      
      backgroundColor: theme.palette.primary.main,
      margin: '5px',
      borderStyle: 'none', 
      color: 'white',
      '&:hover':{
        backgroundColor: theme.palette.primary.light,  
      },
      '&:focus':{
        borderStyle: 'none',  
        outline: 'none',
      },
         
    }
  }))



      const classes = useStyles()

    return (
        <div>
                   <div className="grid md:grid-cols-3 grid-cols-1 gap-3 bg-gray-100">

{
    firestoreApiData.map( (item, index)=> (
     <div className="wrapper  border-2  w-full flex flex-col items-stretch justify-between relative">     
                             {
                             item.favorite !== false & item.favorite !== undefined ? <p className="absolute top-0 right-0 p-4 bg-green-200 text-green-800"> Marcado </p> : null
                             }           
                  <div onClick={()=>{alert('open full ticket')}} id="content-outputs-beers" className="p-6 bg-green-800 flex-1 cursor-pointer">
                              
                            <div className="">                               
                            <h1 key={item.id} className="text-green-200 text-sm mt-8">ID: {item.id}</h1>                                         
                            <h1 key={item.id + 'k1'} className="text-green-200 mt-8">Title: {item.title}</h1>
                            <h2 key={item.id + 'k2'} className="text-green-300">Subject: {item.subtitle}</h2>
                            <p key={item.id + 'k3'} className="text-gray-200 mt-4">{item.content}</p>
                           </div>
                   </div>                                     
                   <div className="command-inputs bg-green-800 p-6">
                            <Button onClick={()=>{handleDeletion(item.id)}} startIcon={<DeleteIcon />} className={classes.button} variant="contained">Delete item</Button>                                      
                            <Button onClick={()=>{handleFavorite(item.id)}} className={classes.button} variant="contained">Marcar</Button>  
                   </div>
         
    </div>  
  ))
}

</div>
        </div>
    )
}
