import React from 'react'
import TituloPadrao from './TituloPadrao'
import Higor1 from '../../images/higorretangulo.png';

export default function ComQuemVaiAprender() {
    return (
        <div className={`pt-16 pb-16 md:pt-0`}>
            <TituloPadrao textColor="gray-900"> Com quem você vai aprender?</TituloPadrao>

                <div className="flex-w flex items-center justify-center p-4 md:p-8 md:flex-row flex-col ">

                <div className="left-flex md:w-1/3 ">

                    <div className="wrapper relative p-2 md:-mt-12">
                        <img src={Higor1} alt="" />
                    </div>

                </div>
                <div className="right-flex  p-2  md:w-2/3">
                <p className="font-sans text-2xl">Fala matriculador, aqui&nbsp;é&nbsp;o</p><p className=" text-3xl text-custom-orange-default md:text-5xl font-bold mt-4 mb-4">  Higor de Almeida Fabiano </p> 
                <p className="font-sans text-xl">
                Fundador da primeira Escola específica para quem vende cursos, a <strong>Escola de Matrículas</strong>.<br/><br/>
Higor teve sua primeira experiência profissional <strong>aos 17 anos</strong> como Street, fazendo captação externa de clientes.<br/><br/>
Durante todos esses anos Higor se aprofundou no comportamento humano e nas técnicas <strong>mais poderosas de Comunicação e Vendas</strong>.<br/><br/>
Higor <strong>é líder</strong> de um movimento que luta através da <strong>excelência, desenvolvendo Consultores e Gestores Educacionais</strong> para que se tornem Matriculadores Implacáveis. Mais de 1000 alunos formados fazem parte dessa comunidade que cresce a cada dia.<br/><br/>
Pioneiro, desbravador e extremamente sedento por resultado, Higor criou o <strong>MÉTODO MATRÍCULA</strong>, que faz seus alunos matricularem <strong>todos os dias</strong>.<br/><br/>
A Escola de Matrículas já <strong>transformou</strong> vidas, <strong>resgatou</strong> empresas e desenvolveu <strong>autoconfiança</strong> em profissionais que se sentiam perdidos e desvalorizados.<br/><br/>
Não somos apenas uma Escola, somos <strong>a primeira Escola de Matrículas do Brasil</strong> pois carregamos conosco cada vida que passou e passará por aqui.<br/><br/>
                </p>
                </div>
                </div>          
        </div>
    )
}
