import React, { useState, useEffect, useRef } from 'react'
import { LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

export default function ScreenProgressBar() {

    const [state, setState] = useState({
        progress: 0
    })

    let scrollHeight = window.pageHeight
    let scrolled = window.pageYOffset
    let percentageOfScrolling = scrolled / scrollHeight * 100
    let finalPercentage = 0

    const handleScrollUpdate = () => {
        scrollHeight = document.querySelector('body').scrollHeight
        scrolled = window.pageYOffset
        percentageOfScrolling = scrolled / scrollHeight * 100
        if (percentageOfScrolling >= 93) {
            finalPercentage = 100            
        }
        else{
            finalPercentage = percentageOfScrolling  
        }

        setState({progress: finalPercentage})
    }

    useEffect(() => {    

       window.addEventListener('scroll', handleScrollUpdate);

       return () => {
        window.removeEventListened('scroll', handleScrollUpdate);
      }
      
    }, [])    


      

   

        // Material Ui Styling
        const useStyles = makeStyles( (theme) => ({
            LinearProgress:{
               position: 'fixed',
               top: 'calc(50%)',             
               height: '6px',      
               right:'-35px',         
               width: '100px',
               zIndex: '5',     
               transform: 'rotate(90deg)', 
               [theme.breakpoints.down('xs')]:{   
                transform: 'rotate(0deg)',       
                top: 'auto',
                bottom: '0',                              
                right:'0px',   
                width: '100%', 
                },        
            },    
        }))
    
        const classes = useStyles();
        // End of Material Ui Styling

    
    

    return (
      <div>
            <LinearProgress color="primary" value={state.progress} className={classes.LinearProgress} variant='determinate'/>
      </div>
            )
            
}
