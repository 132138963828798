import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

export default function VideoComponent(props) {

    let defaultDimensions = ''
 
    if (props.width !== undefined || props.height !== undefined) {
    defaultDimensions = ` ${props.width} ${props.height} `
    } else{
    defaultDimensions = "sm:h-72 sm:w-96 h-54 w-72"
    }

    if (props.isCustomThumb) {
        console.log();
    } else{
        console.log();
    }
    


  

    return (
        <div style={{backgroundImage: `url('${props.thumbnail}')`, backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundPosition: 'center'}} className={`cursor-pointer ${defaultDimensions} bg-black relative hover:opacity-90`} onClick={()=>{props.callBackFunction(props.stateIdentifier)}}>

      { props.isCustomThumb ? null : <PlayCircleFilledIcon style={{position:'absolute', marginLeft:'-30px', marginTop:'-30px', top:'50%', left:'50%', height:'60px', width:'60px', color:'white' }} />}
      { props.isCustomThumb ? null : <img src={props.thumbnail} alt="Testimonial Image" /> }
    </div>
    )
}
