import React, { Component } from 'react'

export default class InputComponent extends Component {



    render() {
        let UsedTag = 'input'    

        if (this.props.textareaEnabled === true) {
            UsedTag = 'textarea'  
        }

        return (
            <div>
                <UsedTag className={`border-2 p-4 rounded-lg mb-2 w-full ${this.props.className}`} id={this.props.id} type={this.props.text} placeholder={this.props.placeholder} rows={this.props.rows} />
            </div>
        )
    }
}
