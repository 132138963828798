import React, {useContext, createContext, useState} from 'react'
//import { useAuth } from './AuthContext' 
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

const LoadingContext = createContext()

export const useLoadingContext = () => {
       return useContext(LoadingContext)
}

export const LoadingContextProvider = (props) => {

    const useStyles = makeStyles((theme) => ({
        backdrop: {          
          color: theme.palette.primary.main,
        },
      }));

  const classes = useStyles();
  //const { isAuthLoading, isDbUserLoading } = useAuth()
  const [shouldLoad, setshouldLoad] = useState(false)

  const value={
    shouldLoad,
    setshouldLoad      
  }

    return(
        <LoadingContext.Provider value={value}>
        <div>
            <div className={`relative z-40`}>
            <Backdrop transitionDuration={200} className={classes.backdrop} open={shouldLoad}>
            <CircularProgress color="inherit" />
            </Backdrop>
            </div>           
            {props.children}
        </div>
        </LoadingContext.Provider>
    )
}