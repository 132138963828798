import React, { Component } from 'react'
import InputComponent from '../components/fundamentalComponents/InputComponent'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { swal } from 'sweetalert2/dist/sweetalert2.all'
import { makeStyles } from "@material-ui/styles"
import { createMuiTheme, ThemeProvider  } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';


const MySwal = withReactContent(Swal)

export default class TicketForm extends Component {

    constructor(props) {
        super(props)
        this.state={
            filledName:null,
            filledEmail: null,        
            filledMensagem: null,  
            ticketEnviado: false, 
            openTicketButtonText: "Abrir Ticket!",
            enviandoTicket: false,
        }
        
        this.smtpEmailFunction=()=>{
            if (!this.state.enviandoTicket) {
                this.setState({enviandoTicket: true})
                this.setState({openTicketButtonText:"Enviando..."})    

                window.Email.send({
                    Host : "higorfabiano.com.br",
                    Username : "automatico@higorfabiano.com.br",
                    Password : "_r41n7)bp$b8",
                    To : 'suporte@higorfabiano.com.br',
                    From : 'automatico@higorfabiano.com.br',
                    ReplyAddress: this.state.filledEmail,   
                    Subject : `Pedido de Suporte: ${this.props.selectedSubject} Turma: ${this.props.selectedTurma}`,
                    Body : `
                    Nome: ${this.state.filledName}<br>
                    Email: ${this.state.filledEmail}<br>
                    Turma: ${this.props.selectedTurma}<br>
                    Assunto: ${this.props.selectedSubject}<br>
                    Mensagem: ${this.state.filledMensagem}<br>
                    `
                    
                }).then(
                    (message) => {
    
                    if (message.toLowerCase().includes('ok'))
                    { 
                        this.setState({enviandoTicket: false})
                        this.setState({ticketEnviado: true})
                        this.setState({openTicketButtonText:"Abrir Ticket!"})                       
                        MySwal.fire({                
                            icon: 'success',
                            title: <p>Sucesso! Seu ticket foi enviado para nossa central para análise!</p>,            
                        }
                        )
                    }
                    else {
                        this.setState({enviandoTicket: false})
                        this.setState({openTicketButtonText:"Abrir Ticket!"})    
                        MySwal.fire({                
                            icon: 'error',
                            title: <p>Ocorreu um erro</p>,
                            text: 'Por favor confira seus campos ou tente novamente mais tarde.'            
                        }
                        )
                    }
                }
                );
    
                window.Email.send({
                    Host : "higorfabiano.com.br",
                    Username : "automatico@higorfabiano.com.br",
                    Password : "_r41n7)bp$b8",
                    To : this.state.filledEmail,
                    From : 'suporte@higorfabiano.com.br',
                    ReplyAddress: 'suporte@higorfabiano.com.br',   
                    Subject : "Obrigado por abrir o seu ticket!",
                    Body : `            
                    Olá ${this.state.filledName} da turma ${this.props.selectedTurma}!<br>
                    Recebemos seu ticket sobre o assunto: ${this.props.selectedSubject}! <br>                
                    Email de agradecimento pelo contato ao suporte.
                    `
                }).then(
                  message => console.log(message)
                );
            }
            else{
               
            }

          
           
        }

    }

    SmtpScript = document.createElement('script');

    componentDidMount() { 

        let nameInput = document.querySelector('#name-input')   
        let emailInput = document.querySelector('#email-input')
        let messageInput = document.querySelector('#message-input')
 
 
 
        nameInput.addEventListener('input',(e)=>{
         this.setState({filledName: e.target.value})     
        })

 
        emailInput.addEventListener('input',(e)=>{
         this.setState({filledEmail: e.target.value})
        })
 
        messageInput.addEventListener('input',(e)=>{
         this.setState({filledMensagem: e.target.value})
        })
 
 
         this.SmtpScript.src='https://smtpjs.com/v3/smtp.js' ;
         this.SmtpScript.async = true;
 
         document.body.appendChild(this.SmtpScript);
    }

    componentWillUnmount() {

        document.body.removeChild(this.SmtpScript);
        
    }

    render() {

        const themeButton = createMuiTheme({
            overrides: {
              // Style sheet name ⚛️
              MuiButton: {
                // Name of the rule
                text: {
                  // Some CSS
                  background: 'linear-gradient(135deg, #e66105 30%, #e66105 300%)',
                  borderRadius: 5,
                  border: 0,
                  color: 'white',
                  height: 48,
                  padding: '0 30px',
                  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                },
              },
            },
          });

        return (
           
            <div>  
             {!this.state.ticketEnviado ?
                <div>
                    <InputComponent  id="name-input" type="text" placeholder="Nome Completo" />             
                    <InputComponent  id="email-input" type="text" placeholder="E-mail" />    
                    <InputComponent textareaEnabled={true}  rows="5"  id="message-input" type="text" placeholder="Mensagem" />                                 
                    <ThemeProvider theme={themeButton}> 
                    <Button onClick={()=>this.smtpEmailFunction()}>{this.state.openTicketButtonText}</Button>
                    </ThemeProvider> 
            </div>
            :
            <div className='flex items-center justify-center flex-col'>
            <h1 className="text-2xltext-center text-white">Obrigado por abrir o seu ticket {this.state.filledName}!</h1><br/>
            <p className="text-2xl text-center text-white">O e-mail que você forneceu: {this.state.filledEmail} deve ser consultado para atualiações e comunicações posteriores!</p>
         
            </div>
            }
              </div>  
         
        )
    }
}
