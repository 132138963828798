import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useEntranceAnimation from '../../customHooks/animationRelated/useEntranceAnimation'
import ReactPlayer from 'react-player';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
gsap.registerPlugin(ScrollTrigger);

// Fundamental UI components to be used in the sections

function Section({ children }) {
  return (
    <div className={`max-w-screen-lg w-full h-full p-4 py-8 grid grid-cols-1 place-items-center justify-center`}>
      {children}
    </div>
  )
}

function Header({children}){
  return(
    <div className={`text-3xl my-4 text-center lg:text-4xl border-l-2 border-custom-orange-default pl-2  font-bold`}>{children}</div>
  )
}

function Description({children}){
  return(
    <div className={`text-base font-light text-gray-200`}>{children}</div>
  )
}

function CallButton({children, onClick}){
  return(
    <button onClick={()=>{onClick()}} className={`bg-custom-orange-default text-lg font-bold w-full hover:bg-opacity-90 transition-colors ease-in-out duration-150 px-2 py-3 rounded-lg`}>
      {children}
    </button>
  )
}

function CheckMarkBox({children, highlight, icon}){
  return(
    <div className={`flex w-full bg-gray-200 text-gray-600 ${ highlight ? 'text-2xl lg:text-2xl' : 'rounded-lg'} items-start justify-center gap-2 px-4 p-2`}>
      <span className={`hidden rounded-lg text-2xl lg:text-2xl`}>This span only exist for tailwind pre-loading of certain classes that are inside of conditional rendering methods</span>
      {icon}
      {children}
    </div>
  )
}

function ODiv({ children }) {
  return (
    <div className={`overflow-hidden w-full flex flex-col items-center jusity-center`}>{children}</div>
  )
}

// Sections

export function HeroSection({ data }) {
  
  const heroSection = useRef(null)

  const dynamicRefForAnimation = useEntranceAnimation(heroSection)


  return (
    <Section>
      <div ref={heroSection} className={`grid gap-6 grid-cols-1 lg:grid-cols-2 place-items-stretch justify-center-stretch`}>

        <div className={`grid grid-cols-1 gap-4`}>

          <div className={`grid gap-2 grid-cols-1`}>
            <div className={``}>
              <ODiv>
                <div ref={dynamicRefForAnimation(1)} className={`text-2xl lg:text-4xl font-bold w-full opacity-0 transform -translate-y-full`}>
                  <Header>{data.headline}</Header>
                </div>
              </ODiv>
            </div>

            <div className={``}>
              <ODiv>
                <div ref={dynamicRefForAnimation(2)} className={`text-lg lg:text-2xl font-light w-full opacity-0 transform -translate-y-full`}>
                  {data.secondHeadline}
                </div>
              </ODiv>
            </div>
          </div>

          <div className={`flex lg:flex-row flex-col items-center justify-start gap-6 `}>

            <div className={`border border-gray-100 p-4 rounded-lg`}>
              <div className={``}>
                <ODiv>
                  <div ref={dynamicRefForAnimation(3)} className={`pl-2 border-l-4 border-custom-orange-default text-lg lg:text-xl opacity-0 transform translate-y-full `}>
                    {data.subHeadline}
                  </div>
                </ODiv>
              </div>
              <div className={``}>
                <ODiv>
                  <div ref={dynamicRefForAnimation(4)} className={`pl-2 border-l-4 border-custom-orange-default text-lg lg:text-xl w-full opacity-0 transform translate-y-full `}>
                    {data.subHeadDescription}
                  </div>
                </ODiv>
              </div>
            </div>


            <div className={`border lg:w-auto w-full relative border-red-600 rounded-lg px-4 py-2 flex items-center justify-center p-4`}>
              <ODiv>
                <div ref={dynamicRefForAnimation(5)} className={`text-base text-red-500 text-center w-full opacity-0 transform translate-y-full `}>
                  {data.format}
                </div>
              </ODiv>

              <div className={`w-2 h-2 absolute top-1 right-1 rounded-full  bg-red-500 animate-ping`}></div>
              <div className={`w-2 h-2 absolute top-1 right-1 rounded-full  bg-red-500 animate-pulse`}></div>

            </div>

          </div>

          <div className={`grid grid-cols-1 gap-4`}>
            <div className={``}>
              <ODiv>
                <div ref={dynamicRefForAnimation(6)} className={`text-xl w-full opacity-0 transform translate-y-full `}>
                  {data.whenWillHappen}
                </div>
              </ODiv>
            </div>

            <div className={``}>
              <ODiv>
                <div ref={dynamicRefForAnimation(7)} className={`text-lg  text-center px-2 py-1 w-full opacity-0 transform translate-y-full `}>
                  <CallButton>{data.buttonText}</CallButton>
                </div>
              </ODiv>
            </div>
          </div>

        </div>

        <div className={``}>
          <ODiv>
            <div ref={dynamicRefForAnimation(2)} className={`w-full h-full flex items-center justify-center opacity-0 transform translate-x-full`}>
              <img className={`w-72`} src={data.image1Url} alt='' />
            </div>
          </ODiv>
        </div>

      </div>
    </Section>
  )
}

export function VideoHighlightSection({data}){

  const [isFirstVideoLoading, setisFirstVideoLoading] = useState(true)
  const videoHighlightSection = useRef(null)
  
  const dynamicRefForAnimation = useEntranceAnimation(videoHighlightSection)


  return(
    <Section>
      <div ref={videoHighlightSection} className={`w-full gap-6 flex flex-col items-center justify-center  h-full`}>
      <ODiv>  
          <div ref={dynamicRefForAnimation(2)} className={`border-2 basis-full transform -translate-y-full opacity-0 relative border-custom-orange-default rounded-lg w-full lg:w-4/5 lg:h-96 md:h-72 h-48`}>                         
                                {/* <img src={Covidfree} /> */}
                                {isFirstVideoLoading && <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}>
                                    <div className={`h-12 w-12 border-2 animate-spin border-custom-orange-default`}></div>
                                    </div>}                            <ReactPlayer                                        
                                            onReady={()=>{setisFirstVideoLoading(false)}}
                                            width='100%'
                                            height="100%"                                       
                                            controls={true}
                                            url={'https://youtu.be/Dyne_2rnaZw'}
                                />

          </div>
        </ODiv>
      <Header>{data.headline}</Header>      
      <Description>{data.description1}</Description>
      <Description>{data.description2}</Description>
      {data.reasonsList.map((item)=>{
        return(
          <div key={item.id}>
            {item.title}
          </div>
        )
      })}
      <Description>{data.description3}</Description>
      <CallButton>{data.buttonText}</CallButton>
      
        
      </div>
    </Section>
  )
}

export function DefinitionsSection({data}){

 let definitionsSection = useRef([])

 const dynamicRefForAnimation = useEntranceAnimation(definitionsSection)

  return(
    <Section>
      <div ref={definitionsSection} className={``}>       
        <img src={data.imageUrl1} alt=''/>
        <img src={data.imageUrl2} alt=''/>
        <Description>{data.description1.part1}</Description>
        <Description>{data.description1.part2}</Description>
        <Description>{data.description1.part3}</Description>
        <Header>{data.headline2}</Header>
        {data.headline2PowerWord}
        <Description>{data.description2}  </Description>
        <ODiv>
            <div ref={dynamicRefForAnimation(1)} className={`text-white text-2xl lg:text-4xl font-bold w-full opacity-0 transform -translate-y-full`}>
                {data.headline1}
            </div>
        </ODiv>
      
      </div>  
    </Section>
  )
}

export function CallToAction1({data}){

  let callToAction1Section = useRef([])

  const dynamicRefForAnimation = useEntranceAnimation(callToAction1Section)
  return(
    <Section>
          <div className={`shadow-lg`}>
                  {data.itemsArray1.map((item)=>{
                    return(
                      <CheckMarkBox highlight={true} icon={item.icon} key={item.id}>
                        {item.item}
                      </CheckMarkBox>
                    )
                  })}   
          </div>
      <CallButton>{data.buttonText}</CallButton>  
    </Section>
  )
}
export function CallToAction2({data}){

  let callToAction2Section = useRef([])

  const dynamicRefForAnimation = useEntranceAnimation(callToAction2Section)

  return(
    <Section>
      <div className={`flex flex-col gap-4`} ref={callToAction2Section}>
        <Header>{data.headline}</Header>
        <Description>{data.subheadline2}</Description>
      <div className={`flex gap-4 flex-col lg:flex-row`}>
          {data.itemsArray1.map((item,i)=>{
            return(
            <div className={`w-full h-full`} key={item.id}>
               <ODiv>
                  <div ref={dynamicRefForAnimation(i+1)} className={`flex gap-4 items-center justify-center w-full lg:h-24 p-4 rounded-lg bg-gray-600 transform translate-y-full opacity-0`}>
                    {item.icon}
                    {item.item}
                  </div>
               </ODiv>
            </div>
            )
          })}
      </div>
         <CallButton>{data.buttonText}</CallButton>
       
      </div>
    </Section>
  )
}
export function CallToAction3({data}){

  let callToAction3Section = useRef([])

  const dynamicRefForAnimation = useEntranceAnimation(callToAction3Section)
  return(
    <Section>
     <div className={`flex flex-col gap-4`} ref={callToAction3Section}>
        {data.headline1}
        {data.paragraph1}
        {data.headline2}
        {data.paragraph2}
        <div className={`flex flex-col gap-1`}>
              {data.itemsArray1.map((item,i)=>{
                return(
                 <div className={`w-full`} key={item.id}>
                   <ODiv>
                      <div ref={dynamicRefForAnimation(i+1)}  className={`transform w-full translate-x-full opacity-0`}>
                        <CheckMarkBox icon={item.icon}  key={item.id}>
                            {item.item}
                        </CheckMarkBox>
                      </div>
                   </ODiv>
                 </div>
                )
              })}
        </div>
       <CallButton>{data.buttonText}</CallButton>
       
     </div>
    </Section>
  )
}

export function CallToAction4({data}){

  let callToAction4Section = useRef([])

  const dynamicRefForAnimation = useEntranceAnimation(callToAction4Section)

  return(
    <Section>
     <div className={`flex flex-col gap-4 items-center justiy-center`} ref={callToAction4Section}>
        {data.headline1}
        {data.date1}
        {data.hours1}
        {data.headline2}
        {data.crossedPrice}
        {data.alternativePrice}
        <CallButton>{data.buttonText}</CallButton>
          <div className={` flex items-center justify-center gap-4`}>
                {data.safeData.map((item)=>{
                  return(
                  <div key={item.id}>
                      <div className={`flex p-4 bg-green-600 rounded-lg items-center justify-center flex-col gap-1`}>
                                {item.icon}
                                <div className={`text-lg font-bold`}>{item.headline}</div>
                                <div className={`font-light`}>{item.paragraph}</div>
                      </div>
                  </div>
                  )
                })}
          </div>
   <div className={`flex items-center justify-center gap-4`}>
          {data.cardOptions.map((item)=>{
            return(
              <div key={item.id}>
                <img className={`w-12`} src={item.cardImageUrl} alt="" />
              </div>
            )
          })}  
   </div>
     </div>
    </Section>
  )
}

export function CallToAction5({data}){

  let callToAction5Section = useRef([])

  const dynamicRefForAnimation = useEntranceAnimation(callToAction5Section)

  return(
    <Section>
     <div ref={callToAction5Section}>
      <div className={`flex lg:flex-row flex-col gap-4 items-center justify-center`}>
      <img className={`w-48`} src={data.imageUrl1} alt=""/>
          <div className={`text-2xl lg:text-4xl font-bold`}>{data.headline1}</div>
        
      </div>
        <CallButton>{data.buttonText}</CallButton>
     
     </div>
    </Section>
  )
}

export function CallToAction6({data}){

  let callToAction6Section = useRef([])

  const dynamicRefForAnimation = useEntranceAnimation(callToAction6Section)

  return(
    <Section>
      <div className={`flex flex-col gap-6`} ref={callToAction6Section}>
        <div ref={dynamicRefForAnimation(1)}><Header>{data.headline}</Header></div>
        <div className={`flex flex-col text-lg gap-2`}>
                {data.subheadline}
                {data.headline2}
        </div>
        <div className={`flex flex-col gap-1 w-full`}>
          {data.itemList.map((item,i)=>{
            return(
           <div key={item.id}>
               <ODiv>
                  <div ref={dynamicRefForAnimation(i+1)} className={`w-full transform opacity-0 translate-x-full`}>
                    <CheckMarkBox icon={item.icon}>
                       {item.item}
                    </CheckMarkBox>
                  </div>
               </ODiv>
           </div>
            )
          })}
        </div>
         <CallButton>{data.buttonText}</CallButton>
        <div>
          calltoaction6
        </div>
      </div>
    </Section>
  )
}

export function WhoAmI ({data}){

  let whoAmISection = useRef([])

  const dynamicRefForAnimation = useEntranceAnimation(whoAmISection)

  return (
  <div>
      <div className={`grid max-w-screen-lg lg:grid-cols-2 grid-cols-1 gap-4 bg-gray-800 p-4 rounded-lg lg:pt-16 py-8`}>
        <div className={`relative flex flex-col items-center justify-center rounded-lg`}>
              <div className={`relative flex items-center justify-center w-72`}>
                  <div className={`absolute opacity-20 transform rotate-45 w-64 h-64 bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2  z-10`}>
                    {data.svg1}
                  </div>  
                  <img className={`relative z-20`} src={data.image1} alt='' />  
              </div>
              <div className={`static lg:absolute lg:bottom-10 lg:z-30 flex flex-col items-center lg:items-end justify-center right-5 gap-2`}>
                  <div className={`text-center lg:text-right bg-custom-orange-default text-gray-100 font-sans text-lg rounded-md px-4 py-1`}>{data.nome}</div>
                  <div className={`text-center lg:text-right bg-custom-orange-default text-gray-100 font-sans text-lg rounded-md px-4 py-1`}>{data.titulo}</div>
              </div>
        </div>
        <div className={`flex text-gray-100 flex-col items-center justify-center`}>
            <div className={``}>{data.text}</div>          
            {/* <div className={`border border-custom-orange-default p-4`}>
            <p>Tempo restante:</p>
  
              <Countdown
               date={'2022-05-09T22:22:00'}
               renderer={TimeRenderer}>           
              </Countdown> 
  
            </div>*/}
        </div>
       
      </div>
      <CallButton>{data.buttonText}</CallButton>
  </div>
  )
}

export function Testimonials({data}){

  let testimonialsSection = useRef([])

  const dynamicRefForAnimation = useEntranceAnimation(testimonialsSection)

  return(
    <Section>
      <Header>{data.headline}</Header>
      {data.testimonialList.map((item)=>{
        return(
        <div key={item.id}>
          <img src={item.imageUrl} alt="" />
        </div>
        )
      })}
      <div>
           Testimonials
      </div>
    </Section>
  )
}

export function FAQ({data}){

  let faqSection = useRef([])

  const dynamicRefForAnimation = useEntranceAnimation(faqSection)


  return(
    <Section>
      <div className={`flex flex-col items-center justify-center gap-4`}>
        <Header>{data.headline}</Header>
        <div className={`grid grid-cols-1 lg:grid-cols-2 place-items-stretch justify-items-stretch gap-4`}>
                {data.itemList.map((item)=>{
                  return(
                    <div key={item.id} className={`p-4 gap-2 h-full w-full flex flex-col items-start justify-start rounded-lg bg-gray-600`}>             
                            <div className={`text-lg text-gray-200 font-bold`}>{item.question}</div>
                            <div className={` text-gray-300 font-light`}>{item.resposta}</div>
                    </div>
                  )
                })}
        </div>
        <CallButton onClick={console.log('')} >{data.buttonText}</CallButton>
      </div>
    </Section>
  )
}
