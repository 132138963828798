import React from 'react'
import TituloPadrao from './TituloPadrao';
import mockUp from '../../images/mock-00037_easy-resize.com.jpg'

export default function OQueVoceRecebe() {
    return (
        <div className={`pt-16 pb-16 md:pt-0`}>
            <TituloPadrao>O que você recebe de presente exclusivo se fizer sua Matrícula?</TituloPadrao>
            <div className={`grid md:grid-cols-2 grid-cols-1`}>
                
            <div className="flex md:flex-row flex-col items-center justify-center">
            <img src={mockUp} alt=""></img>
            </div>

            <div className="flex text-2xl flex-col items-center justify-center p-4">
             <p>➔ <strong>Livro Digital </strong>- Além do treinamento em vídeo, explicado, detalhado, você recebe o livro digital com todo o conteúdo para trazer mais praticidade nos estudos.</p>
            </div>


            </div>

            <div className={`grid md:grid-cols-2 pt-4 grid-cols-1`}>

                <div className="flex md:flex-row flex-col items-center justify-center">
                <img src={mockUp} alt=""></img>
                </div>
                                 
                <div className="flex text-2xl flex-col items-center justify-center p-4">
                 <p>➔ <strong>15ª objeção Extra </strong>- Você receberá um Argumento para a objeção do momento: A PANDEMIA.</p>
                </div>             
    
                </div>

                       
        </div>
    )
}
