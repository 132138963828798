import React, {useState} from 'react'
import {functions} from '../../Firestore';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useHistory } from 'react-router-dom';
import { useSnack } from '../../context/SnackContext';
import { makeStyles } from '@material-ui/styles';
import Countdown from 'react-countdown';
import {ReactComponent as SVG1} from '../../images/Visiwig-Dots (1).svg';
export function Section({children}){
  return(
    <div className={`w-full gap-4 grid grid-cols-1 place-items-center justify-items-center max-w-screen-lg`}>
      {children}
    </div>
  )
}

export function ListaDeAulas({list}) {
  return(
      
     <>
        { 
        list.map((element)=>{
            return(
                <AulasEDatas
                key={element.id}
                mode={element.mode}
                date={element.date}
                dateDescription={element.dateDescription}
                id={element.id}
                tagTitle={element.tagTitle}
                headline={element.headline}
                description={element.description}   
                />
            )
        })        
        }
        
     </>
  )
}

export function AulasEDatas({
  mode,
  date,
  dateDescription,
  id,
  tagTitle,
  headline,
  description,
}) {
  return (
  <>
     { 
     mode === 'default' &&
      <div className={`grid p-2 lg:grid-cols-3 grid-cols-1 lg:gap-2 gap-6 place-items-start max-w-3xl`}>
        <div className='lg:col-span-1'>
          <div className={`flex border-l gap-2 pl-4 border-custom-orange-default flex-col items-start justify-center`}>
            <div className={`text-6xl text-gray-100 font-bold font-roboto`}>{date}</div>
            <div className={`text-base text-custom-orange-default font-roboto font-light`}>{dateDescription}</div>
          </div>
        </div>
        <div className='lg:col-span-2'>
          <div className={`flex gap-2 flex-col items-start justify-start`}>
            <div className={`rounded-md text-base font-light text-white font-roboto bg-custom-orange-default px-3 py-1`}>{tagTitle} {id}</div>
            <div className={`flex pb-4 border-b border-opacity-10 border-custom-orange-default flex-col items-start justify-start gap-2`}>              
              <div className={`text-2xl font-roboto font-bold text-gray-200`}>{headline}</div>
              <div className={`text-sm font-roboto font-light text-gray-300`}>{description}</div>
            </div>
          </div>
        </div>
      </div>
     }
  </>
  )
}

export function IconBox({icon, text}){
  return(
    <div className={`flex p-4 border-opacity-40 gap-2 w-28 border-l border-t border-gray-400 h-auto flex-col items-center justify-start`}>
      <div className={`text-custom-orange-default`}>{icon}</div>
      <div className={`text-gray-300`}>{text}</div>
    </div>
  )
}

export function IconBoxList({list}){
  return (
     <>
     <div className={`grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-6 plaice-items-center justify-items-center`}>
        {
         list.map((element)=>{
          return(
            <IconBox text={element.text} icon={element.icon}/> 
          )
        })
       }
     </div>
    </>
  
  )
}

export function HeroSection ({data}){

  // Little assistance in remembering the format of the data
  let exampleData = {
    image1: 'img',
    logo: 'img',
    icon: <div></div>,
    title: <span></span>,
    descriptiveDate: <span></span>,      
    nome: <span></span>,
  }

return(
  <div className={`grid grid-cols-1 lg:grid-cols-2 place-items-start justify-items-center`}>
      <div className={`flex flex-col items-center lg:items-stretch  justify-center gap-6`}>
            <img className={`w-64`} src={data.logo} alt=''/>      
            <div className={`text-lg lg:text-2xl text-gray-100 font-bold font-roboto`}>{data.title}</div>      
            <div className={`flex gap-2`}>
              <div className={`text-custom-orange-default`}>{data.icon}</div>
              <div className={`text-gray-300 font-roboto`}>{data.descriptiveDate}</div>
            </div>
            <ActiveForm paginaDeObrigado={data.paginaDeObrigado}/>
      </div>
      <div>
        <div className={`relative`}>
          <img className={`w-96 p-4`} src={data.image1} alt=''/>
          <div className={`text-right bg-custom-orange-default text-gray-100 font-sans text-lg absolute lg:bottom-5 -bottom-5 right-5 z-10 rounded-md px-4 py-1`}>{data.nome}</div>
        </div>               
      </div>
  </div>
)  
}

export function ActiveForm ({paginaDeObrigado, textoDoBotao}){
  
    const handleLogInBackend= functions.httpsCallable('handleLogInBackend')
    const handleActiveCampaignRequest = functions.httpsCallable('handleActiveCampaignRequest')
    const handleFacebookRequest = functions.httpsCallable('handleFacebookRequest')

    const [isLoading, setIsLoading] = useState(false)
    const [pathForApiRequest, setpathForApiRequest] = useState('')
    const [state, setstate] = useState({
        email:'',
        name:''
    })   
    const { handleSnack } = useSnack()
    const history = useHistory()  

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).replace(/\s/g, '').toLowerCase());
    }
    
    const handleSubmit = async () => {
                  
          setIsLoading(true)   
          if (validateEmail(state.email)) {
              if (state.name !== '' && state.name !== undefined && state.name !== null ) {
                await handleActiveCampaignRequest({
                    'method': 'POST',  
                    'path': 'contacts',
                    'list': 30,
                    'body': {
                        "contact": {
                            "email": state.email.replace(/\s/g, '').toLowerCase(),
                            "firstName": state.name,
                        }
                    }                         
               })                   
               .then((doc) => {

                   if (doc.data.messageType !== 'success') {   
                    handleSnack(doc.data.messageType, doc.data.message)       
                    history.push({
                        'pathname':paginaDeObrigado,
                        'state': {name: state.name, email:state.email.replace(/\s/g, '').toLowerCase()}
                        })
                   }   
                   
                   if (doc.data.messageType === 'success') {                  
                    handleFacebookRequest({
                        eventName:"Lead",
                        email: state.email.replace(/\s/g, '').toLowerCase()
                       })                      
                   history.push({
                    'pathname':paginaDeObrigado,
                    'state': {name: state.name, email:state.email.replace(/\s/g, '').toLowerCase()}
                    })
                   }
                   setIsLoading(false)  
                })
               .catch((err) => {
                   handleSnack('error', 'Algo de errado ocorreu. Confira sua conexão ou tente mais tarde.')
                    console.log(err);
                   setIsLoading(false)  
                })

              } else {
                handleSnack('warning', 'Nome Invalido')
                setIsLoading(false)  
              }

        
          } else {
              handleSnack('warning', 'Email Invalido')
              handleLogInBackend({                   
                  message: `O email "${state.email}" foi apresentado como invalido`,
                  email: state.email  
              })
              setIsLoading(false)  
          }
           
       
    } 




    // Material Ui Styling
    const useStyles = makeStyles( (theme)=>({
        button: {width:'auto', height:'auto'}

    }))

    const classes = useStyles();
    // End of Material Ui Styling
 

     return (
        <div className={`md:mt-0 -mt-4`}>   
            {/* <TextField
              id=""
              label="Path"
              onChange={(e) => {setpathForApiRequest(e.target.value)}}              
            />
            <Button onClick={() => {handleActiveCampaignRequest(
                {
                    'method': 'GET',        
                    'path': pathForApiRequest,            
                }
            )}} variant="contained" color="primary">
              Test Active Campaign
            </Button> */}

            <div className={`rounded-lg bg-gray-200 flex flex-col item-center justify-center w-full h-auto gap-4 p-4`}>
            
                <TextField
                  fullWidth={true}
                  id=""
                  label="Nome Completo"                  
                  onChange={(e) => {setstate((i) =>({...i, name: e.target.value}))}}    
                />
                   <TextField
                  fullWidth={true}                  
                  data-name="email"
                  name="email"
                  id="email"
                  label="E-mail"                  
                  onChange={(e) => {
                      setstate((i) =>({...i, email: e.target.value}))                    
                    }}
                  
                />

                <Button fullWidth={true} className={classes.button} disabled={isLoading} onClick={() => {handleSubmit()}} variant="contained" color="primary">
                  <p className={`text-white`}>
                      { isLoading ? 'Processando' : (textoDoBotao ? textoDoBotao : 'Quero me Inscrever Agora')}</p>
                </Button>
            </div>

        </div>
    )
}

export function CaptureSection ({data}){
  return(
    <div className={`flex flex-col gap-4 p-4`}>
      <div className='font-roboto text-lg text-gray-100 border-custom-orange-default'>
        {data.text}
      </div>   
      <ActiveForm paginaDeObrigado={data.paginaDeObrigado}/>
    </div>
  )
}

export function TimeRenderer({hours, minutes, seconds, days, completed}){
  return(
    <>
    {
    completed ?
    <div>Tempo Esgotado!</div>
    :
    <div className={`grid text-base grid-cols-4 gap-2 place-items-center justify-items-center`}>
        <div className={`flex flex-col items-center justify-start`}>
          <p className={`font-bold text-2xl`}>{days}</p>
          <p>Dias</p>
        </div>
        <div className={`flex flex-col items-center justify-start`}>
          <p className={`font-bold text-2xl`}>{hours}</p>
          <p>Horas</p>
        </div>
        <div className={`flex flex-col items-center justify-start`}>
          <p className={`font-bold text-2xl`}>{minutes}</p>
          <p>Min</p>
        </div>
        <div className={`flex flex-col items-center justify-start`}>
          <p className={`font-bold text-2xl`}>{seconds}</p>
          <p>Seg</p>
        </div>
    </div>
   }
   
    </>    
  )
}

export function WhoAmI ({data}){
  return (
    <div className={`grid lg:grid-cols-2 grid-cols-1 gap-4 bg-gray-800 p-4 rounded-lg lg:pt-16 py-8`}>
      <div className={`relative flex flex-col items-center justify-center rounded-lg`}>
            <div className={`relative flex items-center justify-center w-72`}>
                <div className={`absolute opacity-20 transform rotate-45 w-64 h-64 bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2  z-10`}>
                  <SVG1/>
                </div>  
                <img className={`relative z-20`} src={data.image1} alt='' />  
            </div>
            <div className={`static lg:absolute lg:bottom-10 lg:z-30 flex flex-col items-center lg:items-end justify-center right-5 gap-2`}>
                <div className={`text-center lg:text-right bg-custom-orange-default text-gray-100 font-sans text-lg rounded-md px-4 py-1`}>{data.nome}</div>
                <div className={`text-center lg:text-right bg-custom-orange-default text-gray-100 font-sans text-lg rounded-md px-4 py-1`}>{data.titulo}</div>
            </div>
      </div>
      <div className={`flex text-gray-100 flex-col items-center justify-center`}>
          <div className={``}>{data.text}</div>          
          <div className={`border border-custom-orange-default p-4`}>
          <p>Tempo restante:</p>

            <Countdown
             date={'2022-05-09T22:22:00'}
             renderer={TimeRenderer}>           
            </Countdown>

          </div>
      </div>
    </div>
  )
}