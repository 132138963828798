import React, {useRef, useState, useEffect} from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import firebase from './Firestore';
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container'
import { IconButton } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Input, InputLabel, Button  } from '@material-ui/core';
import { useAuth } from './context/AuthContext'
import { useSnack } from './context/SnackContext'
import { Link, useHistory } from 'react-router-dom';
import GoogleButton from 'react-google-button'
import Avatar from '@material-ui/core/Avatar';


export default function Login() {

    const { login, logout, currentUser, isAuthLoading } = useAuth()
    const {snack, handleSnack} = useSnack()
    const history = useHistory()
   

    // Set sweetalert

    const MySwal = withReactContent(Swal)  
     
      const [values, setValues] = React.useState({    
        password: '',   
        email: '',
        loading: false,
        showPassword: false,
      });
    
    

      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });        
      };
      
      const handleLogOut = async ()=>{
        await logout()
        .then(  () =>{
          handleSnack('success', 'Sua sessão foi encerrada.')  
          history.push('/login')   
        }         
        )
        .catch( err => {
           handleSnack('error', err)  
           console.log(err)
           })
    }
    
      const handleSubmit = async (e, method) => {

        e.preventDefault()    
          setValues({ ...values, loading: true })
          await login(values.email, values.password, method)
          .then( response => {
           handleSnack('success', 'Logado com sucesso!')
           history.push('/dashboard', {status:'loggedWithSuccess'})       
         })
          .catch( err => {    
            handleSnack('error', err)                   
          })      
        
        setValues({ ...values, loading: false })
       
      }

      const handleClickShowPassword = (prop) => { 
          // this if statement only exists because of a pointless syntax error 
          // the error did not affect the code at all but keept appearing so
          // I decided to add this if to remove it.       
          if (prop==='showPassword')  {
            setValues(    { ...values, [prop]: !values.showPassword }     );
          } 
     
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    
 
    // Material Ui Styling
    const useStyles = makeStyles((theme)=>({
        button:{
           color:"white",
           marginTop: "2em",
           marginBottom: "1em" 
        },
        emailInput:{
            marginBottom:"2em"
        },
        passwordInput:{
            marginBottom:"1em"
        },

    }))

    const classes = useStyles();
    // End of Material Ui Styling
     

    const textField = useRef(null)
      
    return (
       <div className="min-h-screen p-8 w-full flex flex-col items-center justify-center">    

        { !isAuthLoading &&
     
        <Container maxWidth="xs">      

<div className="wrapper bg-white p-8 shadow-2xl border-2">

<h2 className="text-4xl font-sans font-bold text-gray-700 mb-4">{ !currentUser ? 'Login' : "Usuário Logado"}</h2>

{ currentUser &&
<div className={`bg-gray-200 bg-opacity-50 p-4 rounded-md`}>
  
  <div className={`flex flex-col items-start justify-center gap-4 p-4`}>
   <div>
     <p>{`Olá, ${currentUser.displayName !== null ? currentUser.displayName : `usuário(a)`}!`}</p>
   </div>   
<div className={`flex items-center justify-center gap-4 mb-8`}>
    <Avatar src={currentUser.photoURL}/>
    <p> {currentUser.email} </p>
</div>
  </div>  
<div className={`flex items-center justify-start gap-4`}>
    <Button onClick={() => {history.push('/dashboard')}} style={{color:'white'}} variant="contained" color="primary">
      Dashboard
    </Button>
    <Button  onClick={() => {handleLogOut()}}variant="text" color="primary">
      Logout
    </Button>
</div>
</div>        
}

{ !currentUser &&

          <div>
              <FormControl fullWidth={true} component="fieldset">
                     <InputLabel>Email</InputLabel>       
                      <Input
                      className={classes.emailInput}
                      ref={textField}              
                      fullWidth={true}        
                      value={values.email}                    
                      id=""
                      label="Email"
                      onChange={handleChange('email')}                   
                      />       
          
              </FormControl>
           
            <FormControl fullWidth={true} >
            <InputLabel htmlFor="standard-adornment-password">Senha</InputLabel>
            <Input
              className={classes.passwordInput}
              id="standard-adornment-password"
              fullWidth={true}
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={()=>handleClickShowPassword('showPassword')}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility />  : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
  
          <Button disabled={values.loading} onClick={(e)=>handleSubmit(e,'direct')} className={classes.button} fullWidth={true} variant="contained" color="primary">
            Logar
          </Button>
  
          <GoogleButton label='Logar com o Google' style={{width:'100%'}} type="light" disabled={values.loading} onClick={(e)=>handleSubmit(e,'google')} />
                  
              <Link to="/cadastrar"> <p className="text-center mt-4 cursor-pointer">Não tem uma conta? Cadastre-se.</p> </Link>

          </div> 
 }
            </div> 
         
            </Container>  
            }


        </div>
    )
}
