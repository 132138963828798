import React from 'react'
import Testimonial1 from '../../images/2.png'



export default function TestimonialsDois() {
    return (
        <div className={`pb-16 pt-16 md:pt-0`}>
           <h1 className={`text-center text-gray-200 md:text-4xl text-3xl font-sans font-bold md:pt-16 pt-8 pb-8 `}>JÁ IMAGINOU TER RESULTADOS TÃO BONS QUANTO ESSES?</h1>
        <div className="grid items-center justify-center content-center md:grid-cols-3 gap-2 grid-cols-1">
        <div className={`flex items-center justify-center`}><img src={Testimonial1} alt="" /></div>
        <div className={`flex items-center justify-center`}><img src={Testimonial1} alt="" /></div>
        <div className={`flex items-center justify-center`}><img src={Testimonial1} alt="" /></div>
        <div className={`flex items-center justify-center`}><img src={Testimonial1} alt="" /></div>
        <div className={`flex items-center justify-center`}><img src={Testimonial1} alt="" /></div>
        <div className={`flex items-center justify-center`}><img src={Testimonial1} alt="" /></div>




        </div>

        </div>
    )
}
