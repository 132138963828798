import React from 'react';
import SuccessLottie from './components/successLottie.jsx';
// import ReactPixel from 'react-facebook-pixel';
import img1 from './images/transferir (1).png';
import img2 from './images/002-anger.svg';
import img3 from './images/016-suffering.svg';
import img4 from './images/036-depressed.svg';
import img5 from './images/034-tired.svg';
import img6 from './images/045-puzzled.svg';
import img7 from './images/023-lonely.svg';
import img9 from './images/043-scared.svg';
import img10 from './images/020-pensive.svg';
import higor from './images/e26b8908-4462-477e-b03d-47fb8e753d00.jpg'
import ActiveCampaignExperimentalForm from './components/activeCampaign/ActiveCampaignExperimentalForm.jsx'
import { functions } from './Firestore';
import Testimonials from './components/matriculaExpress/Testimonials.jsx';
import newIcon1 from './images/failure (2).png'
import newIcon2 from './images/failed-message.png'
import newIcon3 from './images/authentication (1).png'
import newIcon4 from './images/fail (1).png'
import newIcon5 from './images/failure (3).png'
import newIcon6 from './images/failure (4).png'
import newIcon7 from './images/dislike (1).png'
import Gradiente from './images/Imagem1.png'
import GradienteMobile from './images/image2.png'
import newIcon8 from './images/failing.png'
import { Link } from 'react-router-dom'
import {QuemSouEu} from './pages/IntensivaoDeMatriculas'
import MexSection, {CountrySelect, ActiveCampaignForm, Section} from './components/MexSection/MexSection.jsx'
import Higor1 from './images/higorrr-min.png';

class MatriculaExpress extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            heroCustomArea1Css: null,
            heroCustomArea2Css: null,
            heroCustomArea3Css: null,
            heroCustomArea4Css: null,
            customHeroIcon1Css: null,
            customHeroIcon2Css: null,
            mainHeroParagraphCss: null,
            tailwindcss: {
                classes: {
                    mainHeaderText: "custom-text-font text-gray-800 text-4xl sm:text-6xl text-center md:text-left font-sans font-bold text-opacity-90 mb-6 md:text-8xl lg:pl-5"
                }
            },
        }
    }

    handleFacebookRequest = functions.httpsCallable('handleFacebookRequest')
    

 
    componentDidMount() {

       

        this.handleFacebookRequest({
            eventName: "PageView",
            email: null
        })

        // page versions 

        if (this.props.pageVersion === 'newDefault') {

            this.setState({
                tailwindcss: {
                    classes: {
                        mainHeaderText: "custom-text-font text-black text-5xl text-center md:text-left font-sans font-bold text-opacity-90 mb-6 lg:pl-5"
                    }
                }
            })

        }






        if (this.props.alternativeHeroLayout) {
            this.setState({ heroCustomArea1Css: "md:col-span-4 " })
            this.setState({ heroCustomArea2Css: 'hidden' })
            this.setState({ heroCustomArea3Css: "" })
            this.setState({ heroCustomArea4Css: "" })
            this.setState({ mainHeroParagraphCss: 'flex-col-reverse' })
            this.setState({ customHeroIcon1Css: "rounded-full w-36 h-36 shadow-md" })
            this.setState({ customHeroIcon2Css: "" })

        } else {
            this.setState({ heroCustomArea1Css: "md:col-span-2  w-full md:h-52 h-auto pt-4" })
            this.setState({ heroCustomArea2Css: 'md:col-span-2 w-full relative hidden md:block' })
            this.setState({ heroCustomArea3Css: "" })
            this.setState({ heroCustomArea4Css: '' })
            this.setState({ mainHeroParagraphCss: 'flex-col' })
            this.setState({ customHeroIcon1Css: "rounded-full orange w-20 h-20" })
            this.setState({ customHeroIcon2Css: 'md:bottom-8 bottom-0' })


        }


        //     const advancedMatching = { em: 'contato@higorfabiano.com.br' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        //     const options = {
        //      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        //      debug: false, // enable logs
        //    };

        // ReactPixel.init('700597194128609', advancedMatching, options);  
        // ReactPixel.init('477353900172815', advancedMatching, options);         
        // ReactPixel.init('1105383856490571', advancedMatching, options);

        // setTimeout(function () {
        //     ReactPixel.trackSingle('700597194128609', 'ViewContent')
        //     ReactPixel.trackSingle('1105383856490571', 'ViewContent')
        //     ReactPixel.trackSingle('477353900172815', 'ViewContent') 
        // }, 3000)



        // document.querySelector("._button-wrapper").addEventListener('click', function (){
        //     ReactPixel.trackSingle('700597194128609', 'Lead') 
        //     ReactPixel.trackSingle('477353900172815', 'Lead') 
        //     ReactPixel.trackSingle('1105383856490571', 'Lead')                
        //  })



    };




    render() {

        return (
            <div className="main flex flex-col items-center justify-center min-w-screen w-full">



{ 
    this.props.type === 1 &&
                    <div id="vantablack" className="herodiv min-w-screen w-full min-h-screen flex items-center justify-center md:pb-8 md:pt-8">
                        <div style={
                            {         
                                // backgroundImage:`url(${(window.innerWidth <= 600 ? GradienteMobile : Gradiente)})`,  
                                // // backgroundRepeat:`no-repeat`,
                                // backgroundSize:`cover`        
                                               
                                }} className="w-full bg-white sm:w-5/6 md:w-5/6 lg:w-5/6 min-h-5/6 sm:rounded-3xl p-4 sm:p-8 max-w-screen-xl">
                        

                            <div className="grid md:grid-cols-4 grid-cols-1 gap-0 w-full">
    
                                <div className={`${this.state.heroCustomArea1Css} area1 z-10 `}>
                                    {this.props.alternativeHeroLayout ? <div className={`border-2 rounded-lg p-4 md:mb-6 mb-2 shadow-md bg-custom-black-default`}>
                                        {this.props.headerTextElement}
                                        <p className="roboto text-center text-gray-400 text-xl">{this.props.observationText}</p>
                                    </div> : <h1 className={this.state.tailwindcss.classes.mainHeaderText}>{this.props.headerText}</h1>}
    
                                </div>
                                <div className={`${this.state.heroCustomArea2Css} area2 `}>
    
    
                                    {this.props.alternativeIcons
                                        ?
                                        <img className={`bg-gray-100 ml-2 p-2 `} alt="" src={newIcon1} />
                                        :
                                       <div>
                                            <img className={`absolute rounded-2xl overflow-hidden bottom-0 right-0 ml-6 p-2 h-30 w-20`} alt="" src={img1} />                                          
                                        </div>
                                    }
    
                                </div>
    
         { this.props.pageVersion === 'newDefault' && window.innerWidth < 500 ? 
         <>
                 
                 <div className={`${this.state.heroCustomArea3Css} area3 md:col-span-2 pl-4 pr-4 pb-0 w-full h-auto`}>
        
        <div className="leftright flex lg:flex-row flex-col items-center justify-center h-full">
    
            <div className={`left flex items-center md:justify-center mr-4`}>
    
    
    
                {   
                this.props.pageVersion === 'oldmex' &&
                    <div className={`${this.state.customHeroIcon1Css}`} >                                                                      
                        
                        <img className="rounded-full border-4 shadow-md" src={this.props.mainHeroImage} alt=''></img>
                    </div>
                }
    
                {
    
                    this.props.pageVersion === 'default' &&
    
    
                    <div className={``} >
                        
                        <div className="orange rounded-full w-20 h-20 flex items-start justify-center">
                            <div className="to-become-white">
                                {this.props.mainHeroLottie}
                            </div>
                        </div>
                     </div>
               }
    
                        {
    
                            this.props.pageVersion === 'newDefault' &&
    
                         <div className={` hidden w-32 h-32 mt-8 mb-12`} >
                            <div className=" flex  items-center justify-center">
                                <div className="">
                                   <img src={this.props.mainLogo} alt=''></img> 
                                </div>
                            </div>
                          </div>
    
                        }                                          
                                    </div>
    
            <div className={`right p-2 flex items-center justify-center ${this.state.mainHeroParagraphCss}`}>
    
               {this.props.pageVersion !== "newDefault" && <p className="text-opacity-90 text-black font-sans text-base mb-2 pt-4 pb-4">{this.props.mainHeroData}</p>}
    
                {this.props.alternativeHeroLayout ? <div>
                    <p className="text-opacity-90 text-center text-black font-sans text-2xl sm:text-3xl">
                        <strong>Matrícula Express: </strong>
                    </p>
                    {
                    this.props.pageVersion==='newDefault' &&
            <>
                            <p className="text-opacity-90 text-black font-sans text-lg mt-2">
                               <span className={`italic`}>• Descubra em 4 dias as principais chaves para <strong>viver de matrículas</strong></span> 
                            </p>
    <p className="text-opacity-90 text-black font-sans text-lg mt-2"><span className={`italic `}>• Aprenda as estratégias de quem começou como panfleteiro e se tornou sócio de uma rede de cursos presenciais.</span></p> 
                 
            </>
                    }
                </div> : <div>
                  
                    <p className="text-opacity-90 text-black font-sans text-md sm:text-2xl md:text-2xl lg:text-2xl">
                    Entenda o passo a passo para você estruturar um processo de vendas focado em matrículas <strong>em apenas 4 dias</strong>
    </p>
                    <div className="wrapperdiv1">
                        <p className="text-opacity-90 text-black font-sans text-xl sm:text-2xl pt-4 pb-0 sm:pb-4">Elimine <strong>de uma vez por todas</strong> as objeções, bolos e principalmente as metas não batidas.</p>
                    </div>
                </div>}
            </div>
        </div>
    
    </div>
    
                 <div className={`${this.state.heroCustomArea4Css} area4 md:col-span-2 w-full h-auto flex items-center justify-center`}>
                                        <div className="h-auto flex lg:flex-col flex-col-reverse lg:items-end items-center">
                                            <div className="text-opacity-90 text-black font-sans text-2xl pl-5">
                                                {/* <p>
                                                <strong className="text-4xl">Treinamento Gratuito e Online </strong></p> */}
                                                </div>
                                            <div id="form" className="form w-full"></div>
                                            <div className="novoFormWrapper w-full">
                                        
                                                <ActiveCampaignExperimentalForm />
                                                 <p className={`p-2`}>Treinamento <strong>Online e Gratuito</strong> de 13 a 16 de setembro às 20h21min (horário de Brasília)</p>
                                            </div>
                                        </div>
                                    </div>
     
         </> :
         <>
         
         <div className={`${this.state.heroCustomArea3Css} area3 md:col-span-2 pl-4 pr-4 pb-0 w-full h-auto`}>
        
        <div className="leftright flex lg:flex-row flex-col items-center justify-center h-full">
    
            <div className={`left flex items-center md:justify-center mr-4`}>
    
    
    
                {   
                this.props.pageVersion === 'oldmex' &&
                    <div className={`${this.state.customHeroIcon1Css}`} >                                                                      
                        
                        <img className="rounded-full border-4 shadow-md" src={this.props.mainHeroImage} alt=''></img>
                    </div>
                }
    
                {
    
                    this.props.pageVersion === 'default' &&
    
    
                    <div className={``} >
                        <div className="orange rounded-full w-20 h-20 flex items-start justify-center">
                            <div className="to-become-white">
                                {this.props.mainHeroLottie}
                            </div>
                        </div>
                     </div>
               }
    
                   <>
                            {
        
                                this.props.pageVersion === 'newDefault' &&
        
                             <div className={`w-32 h-32 mt-8 mb-12`} >
                                <div className=" flex items-center justify-center">
                                    <div className="">                                    
                                       <img src={this.props.mainLogo} alt=''></img> 
                                    </div>
                                </div>
                              </div>
        
                            }   
                   </>                                       
                                    </div>
    
            <div className={`right p-2 flex items-center justify-center ${this.state.mainHeroParagraphCss}`}>
                {/* <p className="text-opacity-90 text-gray-100 font-sans text-base mb-2 pt-4 pb-4">{this.props.mainHeroData}</p> */}
    
                {
                this.props.alternativeHeroLayout ? 
                   <div>
                        <p className="text-opacity-90 text-gray-100 font-sans text-2xl sm:text-3xl">
                            <strong>Matrícula Express: </strong>
                        </p>
                    {
                    this.props.pageVersion==='newDefault' &&
            <>
                            <p className="text-opacity-90 text-gray-100 font-sans text-xl mt-2">
                                Descubra em 4 dias as principais chaves para <strong>viver de matrículas</strong>
                            </p>
    <p className="text-opacity-90 text-gray-100 font-sans text-xl mt-2">Aprenda as estratégias de quem começou como panfleteiro e se tornou sócio de uma rede de cursos presenciais.</p>
                 
            </>
                    }
                </div> : <div>
                    {this.props.history.location.pathname === "/mex2" && 
                     <div>
                             <p className="text-opacity-90 text-black font-sans text-xl sm:text-2xl md:text-2xl lg:text-2xl">
                             Descubra em 4 dias as principais chaves para <strong>viver de matrículas.</strong> </p>
                         <div className="wrapperdiv1">
                             <p className="text-opacity-90 text-black font-sans text-xl sm:text-2xl pt-4 pb-0 sm:pb-4">Aprenda as estratégias de quem começou como panfleteiro e se tornou sócio de uma rede de cursos presenciais.</p>
                         </div>
                     </div>
                    }  
                     {this.props.history.location.pathname !== "/mex2" && 
                     <div>
                             {/* <p className="text-opacity-90 text-gray-100 font-sans text-xl sm:text-2xl md:text-2xl lg:text-2xl">
                             Entenda o passo a passo para você estruturar um processo de vendas focado em matrículas <strong>em apenas 4 dias</strong>. </p>
                         <div className="wrapperdiv1">
                             <p className="text-opacity-90 text-gray-100 font-sans text-xl sm:text-2xl pt-4 pb-0 sm:pb-4">Elimine <strong>de uma vez por todas</strong> as objeções, bolos e principalmente as metas não batidas.</p>
                         </div> */}

                         <p className="text-opacity-90 text-gray-700 font-sans text-xl sm:text-2xl md:text-2xl lg:text-2xl">Entenda o passo a passo para você <strong>estruturar um processo de vendas focado em matrículas</strong> em apenas 4 dias e <strong>elimine de uma vez por todas</strong> as objeções, bolos e principalmente <strong>as metas não batidas</strong>.</p>
                     </div>
                    }                 
                </div>}
            </div>
        </div>
    
    </div>
         <div className={`${this.state.heroCustomArea4Css} area4 gap-2 pt-4 md:col-span-2 w-full h-auto flex items-center justify-center`}>
                                        <div className="h-auto gap-2 flex lg:flex-col flex-col-reverse lg:items-end items-center">
                                            <div className="text-opacity-90 text-gray-700 font-sans text-2xl pl-5"><p>Preencha os campos e garanta a sua presença neste Treinamento
                                                <strong className="text-4xl"> Gratuito e Online </strong></p></div>
                                            <div id="form" className="form w-full"></div>
                                            <div className="novoFormWrapper w-full bg-gray-100 p-4 rounded-md overflow-hidden">

                                            <ActiveCampaignForm
                                            paginaDeObrigado={'/mexobrigado'}
                                            textElement={this.props.mainHeroData}
                                            buttonTextElement={<span>QUERO ESTRUTURAR MEU TIME DE MATRÍCULAS</span>}
                                           />                                      
        
                                            </div>
                                        </div>
                                    </div>
        
         </>
    
         }     
                       </div>
    
                        </div>
                    </div>

}

{
     this.props.type === 2 &&
    <div className={`w-full`}>
        <MexSection type={2}
        mainHeroLottie={this.props.mainHeroLottie}
        headlineElement={"Entenda o passo a passo para você estruturar um processo de vendas focado em matrículas"} 
        subHeadlineElement={
        <div className={`flex flex-col gap-2`}>           
            <p className={`text-gray-200`}>Em apenas 4 dias você é capaz de eliminar de uma vez por todas as objeções, bolos e principalmente as metas não batidas</p>
        </div>
        } 
        paginaDeObrigado={"/mexobrigado"}/>
    </div>
}


{
     this.props.type === 3 &&
    <div className={`w-full`}>
        <MexSection type={3}
           mainHeroLottie={this.props.mainHeroLottie}
        headlineElement={<span className={`text-gray-700`}>Elimine os Bolos e as objeções dos seus atendimentos e pare de perder Matrículas.</span>} 
        subHeadlineElement={
        <div className={`flex flex-col gap-2`}>           
            <p className={`text-gray-600`}>use as técnicas no presencial, online e até mesmo pelo WhatsApp.</p>
        </div>
        } 
        paginaDeObrigado={"/mexobrigado"}/>
    </div>
}

{
     this.props.type === 3.1 &&
    <div className={`w-full`}>
        <MexSection type={3}
           mainHeroLottie={this.props.mainHeroLottie}
        headlineElement={<span className={`text-gray-700`}>Elimine os Bolos e as objeções dos seus atendimentos e pare de perder Matrículas.</span>} 
        subHeadlineElement={
        <div className={`flex flex-col gap-2`}>           
            <p className={`text-gray-600`}>use as técnicas no presencial, online e até mesmo pelo WhatsApp.</p>
        </div>
        } 
        paginaDeObrigado={"/mexobrigado2"}/>
    </div>
}

{
     this.props.type === 4 &&
    <div className={`w-full`}>
        <MexSection type={3}
           mainHeroLottie={this.props.mainHeroLottie}
        headlineElement={<span className={`text-gray-700`}>Descubra o passo a passo para dobrar o seu número de matrículas</span>} 
        subHeadlineElement={
        <div className={`flex flex-col gap-2`}>           
            <p className={`text-gray-600`}>Em apenas 4 dias você vai revolucionar a sua forma de vender</p>
        </div>
        } 
        paginaDeObrigado={"/mexobrigado"}/>
    </div>
}

{
     this.props.type === 5 &&
    <div className={`w-full`}>
        <MexSection type={3}
           mainHeroLottie={this.props.mainHeroLottie}
        headlineElement={<span className={`text-gray-700`}>Aprenda a contornar objeções e aumentar as suas vendas sem precisar ser um vendedor chato
        </span>} 
        subHeadlineElement={
        <div className={`flex flex-col gap-2`}>           
            <p className={`text-gray-600`}>Conheça o nosso método de matrículas já testado e comprovado por mais de 1.000 alunos</p>
        </div>
        } 
        paginaDeObrigado={"/mexobrigado"}/>
    </div>
}

{
     this.props.type === 6 &&
    <div className={`w-full`}>
        <MexSection type={3}
           mainHeroLottie={this.props.mainHeroLottie}
        headlineElement={<span className={`text-gray-700`}>Você quer aprender a técnica mais eficiente para fazer matrículas no primeiro atendimento?</span>} 
        subHeadlineElement={
        <div className={`flex flex-col gap-2`}>           
            <p className={`text-gray-600`}>Se cadastre no treinamento e descubra como vender de forma mais rápida e eficiente.</p>
        </div>
        } 
        paginaDeObrigado={"/mexobrigado"}/>
    </div>
}

{
     this.props.type === 7 &&
    <div className={`w-full`}>
        <MexSection type={3}
           mainHeroLottie={this.props.mainHeroLottie}
        headlineElement={<span className={`text-gray-700`}>Você quer aumentar o número de alunos na sua escola e bater a meta de matrículas do mês?</span>} 
        subHeadlineElement={
        <div className={`flex flex-col gap-2`}>           
            <p className={`text-gray-600`}>Cadastre-se agora no treinamento e aprenda a forma mais eficaz de dobrar o número de alunos da sua escola.</p>
        </div>
        } 
        paginaDeObrigado={"/mexobrigado"}/>
    </div>
}


{
     this.props.type === 8 &&
    <div className={`w-full`}>
        <MexSection type={3}
           mainHeroLottie={this.props.mainHeroLottie}
        headlineElement={<span className={`text-gray-700`}>Você quer bater a sua meta de matrículas ainda neste mês? </span>} 
        subHeadlineElement={
        <div className={`flex flex-col gap-2`}>           
            <p className={`text-gray-600`}>Descubra o passo a passo para contornar objeções e matricular logo no primeiro atendimento </p>
        </div>
        } 
        paginaDeObrigado={"/mexobrigado"}/>
    </div>
}


{
(this.props.history.location.pathname === "/mex" || this.props.history.location.pathname === "/mex3" || this.props.history.location.pathname === "/mex4" || this.props.history.location.pathname === "/mex5" || this.props.history.location.pathname === "/mex6" || this.props.history.location.pathname === "/mex7") && 
    <div>
    <h2 className="opacity-90 font-sans text-center lg:text-center lg:p-0 p-2 lg:text-5xl text-4xl font-bold mb-12 mt-6">Veja o resultado dos nossos alunos</h2>
        <Testimonials type={"mex"} />
        
    </div>
}

{ this.props.history.location.pathname === "/mex2" &&
<>
    
                    <div className="lg:max-w-screen-lg lg:w-full lg:p-8 ">
                        <h2 className="text-opacity-90 text-black font-sans text-center lg:text-left lg:p-0 p-6 lg:text-4xl text-4xl font-bold mb-6 mt-24">Para quem é destinado esse curso gratuito?</h2>
                        <p className="text-opacity-90 mb-24 text-black font-sans p-10 text-2xl lg:p-0 text-center lg:text-left">Para Gerentes, Diretores e Donos de escolas regulares (Ed. Infantil, fundamental e médio) e todos os cursos Livres, podendo ser: Idiomas, Profissionalizantes, Técnicos e também Graduações, Pós e MBA.</p>
                        {/* <h2 className="text-opacity-90 text-black font-sans text-center lg:text-left lg:p-0 p-6 lg:text-4xl text-4xl  font-bold mb-6 mt-6">Para quem NÃO É destinado esse curso Gratuito?</h2>
                        <p className="text-opacity-90 text-black font-sans text-2xl lg:p-0 p-10 mb-24 text-center lg:text-left ">Para Escola Regular (educação infantil, fundamental, médio), afiliados, infoprodutores.</p> */}
    
    
                        <div className="opacity-90 bg-black text-white p-6 lg:p-4 border-l-4 border-custom-orange-default">
                            <div className="">
                                <h2 className="text-opacity-90 font-sans text-center lg:text-left lg:p-0 p-6 lg:text-5xl text-4xl font-bold mb-12 mt-6">Se você está<strong className={`text-custom-orange-default`}> cansado de:</strong></h2>
                                <div className="flex flex-col md:flex-row mb-12">
                                    <div className="flex items-center justify-start">
                                        <div className="left3column">
                                        {this.props.alternativeIcons
                                        ?
                                        <img className={`bg-red-100 ml-2 p-2 rounded-md`} alt="" src={newIcon2} />
                                        :
                                        <img className={`ml-6 p-2 h-30 w-20`} alt="" src={img9} />
                                    }
                                        </div>
    
                                        <div className="right3column">
                                            <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Ouvir: ‍“Volto amanhã para me matricular”</p>
    
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-start">
                                        <div className="left3column">
                                        {this.props.alternativeIcons
                                        ?
                                        <img className={`bg-red-100 ml-2 p-2 rounded-md`} alt="" src={newIcon3} />
                                        :
                                        <img className={`ml-6 p-2 h-30 w-20`} alt="" src={img6} />
                                    }
                                        </div>
    
                                        <div className="right3column">
                                            <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Não matricular logo no primeiro atendimento</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-start">
                                        <div className="left3column">
                                        {this.props.alternativeIcons
                                        ?
                                        <img className={`bg-red-100 ml-2 p-2 rounded-md`} alt="" src={newIcon4} />
                                        :
                                        <img className={`ml-6 p-2 h-30 w-20`} alt="" src={img5} />
                                    }
                                        </div>
    
                                        <div className="right3column">
                                            <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Tomar bolo e perder seu tempo</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <h2 className="text-opacity-90 font-sans text-center lg:text-left lg:p-0 p-6 lg:text-5xl text-4xl font-bold mb-12 mt-6">Se você <strong className={`text-custom-orange-default`}>odeia:</strong></h2>
                                <div className="flex flex-col md:flex-row mb-12">
                                    <div className="flex items-center justify-start">
                                        <div className="left2columns flex items-center justify-center">
                                        {this.props.alternativeIcons
                                        ?
                                        <img className={`bg-red-100 ml-2 p-2 rounded-md`} alt="" src={newIcon5} />
                                        :
                                        <img className={`ml-6 p-2 h-30 w-20`} alt="" src={img3} />
                                    }
                                        </div>
    
                                        <div className="right2columns">
                                            <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left lg:mr-4">Receber objeções atrás de objeções</p>
    
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-start">
                                        <div className="left2columns">
                                        {this.props.alternativeIcons
                                        ?
                                        <img className={`bg-red-100 ml-2 p-2 rounded-md`} alt="" src={newIcon6} />
                                        :
                                        <img className={`ml-6 p-2 h-30 w-20`} alt="" src={img2} />
                                    }
                                        </div>
    
                                        <div className="right2columns">
                                            <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Ligar para clientes antigos</p>
                                        </div>
    
                                    </div>
    
                                </div>
                            </div>
                            <div className="">
                                <h2 className="text-opacity-90 font-sans text-center lg:text-left lg:p-0 p-2 lg:text-5xl text-4xl font-bold mb-12 mt-6">Se você já <strong className={`text-custom-orange-default`}>não suporta mais:</strong></h2>
                                <div className="flex flex-col md:flex-row mb-12">
                                    <div className="flex items-center justify-start ">
                                        <div className="left3column">
                                        {this.props.alternativeIcons
                                        ?
                                        <img className={`bg-red-100 ml-2 p-2 rounded-md`} alt="" src={newIcon7} />
                                        :
                                        <img className={`ml-6 p-2 h-30 w-20`} alt="" src={img10} />
                                    }
                                        </div>
    
                                        <div className="right3column">
                                            <p className="text-opacity-90 font-sans text-xl font-semibold lg:p-0 p-6 mb-6 text-left ml-4">Cliente desligando na sua cara</p>
    
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-start">
                                        <div className="left3column">
                                        {this.props.alternativeIcons
                                        ?
                                        <img className={`bg-red-100 ml-2 p-2 rounded-md`} alt="" src={newIcon1} />
                                        :
                                        <img className={`ml-6 p-2 h-30 w-20`} alt="" src={img7} />
                                    }
                                        </div>
    
                                        <div className="right3column">
                                            <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Não bater suas metas por não ter leads</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-start">
                                        <div className="left3column">
                                        {this.props.alternativeIcons
                                        ?
                                        <img className={`bg-red-100 ml-2 p-2 rounded-md`} alt="" src={newIcon8} />
                                        :
                                        <img className={`ml-6 p-2 h-30 w-20`} alt="" src={img4} />
                                    }
                                        </div>
    
                                        <div className="right3column">
                                            <p className="text-opacity-90 font-sans ml-4 text-xl font-semibold lg:p-0 p-6 mb-6 text-left">Trabalhar finais de semanas e feriados</p>
                                        </div>
                                    </div>
    
                                </div>
                                <h2 className="text-opacity-90 font-sans text-center lg:text-left lg:p-0 p-2 lg:text-5xl text-4xl font-bold mb-12 mt-6">Então...</h2>
                            </div>
    
    
    
                        </div>
    
                    </div>
    
    
    
                    <div className="lg:p-8 lg:max-w-screen-lg lg:w-full pr-4 lg:pr-8">
                        <h2 className="opacity-90 font-sans text-center lg:text-center lg:p-0 p-2 lg:text-5xl text-4xl font-bold mb-12 mt-6">Matrícula Express é pra você!</h2>
                        <div className="lottieok mb-6"> <SuccessLottie /></div>
                        <p className="lg:-ml-5 lg:mr-5 opacity-90 font-sans ml-4 text-xl font-semibold p-4 mb-6 text-left border-2 w-auto">Serão 4 dias de curso totalmente gratuito e online com os seguintes temas:</p>
                        <p className="lg:-ml-3 lg:mr-3 opacity-90 font-sans ml-4 text-xl font-semibold p-4 mb-6 text-left border-2 w-auto">👉21/03: Agendamento Implacável: Você vai entender que os 10 segundos de ligação, determinam o bolo ou a matrícula. (Essa aula é especial pra quem não suporta mais tomar bolo 🤐)</p>
                        <p className="lg:-ml-2 lg:mr-2 opacity-90 font-sans ml-4 text-xl font-semibold p-4 mb-6 text-left border-2 w-auto">‍👉22/03: Leads infinitos: Descobrindo como ter leads infinitos e VIVER de indicações.</p>
                        <p className="lg:-ml-1 lg:mr1 opacity-90 font-sans ml-4 text-xl font-semibold p-4 mb-6 text-left border-2 w-auto">👉23/03: Fechamento Matriculador:  Os principais erros na hora de fechar uma matrícula e como colocar em prática a estratégia matriculadora.</p>
                        <p className="lg:-ml-0 opacity-90 font-sans ml-4 text-xl font-semibold p-4 mb-6 text-left border-2 w-auto">👉24/03: Montando Script: Entenda como funciona um Script de vendas que elimina as objeções na Prática.</p>
                    </div>
    
                    <button className='orange cursor-pointer min-h-20 lg:w-5/6 w-auto center rounded-lg m-auto flex items-center justify-center p-4 lg:pb-4 pb-6  mt-8 mb-8 shadow-lg' onClick={(e) => (window.location = '#vantablack')}>
                        <div className="flex items-center justify-end"><p className="obrigado-texts obrigado-paragraphs opacity-90 font-sans lg:text-4xl text-3xl font-bold p-6 mb-0 text-center">Quero Dobrar Minhas Matrículas!</p></div>
                    </button>
    
    {this.props.history.location.pathname !== "/mex" && 
        <div>
        <h2 className="opacity-90 font-sans text-center lg:text-center lg:p-0 p-2 lg:text-5xl text-4xl font-bold mb-12 mt-6">O que os participantes dizem do treinamento</h2>
            <Testimonials type={"mex2"} />
            
        </div>
    }
</>
}
                <div className={`w-full`}>
                    <QuemSouEu img={Higor1}/>
                    {/* <div className="title-who-we-are mt-12 mb-24 p-4">

                        <h2 className="opacity-90 font-sans text-center lg:text-left lg:p-0 p-6 lg:text-5xl text-4xl font-bold mb-12 mt-6">Quem é seu treinador?</h2>

                        <div className="higor-image-plus-text-wrapper flex lg:flex-row flex-col items-center justify-center max-w-screen-lg">

                            <div className="higorimage w-60 orange flex flex-col items-center justify-center rounded-2xl p-2 mr-6 lg:mb-0 mb-6">
                                <img className="w-60 h-auto rounded-2xl" src={higor} alt="Higor Fabiano" />
                                <div className="pwrwapper w-full">
                                    <p className="text-white text-right font-sans text-xl font-thin pt-2">Higor Fabiano</p>
                                </div>
                                <div className="pwrwapper w-full">
                                    <p className="text-white text-right font-sans text-xl font-thin pt-2">O Matriculador</p>
                                </div>
                            </div>
                            <div className="who-we-are-text lg:w-3/4 orange text-white rounded-xl p-6 font-sans text-lg">
                                <p className="mb-6">E ai!!! Eu sou Higor Fabiano, o fundador da Escola de Matrículas, e eu também fico 1 hora ouvindo lamentações, alegrias, sendo um psicólogo/amigo e às vezes consultor. E foi assim que entendi o poder que tinha nas mãos: Transformar a vida das pessoas, e a minha, por meio da Educação. Foi no dia que entendi isso que decidi largar o Direito e me profissionalizar, me tornando um <strong>"Matriculador"</strong>
                                </p>

                                <p>
                                Sempre digo que cada matrícula que faço é um tijolinho a mais no meu objetivo. Se não fossem as matrículas eu não estaria onde estou hoje. Se você está aqui é porque também sabe o valor da matrícula, tanto para o próximo quanto para você.</p>

                            </div>

                        </div>

                    </div> */}
                </div>

<div className={`w-full h-auto p-2 flex items-center justify-center bg-custom-black-default`}>
    <Link to="/termosdeusoeprivacidade" > <p className={`text-gray-200  font-sans`}>Termos de Uso & Privacidade</p> </Link>
</div>




                {/*End of component*/}
            </div>




        )
    }
}

export default MatriculaExpress

