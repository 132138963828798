import React from 'react'
import Interfa from './components/Interfa/Interfa'
import ExampleCard from './components/VideoWithInteractions/ExampleCard'

export default function ClassVideoWithInteraction() {

  let eventsArray = [
    {timeInSeconds: 5, element:<ExampleCard/>},
    {timeInSeconds: 15, element:<ExampleCard/>},
    {timeInSeconds: 25, element:<ExampleCard/>}
   ]

  return (
    <div className={`bg-black min-h-screen flex items-center justify-center`}>
      {/* <Interfa
       videoURL={'https://www.youtube.com/watch?v=ysz5S6PUM-U'}
       events={eventsArray} 
      />       */}

      <Interfa
       videoURL={'https://www.youtube.com/watch?v=0gJCnGpTIzA'}
       events={eventsArray} 
      />      


    </div>

  )
}
