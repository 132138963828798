import React, {useRef, useState, useEffect} from 'react'
import gsap from 'gsap'
import Button from '@material-ui/core/Button'

export default function FixedFeedBackAnimateLines(props) {
    const topLine = useRef(null)
    const rightLine = useRef(null)
    const bottomLine = useRef(null)
    const leftLine = useRef(null)
 
    const cn = {            
        topLine: `fixed  transform h-px w-24 top-2 right-2 bg-custom-orange-default`,
        rightLine:`fixed  transform h-24 w-px top-2 right-2 bg-custom-orange-default`,
        bottomLine:`fixed  transform h-px w-24 bottom-2 left-2 bg-custom-orange-default`,
        leftLine:`fixed  transform h-24 w-px bottom-2 left-2 bg-custom-orange-default`,
    }
    const [windowSizes, setwindowSizes] = useState({
        height: window.innerHeight,
        width: window.innerWidth 
    })

    useEffect(() => {      
        const listenToWindowResize = (e) => {       
            tl.current.kill()    
            console.log(e.target.visualViewport.width);       
            console.log(e.target.visualViewport.height);     
            setwindowSizes(
                (i) => ({
                    ...i,
                    height:e.target.visualViewport.height,
                    width:e.target.visualViewport.width
                })
            )    
        }
        window.addEventListener(`resize`,listenToWindowResize)

        return () => {
            window.addEventListener(`resize`,listenToWindowResize)
        }
       }, [])

       

    const tl = useRef(gsap.timeline({repeat:0, paused:true}))

    const animationLinesDuration = 0.8 
    const animationLinesEase = "power3.inOut"  
   
    useEffect(() => {        
        tl.current.to(topLine.current, animationLinesDuration,{
            x: -windowSizes.width + topLine.current.offsetWidth,
            ease: animationLinesEase,
        })    
        .to(leftLine.current,  animationLinesDuration,{
            y: -windowSizes.height + leftLine.current.offsetHeight,
            ease: animationLinesEase,
            delay:- animationLinesDuration,
        })
        .to(bottomLine.current,  animationLinesDuration,{
            x: windowSizes.width - bottomLine.current.offsetWidth,
            ease: animationLinesEase,
            delay:- animationLinesDuration,
        })               
        .to(rightLine.current,  animationLinesDuration,{
            y: windowSizes.height - rightLine.current.offsetHeight,
            ease: animationLinesEase,
            delay:- animationLinesDuration, 
        })  
    }, [tl])    
    

    useEffect(() => {

        if (props.fixedAnimationState === true) {
            tl.current.play()                 
        }  

        if (props.fixedAnimationState === false) {
            tl.current.reverse()              
        }

    }, [props.fixedAnimationState])

    return (
        <div>           
            <div className={cn.topLine} ref={topLine} ></div>
            <div className={cn.rightLine} ref={rightLine}></div>
            <div className={cn.bottomLine} ref={bottomLine}></div>
            <div className={cn.leftLine} ref={leftLine}></div>
            
            
        </div>
    )
}
