import React, {useContext, useState, useEffect} from 'react'
import firebase, { auth , GoogleProvider, db  } from '../Firestore'

const AuthContext = React.createContext()

export function useAuth(){
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
      
    const [isAuthLoading, setIsAuthLoading] = useState(true) 
    const [isDbUserLoading, setIsDbUserLoading] = useState(true)
    const [currentUser, setCurrentUser] = useState('')
    const [currentUserInDb, setCurrentUserInDb] = useState({
        dataFromGet:{},
        toApplyMethodOn: null,
    })    
    const [userIdToken, setUserIdToken] = useState(null)

    //Simplification form of the database data that is respective to this user auth account
   
    const dbUser = currentUserInDb.dataFromGet 

    const logout = () => {
        return  auth.signOut()
    }
    
    const signup = (email,password) => {
        return  auth.createUserWithEmailAndPassword(email,password)
    }

    const receiveCurrentUser = () => {
        return auth.currentUser
    }
    
    const login = (email,password, method) => {
        if (method==='google') {
        return auth.signInWithPopup(GoogleProvider)
            
        }
        if (method==='direct'){
        return  auth.signInWithEmailAndPassword(email,password)
       }
    }
    
    const getAndStoreUserIdToken = async () => {
        await receiveCurrentUser()
        .getIdToken(true)
        .then((doc) => {setUserIdToken(doc)})
        .catch((err) => {console.log(err);})
    }


    const userDbUpdater = async (user) => {
        setIsDbUserLoading(true)
        if (user !== null && user !== undefined ) {
            await db.collection('users').doc(user.uid)
            .get()
            .then(doc => {
                setCurrentUserInDb(i=>({...i,dataFromGet: doc.data(), toApplyMethodOn: db.collection('users').doc(user.uid)}))  
                setIsDbUserLoading(false)
            })
            .catch((err) => {
                setIsDbUserLoading(false)
                console.log(err);
            })    
        }              
       }
    
    const value ={
        currentUser,
        isAuthLoading,
        isDbUserLoading,
        currentUserInDb,
        userIdToken,
        setUserIdToken,
        //Simplification form of the database data that is respective to this user auth account
        dbUser,
        //Generates UserIdToken to be used to be sent to backend for validation and store in userIdToken context state  
        getAndStoreUserIdToken,        
        userDbUpdater,
        receiveCurrentUser,
        logout,
        signup,
        login, 
    }
 
    useEffect(() => {      
       setIsAuthLoading(true)  

       const unsubscribe = auth.onAuthStateChanged(user =>{          
          setCurrentUser(user)    
          setIsAuthLoading(false)                 
          userDbUpdater(user)      
          if (receiveCurrentUser() !== null) {
            getAndStoreUserIdToken()
          } else{
            setUserIdToken(null)
          }                
       })

        return () => {
            return unsubscribe
        }
    }, [])


 

    return (
        <AuthContext.Provider value={value}>
        <div>           
            {children}            
        </div>
        </AuthContext.Provider>
    )
}
