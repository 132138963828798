
import React, {useEffect, useState, useRef} from 'react'
import gsap from 'gsap'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button'
import MoneyFlags from '../../images/img-55398-20160317094438-1.png'
import HigorBg from '../../images/FotoHigor (1).png'

export default function HeroSection() {

        // Material Ui Styling
        const useStyles = makeStyles( (theme)=>( {
        opacityZero:{
            opacity:'0',
            fontFamily: 'Raleway, Arial',
            marginTop: '15px',   
            backgroundColor:'#ff6a00bb',     
            padding: '8px',
            paddingLeft: '20px',
            paddingRight: '20px',   
            color:'#ffffff',
            [theme.breakpoints.down('sm')]:{
               fontSize: '1.8em'
            },


        },
        upperCase:{
            textTransform: "uppercase"
        }

        }))
    
        const classes = useStyles();
        // End of Material Ui Styling
    

    const arrayTexts = [
        "Mesmo sem ter uma equipe de vendas!",
        "Mesmo sem utilizar o whatsapp!",
        "Em apenas 4 dias!",
        "Sem receber objeções!",
    ]
     

    const refShapeSquareOne = useRef(null)
    const refShapeSquareTwo = useRef(null)
    const refDiagonalLineOne = useRef(null)
    const refDiagonalLineTwo = useRef(null)
    const refTextThatChangesOne = useRef(null)
    const refTextThatChangesTwo = useRef(null)
    const refTextThatChangesThree = useRef(null)
    const refTextThatChangesFour = useRef(null)


    // const ref = useRef(null)

    useEffect(() => {

        let tl = gsap.timeline()
        let tlTextsThatChange = gsap.timeline({repeat:-1})
        

        tlTextsThatChange.fromTo(refTextThatChangesOne.current, 1, {
            y: '30',        
            ease: 'sine.inOut',      
        },        {
            y: '0', 
           opacity: '100%',   
           scale: 1,
        }) 
        tlTextsThatChange.to(refTextThatChangesOne.current, 1, {
            opacity: "1",
            ease: 'sine.inOut',      
        }, "<") 

        
        tlTextsThatChange.fromTo(refTextThatChangesOne.current, 1, {
            y: '0',    
            ease: 'sine.inOut',      
        },        {
            y: '30', 
           scale: 1,  
           delay: 1.4, 
        }) 

        tlTextsThatChange.to(refTextThatChangesOne.current, 1, {
            opacity: "0",
            ease: 'sine.inOut',      
        }, "<") 

        
        tlTextsThatChange.fromTo(refTextThatChangesTwo.current, 1, {
            y: '30',  
            ease: 'sine.inOut',      
        },        {
            y: '0',  
           scale: 1,
        }) 
        tlTextsThatChange.to(refTextThatChangesTwo.current, 1, {
            opacity: "1",
            ease: 'sine.inOut',      
        }, "<") 

  
        
        tlTextsThatChange.fromTo(refTextThatChangesTwo.current, 1, {
            y: '0',    
            ease: 'sine.inOut',      
        },        {
            y: '30', 
           scale: 1,  
           delay: 1.4, 
        }) 

        tlTextsThatChange.to(refTextThatChangesTwo.current, 1, {
            opacity: "0",
            ease: 'sine.inOut',      
        }, "<") 

        tlTextsThatChange.fromTo(refTextThatChangesThree.current, 1, {
            y: '30',   
            ease: 'sine.inOut',      
        },        {
            y: '0', 
           scale: 1,
        }) 

        tlTextsThatChange.to(refTextThatChangesThree.current, 1, {
            opacity: "1",
            ease: 'sine.inOut',      
        }, "<") 
        
        tlTextsThatChange.fromTo(refTextThatChangesThree.current, 1, {
            y: '0',     
            ease: 'sine.inOut',      
        },        {
            y: '30', 
           scale: 1,  
           delay: 1.4, 
        }) 

        tlTextsThatChange.to(refTextThatChangesThree.current, 1, {
            opacity: "0",
            ease: 'sine.inOut',      
        }, "<") 

        
        tlTextsThatChange.fromTo(refTextThatChangesFour.current, 1, {
            y: '30',     
            ease: 'sine.inOut',      
        },        {
            y: '0', 
           scale: 1,
        }) 

        tlTextsThatChange.to(refTextThatChangesFour.current, 1, {
            opacity: "1",
            ease: 'sine.inOut',      
        }, "<") 
        
        tlTextsThatChange.fromTo(refTextThatChangesFour.current, 1, {
            y: '0',  
            ease: 'sine.inOut',      
        },        {
            y: '30', 
           scale: 1,  
           delay: 1.4, 
        }) 

        tlTextsThatChange.to(refTextThatChangesFour.current, 1, {
            opacity: "0",
            ease: 'sine.inOut',      
        }, "<") 
        
        
        tl.to(refShapeSquareOne.current, 2, {
            y: '25%',        
            ease: 'sine.inOut', 
            yoyo: true,
            repeat:-1,

        })        

        tl.to(refShapeSquareTwo.current, 4, {
            y: '25%',        
            ease: 'sine.inOut', 
            yoyo: true,
            repeat:-1,

        }, "<")

         tl.to(refDiagonalLineOne.current, 2, {
            y: '-600px', 
            x: '600px',       
            ease: 'sine.inOut',             
            repeat:-1,

        }, "<")
        tl.to(refDiagonalLineTwo.current, 1, {
            y: '-1000px', 
            x: '1000px',       
            ease: 'sine.inOut',             
            repeat:-1,
            delay: 1,

        }, "<")

        return () => {
            tl.kill()
    }}, [])

    return (
        <div className="relative w-full min-h-screen h-full overflow-hidden md:p-8 p-4">      

            <div className={`absolute top-0 -right-0 z-10`}>
                 <img src={HigorBg} alt="" /> 
            </div>

            <div ref={refShapeSquareTwo} className="absolute rounded-md opacity-20 md:h-96 h-44 md:w-96 w-44 bg-custom-orange-default -bottom-0 right-20 z-10"></div>    
            <div ref={refShapeSquareOne} className="absolute rounded-md md:h-96 h-44 md:w-96 w-44 bg-custom-black-light -bottom-10 right-0 z-10"></div>

            <div ref={refDiagonalLineOne} className="absolute top-96 -left-96 opacity-80 rounded-lg transform -rotate-45 h-2 w-96 bg-custom-orange-default z-0"></div>
            <div ref={refDiagonalLineTwo} style={{top: '100%', left:'-400px'}} className="absolute rounded-lg opacity-80 transform -rotate-45 h-2 w-96 bg-custom-orange-default z-0"></div>
    
            <div className="flex relative flex-col md:w-1/2 items-center justify-center md:mt-6 mt-52 p-4 bg-custom-black-light bg-opacity-60 z-20">
               <div className="text-white">
                <Typography className={classes.upperCase} align="center" variant="h4">
                Como <strong className="text-custom-orange-default">dobrar</strong> o número de alunos <strong className="text-custom-orange-default">em&nbsp;seu&nbsp;curso</strong> 
                </Typography>
                </div>

                <div className="div absolute-organizer relative h-auto w-full">

                <div className="absolute flex items-center justify-center w-full">
                        <Typography ref={refTextThatChangesOne} className={classes.opacityZero}  align="center" variant="h4"   >
                        {arrayTexts[0].toUpperCase()}
                        </Typography>
                </div>
            
                <div className="absolute  flex items-center justify-center  w-full ">
                            <Typography ref={refTextThatChangesTwo} className={classes.opacityZero}   align="center" variant="h4"  >
                            {arrayTexts[1].toUpperCase()}
                            </Typography>
                </div>

                <div className="absolute flex items-center justify-center  w-full">
                            <Typography ref={refTextThatChangesThree} className={classes.opacityZero}   align="center" variant="h4"   >
                            {arrayTexts[2].toUpperCase()}
                            </Typography>
                </div>
                <div className="absolute flex items-center justify-center  w-full">
                            <Typography ref={refTextThatChangesFour} className={classes.opacityZero}   align="center" variant="h4" >
                            {arrayTexts[3].toUpperCase()}
                            </Typography>
                </div>

                </div>
            </div>
     
            <div className="div flex relative md:mt-40 mt-48 w-full h-auto flex-col  md:flex-row items-stretch justify-stretch z-20 border-2 border-custom-orange-default p-2 ">
                <div className="left-w flex-1 md:w-1/2 p-4">
                    <p className="text-gray-200 font-bold text-2xl mb-5">Veja:</p>
                    <p  className="text-gray-400 text-xl mb-2"> {`>`} O que as grandes escolas fazem para matricular no primeiro Atendimento</p>
                    <p  className="text-gray-400 text-xl mb-2"> {`>`} Os segredos para você matricular 4 pessoas a cada 5 atendimentos</p>
                    <p  className="text-gray-400 text-xl mb-2"> {`>`} O que os Donos de Grandes Redes de Ensino fazem para matricular todo dia que eles  não te ensinam</p>

                </div>
                <div className="right-w flex-1  md:w-1/2 p-4">
               
                    <p className="text-lg text-gray-200 mb-4">Assista ao Vídeo para entender como funciona o método!</p>
                    <div className="div h-64 w-full flex items-center justify-center bg-blue-800">
                        Video
                    </div>   
                         
                </div>

         
          
            </div>
            <div className="relative z-20  mt-4 w-full">
            <Button fullWidth="true" size="large" variant="contained" color="secondary">
                    <div className="text-white flex items-center justify-center w-full">
                    Quero Dobrar o número de alunos! 
                    </div>                         
            </Button>

            <div className="mt-4 flex flex-col items-center justify-center div w-full bg-custom-black-default p-2">
                <p className="text-center md:text-2xl text-xl p-4 text-gray-200">Pagamento <strong className="text-custom-orange-default">100%</strong> Seguro</p>
                <div className="overflow-y-hidden h-24 flex items-center justify-center">
                <img className="opacity-50" src={MoneyFlags} alt="Aceitamos Visa, Master Card, American Express, Elo e Hypercard como formas de pagamento." /> 
                </div>
               

            </div>
            </div>   
          
        </div>
    )
}
