import React, { useRef } from 'react'
import {functions} from '../../Firestore';
import { useAuth } from '../../context/AuthContext';
import {useLoadingContext} from '../../context/LoadingContext'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { useSnack } from '../../context/SnackContext';
import TextField from '@material-ui/core/TextField'
import { SignalCellularNullOutlined } from '@material-ui/icons';




export default function UserDashboard() {

    const {currentUser} = useAuth()
    const {history} = useHistory()
    const {handleSnack} = useSnack()
    const {shouldLoad,setshouldLoad} = useLoadingContext()
    const handleTicketCreation = functions.httpsCallable('handleTicketCreation')

    const dataForTicketCreationInputedByTheUser = useRef({
        title: null,
        subject:  null,
        message: null    
    })

    const handleDataCheckingForInputProblems = () => {
        if (dataForTicketCreationInputedByTheUser.current.title === SignalCellularNullOutlined) {
            handleSnack('warning', 'Por favor preencha o ticket com título!')          
            return false
        }       
        else {  
            return true
        }
    }

    const handleCreateTicket =  async () => {

        setshouldLoad(true)  

         if (handleDataCheckingForInputProblems()) {

                
                await handleTicketCreation({                  
                    currentUser: JSON.stringify(currentUser),
                    title: dataForTicketCreationInputedByTheUser.current.title,
                    subject: dataForTicketCreationInputedByTheUser.current.subject,
                    message: dataForTicketCreationInputedByTheUser.current.message
                }).then((doc) => {
                    handleSnack('success', 'Ticket criado com sucesso!')  
                    setshouldLoad(false)  
                    console.log(doc)
                })
                .catch((err) => {
                    console.log(err)
                    handleSnack('error', 'Confira sua conexão ou tente mais tarde.')               
                    setshouldLoad(false)                      
                })

        } else {
            setshouldLoad(false)  
        } 
         
       
    }

    return ( 
        <div className={`flex flex-col`}>
            userdashboard 

        <div className={`flex flex-col gap-2`}>
                <TextField            
                variant="outlined"            
                type="text"            
                label="Titulo"            
                onChange={(e)=>{     
                    dataForTicketCreationInputedByTheUser.current.title = e.target.value  
                }}
                
                />
    
                <TextField            
                variant="outlined"            
                type="text"            
                label="Assunto"            
                onChange={(e)=>{    
                    dataForTicketCreationInputedByTheUser.current.subject = e.target.value     
                }}
                
                />
    
                <TextField            
                variant="outlined"            
                type="text"            
                label="Mensagem"  
                multiline={true}
                rows={8}          
                onChange={(e)=>{       
                    dataForTicketCreationInputedByTheUser.current.message = e.target.value     
                }}
                
                />
        </div>
           
           
            <Button  
               disabled={shouldLoad}
               onClick={() => {handleCreateTicket()}}
                 variant="contained" color="primary">
                 <span className={`text-white`}>CRIAR TICKET</span>
            </Button>
        
    
        </div>
    )
}
