import React, { useState } from 'react'
import SinglePostPreview from '../SinglePostPreview/SinglePostPreview.jsx'

export default function PostPreviewDisplayer() {

    const [postPreviewArray, setpostPreviewArray] = useState([
        {
            title: 'lorem title',
            date: '25/12/1992',
            tags: [1,2,3],
            categories: [1,2,3,4,5],
            description: 'lorem description',
            postThumbnail: 'postThumbnail',
            author: 'author',
            authorImage: 'authorImage',
            linkToRespectiveSinglePost: 'linkToRespectiveSinglePost1'
        },
        {
            title: 'lorem title',
            date: '25/12/1992',
            tags: [1,2,3],
            categories: [1,2,3,4,5],
            description: 'lorem description',
            postThumbnail: 'postThumbnail',
            author: 'author',
            authorImage: 'authorImage',
            linkToRespectiveSinglePost: 'linkToRespectiveSinglePost2'
        },
        {
            title: 'lorem title',
            date: '25/12/1992',
            tags: [1,2,3],
            categories: [1,2,3,4,5],
            description: 'lorem description',
            postThumbnail: 'postThumbnail',
            author: 'author',
            authorImage: 'authorImage',
            linkToRespectiveSinglePost: 'linkToRespectiveSinglePost3'
        }          
    ])

    return (
        <div>   
                <div className={`grid grid-cols-2 gap-4`}>
                            {
                                postPreviewArray.map((element) => {
                                    return(                    
                                        <SinglePostPreview 
                                        title={element.title}
                                        date={element.date}
                                        tags={element.tags}
                                        categories={element.categories}
                                        description={element.description}
                                        postThumbnail={element.postThumbnail}
                                        author={element.author}
                                        authorImage={element.authorImage}
                                        linkToRespectiveSinglePost={element.linkToRespectiveSinglePost}
                                        />
                                    )
                                })
                            }
                </div>
        </div>
    )
}
