import React, { Component } from 'react'

export default class NoticeToUserComponent extends Component {


componentDidMount() {
    if (this.props.Tutor){
        let TutorMessage = 'Tutor was set to true on NoticeToUserComponent! Check your console.log for a small walkthrough on how to use this component!'
        alert(TutorMessage);
        console.log(TutorMessage);
  
    }
 
}

componentWillUnmount() {

}

    render() {

        /* Custom Tagging Section
        Here is the code that allow each tag from the pieces of text to be exchanged by what you want.
        Like h1, h2, p or any other.
        */

        let CustomText1Tag = 'p';
        let CustomLabelTextTag = 'p';
        let Text1 = 'Pleae fill the "Text1" props on this component!'
        let LabelText = 'Pleae fill the "LabelText" props on this component!' 
        let LeftBackgroundColor = 'bg-blue-300';    
        let Text1Color = 'text-blue-800';   
        let LabelTextColor = 'text-blue-300';   
        let Button1BackgroundColor = 'bg-blue-800';
        let Button1TextColor = 'text-gray-200';

        if (this.props.Text1Tag !== undefined) {
           CustomText1Tag = `${this.props.Text1Tag}`   
        } 

        if (this.props.LabelTextTag !== undefined) {
            CustomLabelTextTag = `${this.props.LabelTextTag}`   
         } 

        if (this.props.LeftDivBackgroundColor !== undefined){
            LeftBackgroundColor = this.props.LeftDivBackgroundColor
        }

        if (this.props.Text1 !== undefined){
            Text1 = this.props.Text1 
        }


        if (this.props.LabelText !== undefined){
            LabelText = this.props.LabelText 
        }


        if (this.props.PreworkedColors === 'redNotice'){
            LeftBackgroundColor = 'bg-red-300'
            Text1Color = 'text-red-800'
            LabelTextColor ='text-red-300'
            Button1BackgroundColor = 'bg-red-800'
            Button1TextColor = 'text-gray-200'
        } 
        
        if (this.props.PreworkedColors  === 'yellowNotice'){
            LeftBackgroundColor = 'bg-yellow-200'
            Text1Color = 'text-yellow-600'
            LabelTextColor ='text-yellow-200'
            Button1BackgroundColor = 'bg-yellow-600'
            Button1TextColor = 'text-gray-200'
        }

        if (this.props.PreworkedColors  === 'greenNotice'){
            LeftBackgroundColor = 'bg-green-300'
            Text1Color = 'text-green-800'
            LabelTextColor ='text-green-300'
            Button1BackgroundColor = 'bg-green-800'
            Button1TextColor = 'text-gray-200'
        }


        /* End of Custom Tagging Section*/


        return (
            <div className="notice-to-user-component-w w-full h-auto ">

                <div className={`               
                w-full
                h-auto                
                `}>

                    <div className={`
                    leftright-w
                    flex
                    items-stretch
                    justify-stretch
                    flex-1     
                    border-solid    
                    rounded-md  
                    w-full                            
                    overflow-hidden
                    ${this.props.RowReverse && 'flex-row-reverse'}
                    `}>

                              
                                <div className={`left ${LeftBackgroundColor} w-full flex  items-center justify-start`}>
                                        <div className={`Text-lg ${Text1Color} p-4 `}>
                                                                                          
                                            { this.props.LabelExist && <CustomLabelTextTag className={`text-sm mb-2 pl-2 pr-2 p-1 ${LabelTextColor} ${Button1BackgroundColor} rounded-lg w-auto inline-block`}>{LabelText}</CustomLabelTextTag>}
                                          
                                            <CustomText1Tag>{Text1}</CustomText1Tag>
                                        </div>   
                                </div>
                                { this.props.ButtonSideExist &&
                                <div onClick={()=>{
                                    if (this.props.ButtonOnClick !== undefined) {
                                        this.props.ButtonOnClick()  
                                    } else {
                                        let errorMsg='this.props.ButtonOnClick() is returning undefined! Update the component to pass the props correctly so the button can work!';
                                        console.log(errorMsg);
                                        alert(errorMsg)
                                    }
                                       
                                   
                                    }} className={`right ${Button1BackgroundColor} cursor-pointer p-5 flex items-center justify-center`}>
                                        <p className={`${Button1TextColor}`}>X</p>
                                </div>
                                }

                    </div>

                </div>
                
            </div>
        )
    }
}
