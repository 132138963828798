import React from 'react'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/styles';

export default function ParaQuemSeDestina() {

    // Material Ui Styling
    const useStyles = makeStyles( (theme)=>({
        checkBoxIcon:{
            height: '40px',
            width: '40px',
            marginRight: '8px',
            color: theme.palette.primary.main,

        }
    }))

    const classes = useStyles();
    // End of Material Ui Styling


    const checkboxIconPlusTextTailWindStyle = 'text-gray-200 text-2xl flex items-center justify-center'


    return (
        <div className={`pb-16 pt-16 md:pt-0 flex items-center justify-center flex-col`}>
            <h1 className={`text-center items-center text-gray-200 md:text-4xl text-3xl font-sans font-bold md:pt-16 pt-8 pb-8 `}>PARA QUEM SE DESTINA ESSE TREINAMENTO <strong className={`text-custom-orange-default`}>EXCLUSIVO?</strong></h1>

            <div className="flex flex-col items-center justify-center max-w-lg">
     <div className={checkboxIconPlusTextTailWindStyle}>
                <CheckBoxIcon className={classes.checkBoxIcon} />
                <p>Para você que é Matriculador e deseja fazer o dobro de matrículas;</p>
     </div>
     <div className={checkboxIconPlusTextTailWindStyle}>
                <CheckBoxIcon className={classes.checkBoxIcon}/>
                <p>Para você que é de um curso de Idiomas e Profissionalizantes;</p>
     </div>
     <div className={checkboxIconPlusTextTailWindStyle}>
                <CheckBoxIcon className={classes.checkBoxIcon}/>
                <p>Para você que é de Curso Técnico e Tecnólogos;</p>
     </div>
     <div className={checkboxIconPlusTextTailWindStyle}>
                <CheckBoxIcon className={classes.checkBoxIcon}/>
                <p>Para você que é de Graduação, Pós ou MBA;</p>
     </div>
            </div>

        </div>
    )
}
