import React, {useContext, useState} from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const SnackContext = React.createContext()

export function useSnack(){
    return useContext(SnackContext)
}


export function SnackProvider(props) {

        const [snack, setSnack] = useState({content:null, type:null})

        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }      

        const handleSnack = (type,content) =>{
            setSnack({type, content})
       }
      
        const handleCloseSnack = (event, reason) => {
          if (reason === 'clickaway') {
            return;
          }      
          setSnack({content:null, type:null});
        };

        const value ={
            Alert,
            handleCloseSnack,
            handleSnack,
            snack,
        }

    return (
        <SnackContext.Provider value={value}>
        <div>
            <Snackbar anchorOrigin={{vertical:'top', horizontal:'right'}} open={snack.type !== null} autoHideDuration={6000} onClose={handleCloseSnack}>
            {snack.type !== null && <Alert onClose={handleCloseSnack} severity={snack.type}> { snack.content.message === null || snack.content.message === undefined ? snack.content : snack.content.message} </Alert> }                                     
            </Snackbar>    

            {props.children}            
        </div>
        </SnackContext.Provider>
    )
}
