import React, {useEffect,useRef} from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

//Each element should also contain an entrance animation
//Each entrance animation should also have customisable delay

function Corner1(props) {
   

    const square1 = useRef(null)
    const square2 = useRef(null)
  

    useEffect(() => {    
        const tl = gsap.timeline({delay: props.entranceDelay,  scrollTrigger:square1.current}) 
        tl.fromTo(square1.current, 1,{
            opacity:0, 
            y:0          
        },
        {
            opacity:1,
            y:20,   
            ease:"power2.inOut",  
            
        })
        .fromTo(square2.current, 1,{
            opacity:0, 
            y:0          
        },
        {
            opacity:1,
            y:20,      
            ease:"power2.inOut",              
        }, '-=0.8')
        
    }, [])

    return  ( 
    <div className={`relative w-24 h-24`}>
        <div ref={square1} className={`w-24 h-24 opacity-0 border border-custom-orange-default`}>

        </div>    
        <div  ref={square2} className={`w-24 h-24 opacity-0 transform translate-x-1/2 border border-custom-orange-default absolute -bottom-1/2 right-0`}>
        </div>            
    </div>  
    )
}

function Corner2(props) {
    const square1 = useRef(null)
    const square2 = useRef(null)
    useEffect(() => {    
        const tl = gsap.timeline({delay: props.entranceDelay,  scrollTrigger:square1.current}) 
        tl.fromTo(square2.current, 1,{
            opacity:0, 
            y:0          
        },
        {
            opacity:1,
            y:20,   
            ease:"power2.inOut",  
            
        })
        .fromTo(square1.current, 1,{
            opacity:0, 
            y:0          
        },
        {
            opacity:1,
            y:20,      
            ease:"power2.inOut",              
        }, '-=0.8')
        
    }, [])

    return  ( 
    <div className={`relative w-24 h-24`}>
        <div ref={square2} className={`w-24 h-24 bg-custom-orange-default`}>

        </div>    
        <div ref={square1} className={`w-24 h-24 border transform translate-x-1/2  bg-custom-orange-default absolute -bottom-1/2 right-0`}>
        </div>            
    </div>  
    )
}

function Background1(props){
    return(
        <div>

        </div>
    )
}

function Background2(props){
    return(
        <div>

        </div>
    )
}

function Division1(props){
    return(
        <div>

        </div>
    )
}

function Division2(props){
    return(
        <div>

        </div>
    )
}

function Floater1(props){

    const box = useRef(null)

    useEffect(() => {
        const tl = gsap.timeline({delay: props.entranceDelay, scrollTrigger: box.current})
        const tlFloat = gsap.timeline({paused:true})
        
        tlFloat.to(box.current,1,{
            y:-20,
            repeat: -1,
            yoyo:true,
            ease: 'sine.inOut'
        })
        
        tl.fromTo(box.current, 1,{
            opacity:0,
            y:-20
        },
        {
           opacity:1,
           y:0,
           onComplete: () => {tlFloat.play()},
        }
        )
      
    }, [])

    return(
        <div ref={box} className={`w-64 h-64 border border-red-800`}>

        </div>
    )
}

function Floater2(props){
    return(
        <div>

        </div>
    )
}

function Pattern1(props){
    return(
        <div>

        </div>
    )
}
function Pattern2(props){
    return(
        <div>

        </div>
    )
}

export default function Artistic(props) {
    gsap.registerPlugin(ScrollTrigger);
    return (
        <div>
            {props.template === 'corner1' && 
            <Corner1 entranceDelay={props.entranceDelay}/>
            }

            {props.template === 'corner2' && 
            <Corner2 entranceDelay={props.entranceDelay}/>
            }

            {props.template === 'background1' && 
            <Background1 entranceDelay={props.entranceDelay}/>
            }

            {props.template === 'background2' && 
            <Background2 entranceDelay={props.entranceDelay}/>
            }
            
            {props.template === 'division1' && 
            <Division1 entranceDelay={props.entranceDelay}/>
            }

            {props.template === 'division2' && 
            <Division2 entranceDelay={props.entranceDelay}/>
            }

            {props.template === 'floater1' && 
            <Floater1 entranceDelay={props.entranceDelay}/>
            }

            {props.template === 'floater2' && 
            <Floater2 entranceDelay={props.entranceDelay}/>
            }

            {props.template === 'pattern1' && 
            <Pattern1 entranceDelay={props.entranceDelay}/>
            }
            {props.template === 'pattern2' && 
            <Pattern2 entranceDelay={props.entranceDelay}/>
            }
            

        </div>
    )
}
