import React from "react";
import { makeStyles} from "@material-ui/styles";	
import { createMuiTheme, ThemeProvider  } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
  
  inputLabel:{
  color: '#E5E7EB',  
},
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    minWidth: "300px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));



  
const themeSelect = createMuiTheme({
    overrides: {
      // Style sheet name ⚛️

      MuiInputBase:{
          root:{
              color:'#b0b0b0'
          },          
            '&$underline:after' : {
                borderBottom: "red"
                
    
            }
         
      
      },

      MuiFormLabel:{
          root:{
            
              }                       
          },              
 
      MuiInputLabel: {    
          root:{
              '&$focused':{
                  color:'white',        
              },
          color: 'white',                  
        } , 
        },
    },
  });

export default function NativeSelects(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: ""
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value
    });
    props.callBackDropDown(event.target.value);


    if (event.target.value === 'Problemas abc') {

        MySwal.fire({
            icon: 'warning',
            title: <p>A um problema já em resolução envolvendo pagamentos!</p>,
            text: 'Devido a crise da mastercard estamos verificando os pagamentos de forma manual e portanto as aulas foram atrasadas em 1',
            confirmButtonText: 'Entendo e estou ciente! ',
            didOpen: () => {
              // `MySwal` is a subclass of `Swal`
              //   with all the same instance & static methods
           
            }
          }).then(() => {
            return MySwal.fire({                
                icon: 'success',
                title: <p>Muito bem!</p>,
                text: 'Por favor continue para o envio do seu ticket!',
    
            }
            )
            
          })
        }

    if (event.target.value === 'Turma 3') {

        MySwal.fire({
            icon: 'warning',
            title: <p>A um problema já avisado para esta turma!</p>,
            text: 'Devido a grande quantidade de alunos a entrega do material foi atrasada para o dia 13!',
            confirmButtonText: 'Entendo e estou ciente! ',
            didOpen: () => {
              // `MySwal` is a subclass of `Swal`
              //   with all the same instance & static methods
           
            }
          }).then(() => {
            return MySwal.fire({                
                icon: 'success',
                title: <p>Muito bem!</p>,
                text: 'Por favor continue para o envio do seu ticket!',

            }
            )
            
          })
    } 
    

    
  };

 



  return (
    <div>
          <ThemeProvider theme={themeSelect}>     
      <FormControl className={classes.formControl}>

        <InputLabel className={classes.inputLabel} htmlFor="age-native-helper">{props.label}</InputLabel>
        <NativeSelect
        
          value={state.Turma}
          onChange={handleChange}
          inputProps={{
            name: "Turma",
            id: "turma-native-helper"
          }}
        >
          <option aria-label="None" value="" />
          <option value={props.option1}>{props.option1}</option>
          <option value={props.option2}>{props.option2}</option>
          <option value={props.option3}>{props.option3}</option>
        </NativeSelect>
        <FormHelperText className={classes.inputLabel}>{props.textoAuxiliar}</FormHelperText> 
  
      </FormControl>
      </ThemeProvider>

   
    </div>
  );
}
