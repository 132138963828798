import React, {useEffect, useRef} from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function useEntranceAnimation(triggerSection) {
  
  let refsForEntranceAnimation = useRef([])

  useEffect(() => {

    refsForEntranceAnimation.current.sort((a, b) => {
      if (a.position < b.position) {
        return -1
      } else if (a.position > b.position) {
        return 1
      } else {
        return 0
      }
    })

    refsForEntranceAnimation.current.forEach(
      (item, index) => {
        gsap.to(item.ref.current, {
          opacity: 1,
          duration: 0.8,
          rotate: 0,
          x: 0,
          y: 0,
          delay: (0.4 + index * 0.2) + item.additionalDelay,
          ease: "power3.out",
          scrollTrigger: {
            trigger: triggerSection.current,
            start: "top 80%",
            toggleActions: "play none none none"
          }
        })
      }
    )

  }, [])

  function dynamicRefForAnimation(position, additionalDelay) {
    // after this functions executes it's importante to notice
    // that array is sorted in an useEffect hook
    if (!additionalDelay) {
      additionalDelay = 0
    }
    const newRef = React.createRef()
    if (position) {
      refsForEntranceAnimation.current.splice(position, 0,
        { position: position, ref: newRef, additionalDelay: additionalDelay })
    } else {
      refsForEntranceAnimation.current.splice(9999, 0,
        { position: 9999, ref: newRef, additionalDelay })
    }
    return newRef
  }
  
  return dynamicRefForAnimation
}
