import React, { useState } from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/styles';
import { useMUIBreakpointsContext } from '../../context/MUIBreakpointsContext'
import Typography from '@material-ui/core/Typography'

export default function ReferralProgress() {

    const [state, setstate] = useState({
        referralPoints: 0,
    })

    const theme = useTheme();
    const { xsUp,
        smUp,
        mdUp,
        lgUp,
        xlUp,
        xsDown,
        smDown,
        mdDown,
        lgDown,
        xlDown } = useMUIBreakpointsContext();



    // Material Ui Styling
    const useStyles = makeStyles((theme) => ({
        stepper:{
            backgroundColor: 'transparent'
        }

    }))

    const classes = useStyles();
    // End of Material Ui Styling

    const steps = [{
        status:false,
        expanded:true,
        label: 'Recompensa 1',
        content: 'Boa recompensa!',
    },
    {
        status:false,
        expanded:true,
        label: 'Recompensa 1',
        content: 'Boa recompensa!',
    },
    {
        status:false,
        expanded:true,
        label: 'Recompensa 1',
        content: 'Boa recompensa!',
    },
    {
        status:false,
        expanded:true,
        label: 'Recompensa 1',
        content: 'Boa recompensa!',
    },
    {
        status:false,
        expanded:true,
        label: 'Recompensa 1',
        content: 'Boa recompensa!',
    }] 

    return (
        <div>
           <div className={`bg-red-600 w-full h-72`}>

           </div>
            <Typography variant="h3" color="initial">
                Painel de Indicações:
            </Typography>
            <Typography variant="h1" color="initial">
                    {state.referralPoints}
                </Typography>

            <div className={`flex bg-gray-200 rounded-lg items-center justify-center`}>    

            <Stepper className={classes.stepper} activeStep={state.referralPoints} orientation="vertical">  
            {
            steps.map((element,index)=>(
            <Step key={index} completed={element.status} expanded={element.expanded}>
                        <StepLabel>{element.label}</StepLabel>
                        <StepContent>                            
                            <div style={{maxWidth:'400px'}}>                                
                            {element.content}
                            </div>                        
                        </StepContent>
            </Step>            
            ))
            }
            </Stepper>           
             
            </div>
        </div>
    )
}
