import React, {Component, useState} from 'react';
import Logo from './images/1-1.jpg';
import Higor1 from './images/HigorMEx (1) (1).png';
import FsLightbox from 'fslightbox-react';
import FourKIcon from "@material-ui/icons/FourK";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import VerticalTabsScrollableTabsButtonAuto from './components/verticalTab1';
import SimpleAccordion from './components/miAccordionFaq';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import PaymentFlags from './images/img-55398-20160317094438-1.png'
import JayissaTestimonial from './images/Screenshot_20181206-1845142222-img-2222111-20200409215307-1.png'

import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import PeopleIcon from '@material-ui/icons/People';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import UpdateIcon from '@material-ui/icons/Update';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckIcon from '@material-ui/icons/Check';
import gsap from 'gsap';

import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import EventIcon from '@material-ui/icons/Event';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CallMadeIcon from '@material-ui/icons/CallMade';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import Mango from './images/higorretangulo.png'
import SmillingMan from './images/Foto trabalhando (2).png'
import SecondSmillingMan from './images/Higorr (1) (2).png'
import SaveMoney from './images/FotoHigor (1).png'

import Image1 from './images/1.png';
import Image2 from './images/2.png';
import Image3 from './images/3.png';
import Image4 from './images/4.png';
import Image5 from './images/5.png';
import Image6 from './images/6.png';

import ImageA from './images/a.jpg';
import ImageB from './images/b.jpg';
import ImageC from './images/c.jpg';
import ImageD from './images/d.jpg';
import ImageE from './images/e.jpg';
import ImageF from './images/f.jpg';
import ImageG from './images/g.jpg';
import ImageH from './images/h.jpg';
import ImageI from './images/i.jpg';

import ImageA1 from './images/a1.jpg'
import ImageA2 from './images/a2.jpg'
import ImageA3 from './images/a3.jpg'
import ImageA4 from './images/a4.jpg'
import ImageA5 from './images/a5.jpg'
import ImageA6 from './images/a6.jpg'
import ImageA7 from './images/a7.jpg'
import ImageA8 from './images/a8.jpg'
import ImageA9 from './images/a9.jpg'

import NovaThumb1 from './images/thumb1.jpg'
import NovaThumb2 from './images/thumb2.jpg'
import NovaThumb3 from './images/thumb3.jpg'

import GiftWoman from './images/Foto do Perfil (1) (1).png'

import BonusDoDiaIcon from './components/BonusDoDiaIcon'
import BonusFlag from './components/BonusFlag'


import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import {functions} from './Firestore';




class MatriculaImplacavel extends React.Component{    
    
constructor(props){
    super(props)

    this.state={
        goToShop: () => {
          window.location.href = this.props.hotmartLink;       
        } ,

        toggler:true,
        toggler2:true,
        toggler3:true,
        toggler4:true,
        toggler5:true,
        toggler6:true,
        toggler7:true,
        toggler8:true,
        toggler9:true,
        toggler10:true,
        toggler11:true,

    togglerChangeState: ()=> {        
        if (this.state.toggler === true) {
            this.setState({toggler: false})
        } else {
            this.setState({toggler: true})

        }
        
    },
    togglerChangeState2: ()=> {        
        if (this.state.toggler2 === true) {
            this.setState({toggler2: false})
        } else {
            this.setState({toggler2: true})

        }
        
    },
    togglerChangeState3: ()=> {        
        if (this.state.toggler3 === true) {
            this.setState({toggler3: false})
        } else {
            this.setState({toggler3: true})

        }
        
    },
    togglerChangeState4: ()=> {        
        if (this.state.toggler4 === true) {
            this.setState({toggler4: false})
        } else {
            this.setState({toggler4: true})

        }
        
    },
    togglerChangeState5: ()=> {        
        if (this.state.toggler5 === true) {
            this.setState({toggler5: false})
        } else {
            this.setState({toggler5: true})

        }
        
    },
    togglerChangeState6: ()=> {        
        if (this.state.toggler6 === true) {
            this.setState({toggler6: false})
        } else {
            this.setState({toggler6: true})

        }
        
    },
    togglerChangeState7: ()=> {        
        if (this.state.toggler7 === true) {
            this.setState({toggler7: false})
        } else {
            this.setState({toggler7: true})

        }
        
    },
    togglerChangeState8: ()=> {        
        if (this.state.toggler8 === true) {
            this.setState({toggler8: false})
        } else {
            this.setState({toggler8: true})

        }
        
    },
    togglerChangeState9: ()=> {        
        if (this.state.toggler9 === true) {
            this.setState({toggler9: false})
        } else {
            this.setState({toggler9: true})

        }
        
    },
    togglerChangeState10: ()=> {        
        if (this.state.toggler10 === true) {
            this.setState({toggler10: false})
        } else {
            this.setState({toggler10: true})

        }
        
    },
    togglerChangeState11: ()=> {        
        if (this.state.toggler11 === true) {
            this.setState({toggler11: false})
        } else {
            this.setState({toggler11: true})

        }
        
    }
    }
}

handleFacebookRequest = functions.httpsCallable('handleFacebookRequest')

     
componentDidMount(){    
    
    this.handleFacebookRequest({
        eventName:"PageView",
        email:null
       })    

    gsap.to('#bonushighlighted', {duration: 1, yoyo:true, repeat:-1, scale:1.10, ease: "sine.inOut"})
    gsap.to('#sectionwithbonushighlighted', {duration: 1, yoyo:true, repeat:-1, backgroundColor:"#059669", ease: "sine.inOut"})

    gsap.to("#higor-floater-1", {
        duration: 1.5,
        y: -20,
        repeat:-1,
        yoyo: true,
        
    });

    gsap.to("#higor-floater-2", {
        duration: 3,
        y: -30,
        repeat:-1,
        yoyo: true,
        
    });

    gsap.to("#first-floater", {
        duration: 1.5,
        y: -20,
        repeat:-1,
        yoyo: true,
        
    });
    gsap.to("#second-floater", {
        duration: 3,
        x: 20,
        repeat:-1,
        yoyo: true,
        
    });

    gsap.to("#third-floater", {
        duration: 4,          
        y: 280,
        repeat:-1,
        yoyo: true,
        
    });

    
    gsap.to("#fourth-floater", {
        duration: 10,        
        y: 50,
        repeat:-1,
        yoyo: true,
        
    });

    gsap.to("#fifth-floater", {
        duration: 20,        
        y: 50,
        repeat:-1,
        yoyo: true,
        
    });

    gsap.to("#floater-mulher-mango1", {
        duration: 3,        
        x: 15,
        y:15,
        repeat:-1,
        yoyo: true,
        
    });

    gsap.to("#floater-mulher-mango2", {
        duration: 3,        
        x: -15,
        y:-15,
        repeat:-1,
        yoyo: true,
        
    });
  
  }



render() { 



    
    return(  
        <div className="matricula-implcavel-mother-w p-4 md:p-0 relative"> 

         {/*Floating Stuff*/}
         <div id="first-floater" className="w-24 hidden md:block h-24 bg-black opacity-20 top-0 right-5 md:fixed z-0"></div>         
         {/*Floating Stuff*/}   

            {/*Floating Stuff*/}
            <div id="second-floater" className="w-48 h-48 hidden md:block bg-black opacity-20 top-40 left-5 md:fixed z-0"></div>         
         {/*Floating Stuff*/}  
          
            {/*Floating Stuff*/}
            <div id="third-floater" className="w-96 h-1 hidden md:block bg-black opacity-5 top-80 right-5 md:fixed z-0"></div>         
         {/*Floating Stuff*/}  

           {/*Floating Stuff*/}
            <div id="fourth-floater" className="w-80 h-80 hidden md:block bg-white opacity-5 top-80 -left-20 md:fixed z-0"></div>         
         {/*Floating Stuff*/}       

                  {/*Floating Stuff*/}
                  <div id="fifth-floater" className="w-80 h-80 hidden md:block bg-white opacity-5 top-80 -right-20 md:fixed z-0"></div>         
         {/*Floating Stuff*/}    
         
          

        <div className="matricula-implacavel-component z-10">
                <div className="section-wrapper">
                        
                    <div className="grid-container md:mt-12 md:p-0 md:border-t-2 md:border-yellow-600 ">
                   
                        <div className="area area0">
                            
                        </div>
                               <div class="area area1 flex items-center justify-center">
                                   
                                   </div>
                               <div class="area area2 flex flex-col items-center md:items-end justify-center md:p-8 p-4 m-0 md:m-0 border-2 border-yellow-600  md:border-0">
                                <h1 className="alabaster font-sans md:text-5xl text-4xl  font-bold">ELIMINE OS BOLOS E MATRICULE SEU CLIENTE NO PRIMEIRO ATENDIMENTO</h1>     
                                <p className="text-white opacity-70"><strong>Observação Importante:</strong> Mesmo se seu curso estiver fechado.</p>                              
                               </div>
                               
                               <div className="cursor-pointer area area3 flex items-center justify-center relative bg-black">
                                    
                                   <div className="top-left-absolute-box absolute flex items-center justify-center z-20 w-auto h-auto top-0 left-0 text-white" >
                                       <div className="left-right opacity-90 flex flex-row-reverse items-center justify-center bg-red-500">
                                           <div className="left flex items-center justify-center pl-2 pr-2 "><NotificationsActiveIcon/></div>
                                           <div className="right flex items-center justify-center bg-red-600"><p className="p-4 text-lg font-bold text-white">Novo!</p></div>
                                       </div>
                                       
                                   </div>

                                  <div className="absolute-positioner h-full w-full absolute flex items-center justify-center" onClick={() => this.state.togglerChangeState11()}>
                                  <div className="rounded-full bg-gray-800 opacity-80 shadow-2xl">      
                                <PlayCircleFilledWhiteIcon style={{ fontSize: 100, color:'#ffffff' }} />     
                                </div> 
                                      </div>                            
                               
 

                              
                                  <FsLightbox
                                toggler={this.state.toggler2}
                                sources={[                                
                                'https://www.youtube.com/watch?v=oL0DMmwh9Vs',                              
                                ]}
                                />
                                    <FsLightbox
                                toggler={this.state.toggler3}
                                sources={[                                
                                'https://www.youtube.com/watch?v=5s8ewLXMs2Q&feature=youtu.be',                              
                                ]}
                                />

                                <FsLightbox
                                toggler={this.state.toggler4}
                                sources={[                                
                                'https://www.youtube.com/watch?v=XAP9YbGb5LI',                              
                                ]}
                                />

<FsLightbox
                                toggler={this.state.toggler5}
                                sources={[                                
                                'https://www.youtube.com/watch?v=SXyTPPtPBnA&feature=youtu.be',                              
                                ]}
                                />
                                    <FsLightbox
                                toggler={this.state.toggler6}
                                sources={[                                
                                'https://www.youtube.com/watch?v=DPc-tmU105M',                              
                                ]}
                                />
                                    <FsLightbox
                                toggler={this.state.toggler7}
                                sources={[                                
                                'https://www.youtube.com/watch?v=D1Vc7LjnnOc&feature=youtu.be',                              
                                ]}
                                />

<FsLightbox
                                toggler={this.state.toggler8}
                                sources={[                                
                                'https://www.youtube.com/watch?v=U4KdNmMRHGQ&feature=youtu.be',                              
                                ]}
                                />

<FsLightbox
                                toggler={this.state.toggler9}
                                sources={[                                
                                'https://www.youtube.com/watch?v=2b4D5fB3dTY',                              
                                ]}
                                />

<FsLightbox
                                toggler={this.state.toggler10}
                                sources={[                                
                                'https://www.youtube.com/watch?v=_Gga9vItLmo&feature=youtu.be',                               
                                ]}
                                />

<FsLightbox
                                toggler={this.state.toggler11}
                                sources={[                                
                                'https://www.youtube.com/watch?v=UlI1Iu6ULEU',                              
                                ]}
                                />


                                                           
                      
                               </div>
                               <div class="area area4 flex flex-col items-start justify-between p-4 pt-16 pb-16 md:pb-0 md:pt-0">
                                    <div className="w flex flex-col items-start justify-center">
                                          <h1 className="alabaster font-sans text-4xl mb-4">Garanta sua vaga e receba bônus exclusivo da turma</h1>
                                          <p className="alabaster font-sans text-md mb-4">Se livre do perigo de não bater as metas todos os meses e faça matrícula todos os dias</p>
                                     </div>    
                              
                               <button onClick={()=>this.state.goToShop()} className="orange hover:opacity-80 alabaster p-8 bg-blue-400 w-full  md:text-2xl text-2xl font-bold">Quero&nbsp;Matricular Todo&nbsp;Dia</button>
                               </div>
                 </div>              
            </div> 
            <div className="section-wrapper">
                                   <h1 className="section-title-h1">Os problemas</h1>
                                   <div className="items6-w">
                                    <div className="top-row flex flex-col md:flex-row items-center justify-center md:mb-8">
                                            <div className="problem-w2">
                                                    <div className="problem-fig2"></div>
                                                    <h2 className="problem-title2">Agendei com uma pessoa e ela não foi ao atendimento</h2>
                                                    <p className="problem-paragraph2" >Acho que meu script está ruim. Preciso mudar algumas coisas. Isso sempre acontece comigo e eu não sei mais o que fazer.</p>
                                            </div>
                                            <div className="problem-w2">
                                                    <div className="problem-fig2"></div>
                                                    <h2 className="problem-title2">Ficou de voltar para matricular e desapareceu</h2>
                                                    <p className="problem-paragraph2" >Poxa! Fiquei mais de uma hora atendendo. Fiz tudo certinho. Ele disse que amou o método e a escola....
Não entendo!</p>
                                            </div>
                                            <div className="problem-w2">
                                                    <div className="problem-fig2"></div>
                                                    <h2 className="problem-title2">"Me liga daqui 10 minutinhos". Nunca mais me atendeu</h2>
                                                    <p className="problem-paragraph2" >Por que as pessoas fazem isso? Será que estou falando demais? Acho que ninguém mais quer fazer curso hoje em dia.</p>
                                            </div>
                                    </div>
                                    <div className="bottom-row flex flex-col md:flex-row items-center justify-center">
                                    <div className="problem-w2">
                                                    <div className="problem-fig2"></div>
                                                    <h2 className="problem-title2">Não tenho mais nenhum contato para ligar</h2>
                                                    <p className="problem-paragraph2" >As pessoas não gostam de indicar os amigos. Os leads da internet estão ruins. Ainda mais agora na quarentena. Está realmente difícil.</p>
                                            </div>
                                            <div className="problem-w2">
                                                    <div className="problem-fig2"></div>
                                                    <h2 className="problem-title2">Fiz a pré-matrícula e o cliente foi abduzido</h2>
                                                    <p className="problem-paragraph2" >A única explicação é a abdução. Só pode. Eu disse tudo: "Vou garantir sua vaga na turma", "Vou dar baixa no meu sistema". Até marcamos um horário e NADA!</p>
                                            </div>
                                            <div className="problem-w2">
                                                    <div className="problem-fig2"></div>
                                                    <h2 className="problem-title2 ">"Vou pensar melhor e depois eu volto"</h2>
                                                    <p className="problem-paragraph2" >No que essa criatura precisa pensar? Ficamos 1 hora esclarecendo dúvidas e ele disse que estava ok. Eles falam isso só pra ir embora e nunca mais atender.</p>
                                            </div>
                                    </div>

                                   </div>

            </div>





            <div className="section-wrapper flex flex-col items-center justify-center w-full pt-32 pb-32">
            <h1 className="section-title-h1 pb-12 w-full">Quem Somos</h1>
                    <div className="leftrightflex-w quemsomos-child-w flex items-center justify-center flex-col md:flex-row md:h-96">
                                <div className="left-w md:w-1/2 h-full">
                                        <div className="top-bottom-flex-w relative">
                                        <div id="higor-floater-1" className="h-36 w-36 bg-black opacity-20 absolute -top-5 -right-5 z-0"></div>
                                        <div id="higor-floater-2" className="h-36 w-36 bg-black opacity-20 absolute -bottom-5 -left-5 z-0"></div>
                                                    <div className="top relative">
                                                     <img className="higor-fabiano-pic-mi object-none w-full z-10" src={Higor1} alt="Higor Fabiano"/>
                                                    
                                                    </div>
                                                    <div className="bottom p-8">
                                                        <p className="standard-p text-xl font-bold pt-8">Higor Fabiano</p>
                                                        <p className="standard-p text-md font-slim">Matriculador Profissional</p>
                                                    </div>
                                        </div>

                                </div>
                                <div className="right-w md:w-1/2 h-full">
                                            <div className="top-bottom-flex-w flex flex-col items-start justify-center">
                                                <div className="top h-auto">
                                                    <p className="standard-p p-4 md:p-8 border-l-2 md:border-l-0 md:border-t-2 ">
                                                    E ai!!! Eu sou Higor de Almeida Fabiano e eu também fico 1 hora ouvindo lamentações, alegrias, sendo um psicólogo/amigo e as vezes consultor. E foi assim que entendi o poder que tinha nas mãos: Transformar a vida das pessoas, e a minha, por meio da Educação.
Foi no dia que entendi isso que decidi largar o Direito e me profissionalizar, me tornando um "Matriculador"
‍
Sempre digo que cada matrícula que faço é um tijolinho a mais no meu objetivo. Se não fossem as matrículas eu não estaria onde estou hoje.
Se você está aqui é porque também sabe o valor da matrícula, tanto para o próximo quanto para você
                                                    </p>
                                                </div>
                                                <div className="bottom h-auto w-full">
                                                    <div className="social-media-w flex flex-row items-center justify-end pr-4 w-full">
                                                        <div className="social-icon"><a href="https://twitter.com/matriculemais"><TwitterIcon style={{ fontSize: 35, color:'#ecebe4ea'}}/> </a></div>
                                                        <div className="social-icon"><a href="https://www.youtube.com/channel/UCFqWTYmZZNwTSDBVEEjPR4Q"><YouTubeIcon style={{ fontSize: 35, color:'#ecebe4ea'}}/></a></div>
                                                        <div className="social-icon"><a href="https://www.facebook.com/aescoladematriculas?_rdc=2&_rdr"><FacebookIcon style={{ fontSize: 35, color:'#ecebe4ea'}}/></a></div>
                                                        <div className="social-icon"><a href="https://www.instagram.com/escoladematriculas/"><InstagramIcon style={{ fontSize: 35, color:'#ecebe4ea'}}/></a></div>

                                                    </div>
                                                </div>
                                            </div>                                   

                                </div>
                    </div>            
            </div>

            <div className="section-wrapper md:pt-32">
            <h1 className="section-title-h1 ">O que é o Treinamento...</h1>
            <h2 className="special-text-1-matricula-implacavel">Matrícula Implacável?</h2>

            </div>





                   <div className="section-wrapper flex items-center flex-col justify-center ">   

                   <div className="md:max-w-xl p-8 border-l-2 mt-16 mb-16 font-bold">
                                <h2 className="sub-section-title text-4xl text-white">É a junção dos treinamentos:</h2>
                                <h1 className="special-text-2-matricula-implacavel text-2xl orangetxt mb-4 mt-4">Agendamento Implacável + Método Matrícula</h1>
                                <p className="special-paragraph-matricula-implacavel alabaster text-base">Quando falamos de Matrículas este é o treinamento mais completo do Brasil. São aulas em vídeos, atividades, materiais complementares, atualizações, aulas de alinhamento ao vivo e muito mais. No final você se tornará um Matriculador Implacável, isso porque você vai aprender:</p>
                   </div>
                                 <div className="items9-w">
                                    <div className="top-row flex flex-col md:flex-row items-center justify-center md:mb-8">
                                            <div className="problem-w">
                                                    <div className="problem-fig iconplaceholder1">
                                                    <RecordVoiceOverIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                    </div>
                                                    <h2 className="problem-title boxes-h2-title">Comunicar</h2>
                                                    <p className="problem-paragraph" >No final do treinamento você conseguirá fazer o cliente dizer SIM para matricular.</p>
                                            </div>
                                            <div className="problem-w">
                                                    <div className="problem-fig iconplaceholder1">
                                                    <EventIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                    </div>
                                                    <h2 className="problem-title boxes-h2-title">Agendar</h2>
                                                    <p className="problem-paragraph" >Sua agenda terá pouca marcação e muito atendimento.</p>
                                            </div>
                                            <div className="problem-w">
                                                    <div className="problem-fig iconplaceholder1">
                                                    <AssessmentIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                    </div>
                                                    <h2 className="problem-title boxes-h2-title">Analisar</h2>
                                                    <p className="problem-paragraph" >Só de falar 1 minuto com a pessoa você já saberá com quem está conversando.</p>
                                            </div>
                                    </div>
                                    <div className="middle-row flex flex-col md:flex-row items-center justify-center">
                                    <div className="problem-w">
                                                    <div className="problem-fig iconplaceholder1">
                                                    <CallMadeIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                    </div>
                                                    <h2 className="problem-title boxes-h2-title">Orientar</h2>
                                                    <p className="problem-paragraph" >Saberá aplicar a técnica específica de matriculador no momento certo.</p>
                                            </div>
                                            <div className="problem-w">
                                                    <div className="problem-fig iconplaceholder1">
                                                    <SentimentSatisfiedIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                    </div>
                                                    <h2 className="problem-title boxes-h2-title">Aplicar</h2>
                                                    <p className="problem-paragraph" >Nunca mais vai ficar na dúvida e sem saber se vai funcionar ou não</p>
                                            </div>
                                            <div className="problem-w">
                                                    <div className="problem-fig iconplaceholder1">
                                                    <DoneAllIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                    </div>
                                                    <h2 className="problem-title boxes-h2-title">Multiplicar</h2>
                                                    <p className="problem-paragraph" >Com o ciclo de leads infinitos nunca mais ficará sem contato pra matricular</p>
                                            </div>
                                    </div>

                                        <div className="bottom-row flex flex-col md:flex-row items-center justify-center">
                                        <div className="problem-w">
                                                        <div className="problem-fig iconplaceholder1">
                                                            <QuestionAnswerIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                        </div>
                                                        <h2 className="problem-title boxes-h2-title">Argumentar</h2>
                                                        <p className="problem-paragraph" >Objeção nunca mais será um problema. Você eliminará antes dela surgir.</p>
                                                </div>
                                                <div className="problem-w">
                                                        <div className="problem-fig iconplaceholder1">
                                                        <LocalLibraryIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                        </div>
                                                        <h2 className="problem-title boxes-h2-title">Matricular</h2>
                                                        <p className="problem-paragraph" >O cliente vai se conscientizar que assinar o contrato é a melhor escolha</p>
                                                </div>
                                                <div className="problem-w " >
                                                        <div className="problem-fig iconplaceholder1">
                                                        <SentimentVerySatisfiedIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                        </div>
                                                        <h2 className="problem-title boxes-h2-title">Desfrutar</h2>
                                                        <p className="problem-paragraph" >Dobrar suas comissões e desfrutar de todos os bônus que você perdeu até hoje.</p>
                                                </div>
                                        </div>

                                   </div>
                   </div>
     

                   <div className="section-wrapper p-8">   
                                        <h1 className="section-title-h1 ">O que você vai ver neste Treinamento</h1>
                                        <div className="items6-modulos-w pt-8 md:p-8 md:mb-8">
                                                <h1 className="special-ultra-title-matricula-implacavel pl-0 text-5xl text-center md:text-left text-white mb-8">Primeira Fase:</h1>
                                                <div className="6items-w flex items-start justify-center md:flex-row flex-col gap-4">
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">01</p>
                                                        <p className="modulo-p">Eu Desenvolvi um Programa de Estudos para você se transformar em um Matriculador em apenas 30 dias</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">02</p>
                                                        <p className="modulo-p">As técnicas de agendamento que você ainda usa e não funcionam mais. Entenda o que fazer</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">03</p>
                                                        <p className="modulo-p">Técnica poderosa de persuasão para o seu cliente entregar todas as informações que você precisa</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">04</p>
                                                        <p className="modulo-p">Saiba de uma coisa: o agendamento não é para falar do seu curso. Aprenda a usar o equilíbrio e conquiste o SIM</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">05</p>
                                                        <p className="modulo-p">Pare de espantar seus clientes com frases e perguntas prontas. Descubra aquilo que ninguém te ensina na hora de agendar</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">06</p>
                                                        <p className="modulo-p">Seu cliente já conhece as técnicas que você usa com ele, por isso você toma bolo. Aprenda como inovar nesta hora</p>
                                                    </div>
                                          </div>
                                        </div>

                                        <div className="items12-modulos-w pt-8">
                                                <h1 className="special-ultra-title-matricula-implacavel md:pl-8  text-5xl text-white mb-8 text-center md:text-left">Segunda Fase:</h1>
                                                <div className="items6-w p-8 flex items-start justify-center md:flex-row flex-col background-mais-escuro">
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">07</p>
                                                        <p className="modulo-p">Como funciona o cérebro do seu cliente na hora de agendar um horário. Use esta técnica e elimine os bolos</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">08</p>
                                                        <p className="modulo-p">Ainda com dificuldade de aplicação? Anote o passo a passo do que fazer quando você tomar um bolo</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">09</p>
                                                        <p className="modulo-p">Aplique PNL, Linguagem hipnótica e técnicas de demonstração de poder em seu cliente e gere credibilidade</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">10</p>
                                                        <p className="modulo-p">Descubra como aplicar a Matriz de Relacionamento Humano e já plante a semente da decisão</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">11</p>
                                                        <p className="modulo-p">Você não precisa de um “Curso Bonito” para matricular. Crie uma conexão Real com seu cliente</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">12</p>
                                                        <p className="modulo-p">Não é seu cliente que escolhe o horário e como vai estudar. Contorne esta objeção com uma técnica de persuasão</p>
                                                    </div>
                                                  
                                                </div>

                                                <div className="items6-w p-8 w-full flex items-start justify-center md:flex-row flex-col orange">
                                                
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">13</p>
                                                        <p className="modulo-p">Antecipe o SIM do seu cliente antes mesmo dele conhecer seu curso. Aqui você já vai começar a regar a semente que plantou</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">14</p>
                                                        <p className="modulo-p">Se conecte com seu cliente transitando entre o Racional e o Emocional dele. Somente assim ele dirá o SIM</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">15</p>
                                                        <p className="modulo-p">Prepare seu cliente para o fechamento financeiro antes mesmo dele saber “quanto custa”</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">16</p>
                                                        <p className="modulo-p">Crie um sistema sustentável e nunca mais dependa de ninguém para bater suas metas e fazer suas matrículas</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">17</p>
                                                        <p className="modulo-p">Tenha um momento único com O Matriculador Higor Fabiano e esclareça todas as suas dúvidas</p>
                                                    </div>
                                                    <div className="modulo-1-mi-w md:w-1/6">
                                                        <p className="modulo-title">Módulo</p>
                                                        <p className="modulo-number text-white text-5xl mb-8">18</p>
                                                        <p className="modulo-p">Se você perder alguma aula ao vivo, respire e não desespere, tudo fica gravado para você estudar depois!</p>
                                                    </div>
                                                </div>
                                        </div>

                   </div>




                   <div className="section-wrapper md:p-8 p-0 pt-32">   
                   <h1 className="special-ultra-title-matricula-implacavel md:pl-8  text-5xl text-center md:text-left p-2 text-white mb-8 relative z-20 terceira-fase">Terceira Fase:</h1>
                   <div className="div kinda-gray-bg md:p-8 p-4 border-8 border-green-600">

                            <div className="box-mi left-right flex flex-col-reverse md:flex-row h-full items-start justify-center md:pt-0 md:pb-0 sm:pt-24 pt-8 pb-8">

                                <div className="left md:w-1/2 flex items-start justify-center flex-col h-full md:p-8 p-0">
                                <h2 className="text-5xl  md:mt-0 mt-8 mb-8 text-green-800 opacity-80">Módulo Bônus:</h2>  
                                      <p className="text-lg mb-4">Aqui damos outra garantia para que você aproveite ao máximo o seu Treinamento!</p>
                                     
<p className="text-lg">                                Os Módulo Bônus são as gravações de todas aulas ao vivo para você:</p>
‍
<p className="text-lg">  <strong>Aulas de Alinhamento Semanais</strong>, <strong>o MEx que você participou </strong> e muito mais que a Escola de Matrículas oferece de <strong>Overdelivery.</strong></p>          
                                </div>
                                <div className="right md:w-1/2 w-full relative">
                                      <div id="floater-mulher-mango1" className="w-20 h-20 md:w-36 md:h-36 bg-green-600 absolute -top-10 -right-10 z-00"></div>
                                      <div id="floater-mulher-mango2" className="w-20 h-20 md:w-36 md:h-36 bg-green-600 absolute bottom-0 -left-10 z-00"></div>
                                  
                                    <div className="z-10 bg2d2d2d relative flex items-center md:h-96 md:max-h-screen sm:h-96 sm:max-h-96 h-72 max-h-72 justify-center border-2 shadow-2xl higor-raising-fist-w ">
                                    <img className="absolute bottom-0 z-10 higor-raising-fist" src={Mango} alt="Higor Confiante"  /> 
                                    <img className="opacity-0 sm:h-96 h-72 higor-raising-fist" src={Mango} alt="Higor Confiante"  /> 
                                
                                    </div>
                     
                               
                                </div>
                                     
                            </div>

                            </div>
                 
                   </div>
                   <div className="section-wrapper pt-32">   
                        <div className="left-right-w flex flex-col md:flex-row items-start justify-center kinda-gray-bg md:p-8 p-6">
                                    <div className="left md:w-1/2 md:p-8 p-0 mb-4 md:mb-0">
                                        <img className="shadow-2xl" src={SmillingMan} alt="Confident Man Smilling" />
                                    </div>
                                    <div className="right md:w-1/2 md:p-8 p-0">
                                            <div className="h2 md:text-5xl text-4xl mb-8  opacity-80">Você será acompanhado em cada passo do seu treinamento</div>  
                                            <p className=" opacity-80">Eu imagino o que você está passando hoje, pois já passei por isso, como por exemplo: ter dúvidas e não poder perguntar, não saber o que fazer e não ter a quem recorrer, querer dar um passo a mais e não saber por onde começar.
Por esse motivo o aluno do Matrícula Implacável tem um acompanhamento e um suporte muito bem direcionados e focados para você matricular todos os dias</p>
                                    </div>
                        </div>
                   </div>




                   <div className="section-wrapper pt-32 pb-16">  
                   <h1 className="section-title-h1">VEJA OS RESULTADOS DE ALGUNS MATRICULADORES IMPLACÁVEIS</h1>
                        <div className="flex9-items-w flex flex-col items-center justify-center p-8 gap-4">
                                     <div className="top flex flex-col md:flex-row items-start justify-center gap-4 ">
                                                <div className="item cursor-pointer md:w-1/3 "  onClick={()=>this.state.togglerChangeState2()}>
                                                            <h2 className="text-white md:h-16 opacity-80 text-2xl">Jucélia | Diretora de Graduação e Pós</h2>
                                                            <div className="thumbnail-preview-mi flex items-center justify-center">
                                                                        <div className="relative">  
                                                                        <img src={ImageA1} alt="Testimonial" />    
                                                                                <div className="absolute m-auto inset-0 flex items-center justify-center w-full h-full">
                                                                                            <div className="bg-black rounded-full opacity-80 " >
                                                                                                    < PlayCircleFilledWhiteIcon style={{ fontSize: 60, color:'#ffffff' }} /> 
                                                                                            </div>
                                                                             
                                                                                </div>
                                                                                    
                                                                        </div> 

                                                            </div>
                                                    
                                                </div>
                                                <div className="item cursor-pointer  md:w-1/3"  onClick={()=>this.state.togglerChangeState3()}>
                                                            <h2 className="text-white md:h-16 opacity-80 text-2xl">Fernando | Franqueado Curso de Idiomas</h2>
                                                            <div className="thumbnail-preview-mi flex items-center justify-center gap-4">
                                                                        <div className="relative">  
                                                                        <img src={ImageA2} alt="Testimonial" />    
                                                                                <div className="absolute m-auto inset-0 flex items-center justify-center w-full h-full">
                                                                                            <div className="bg-black rounded-full opacity-80 " >
                                                                                                    < PlayCircleFilledWhiteIcon style={{ fontSize: 60, color:'#ffffff' }} /> 
                                                                                            </div>
                                                                             
                                                                                </div>
                                                                                    
                                                                        </div> 

                                                            </div>
                                                    
                                                </div>
                                                <div className="item cursor-pointer md:w-1/3 "  onClick={()=>this.state.togglerChangeState4()}>
                                                            <h2 className="text-white md:h-16 opacity-80 text-2xl">Fábio | Franqueado de Curso Profissionalizante</h2>
                                                            <div className="thumbnail-preview-mi flex items-center justify-center gap-4">
                                                                        <div className="relative">  
                                                                        <img src={ImageA3} alt="Testimonial" />    
                                                                                <div className="absolute m-auto inset-0 flex items-center justify-center w-full h-full">
                                                                                            <div className="bg-black rounded-full opacity-80 " >
                                                                                                    < PlayCircleFilledWhiteIcon style={{ fontSize: 60, color:'#ffffff' }} /> 
                                                                                            </div>
                                                                             
                                                                                </div>
                                                                                    
                                                                        </div> 

                                                            </div>
                                                    
                                                </div>
                                     </div>
                                     <div className="mid flex flex-col md:flex-row items-start justify-center gap-4">
                                     <div className="item cursor-pointer md:w-1/3 "  onClick={()=>this.state.togglerChangeState5()}>
                                                            <h2 className="text-white md:h-16 opacity-80 text-2xl">Paulo | Gestor Cursos Livre e Reforço Escolar</h2>
                                                            <div className="thumbnail-preview-mi flex items-center justify-center gap-4">
                                                                        <div className="relative">  
                                                                        <img src={ImageA4} alt="Testimonial" />    
                                                                                <div className="absolute m-auto inset-0 flex items-center justify-center w-full h-full">
                                                                                            <div className="bg-black rounded-full opacity-80 " >
                                                                                                    < PlayCircleFilledWhiteIcon style={{ fontSize: 60, color:'#ffffff' }} /> 
                                                                                            </div>
                                                                             
                                                                                </div>
                                                                                    
                                                                        </div> 

                                                            </div>
                                                                
                                                            </div>
                                                            <div className="item cursor-pointer md:w-1/3 "  onClick={()=>this.state.togglerChangeState6()}>
                                                            <h2 className="text-white md:h-16 opacity-80 text-2xl">Liz | Consultora Curso
Técnico</h2>
                                                            <div className="thumbnail -preview-mi flex items-center justify-center gap-4">
                                                                        <div className="relative">  
                                                                        <img src={ImageA5} alt="Testimonial" />    
                                                                                <div className="absolute m-auto inset-0 flex items-center justify-center w-full h-full">
                                                                                            <div className="bg-black rounded-full opacity-80 " >
                                                                                                    < PlayCircleFilledWhiteIcon style={{ fontSize: 60, color:'#ffffff' }} /> 
                                                                                            </div>
                                                                             
                                                                                </div>
                                                                                    
                                                                        </div> 

                                                            </div>
                                                                
                                                            </div>
                                                            <div className="item cursor-pointer md:w-1/3 "  onClick={()=>this.state.togglerChangeState7()}>
                                                            <h2 className="text-white md:h-16 opacity-80 text-2xl">Jennifer | Gerente Cursos de
Idiomas</h2>
                                                            <div className="thumbnail-preview-mi flex items-center justify-center gap-4">
                                                                        <div className="relative">  
                                                                        <img src={ImageA6} alt="Testimonial" />    
                                                                                <div className="absolute m-auto inset-0 flex items-center justify-center w-full h-full">
                                                                                            <div className="bg-black rounded-full opacity-80 " >
                                                                                                    < PlayCircleFilledWhiteIcon style={{ fontSize: 60, color:'#ffffff' }} /> 
                                                                                            </div>
                                                                             
                                                                                </div>
                                                                                    
                                                                        </div> 

                                                            </div>
                                                                
                                                            </div>
                                                </div>
                                     <div className="bot flex flex-col md:flex-row items-center md:items-start justify-center gap-4">

                                     <div className="item cursor-pointer md:w-1/3"  onClick={()=>this.state.togglerChangeState8()}>
                                                            <h2 className="text-white md:h-16 opacity-80 text-2xl">Yuli | Supervisora Curso Profissionalizante</h2>
                                                            <div className="thumbnail-preview-mi flex items-center justify-center gap-4">
                                                                        <div className="relative">  
                                                                        <img src={NovaThumb1} alt="Testimonial" />    
                                                                                <div className="absolute m-auto inset-0 flex items-center justify-center w-full h-full">
                                                                                            <div className="bg-black rounded-full opacity-80 " >
                                                                                                    < PlayCircleFilledWhiteIcon style={{ fontSize: 60, color:'#ffffff' }} /> 
                                                                                            </div>
                                                                             
                                                                                </div>
                                                                                    
                                                                        </div> 

                                                            </div>
                                                                
                                                            </div>
                                                            <div className="item cursor-pointer md:w-1/3 "  onClick={()=>this.state.togglerChangeState9()}>
                                                            <h2 className="text-white md:h-16 opacity-80 text-2xl">Jéssica | Franqueada Cursos Profissionalizantes</h2>
                                                            <div className="thumbnail-preview-mi flex items-center justify-center gap-4">
                                                                        <div className="relative">  
                                                                        <img src={NovaThumb2} alt="Testimonial" />    
                                                                                <div className="absolute m-auto inset-0 flex items-center justify-center w-full h-full">
                                                                                            <div className="bg-black rounded-full opacity-80 " >
                                                                                                    < PlayCircleFilledWhiteIcon style={{ fontSize: 60, color:'#ffffff' }} /> 
                                                                                            </div>
                                                                             
                                                                                </div>
                                                                                    
                                                                        </div> 

                                                            </div>
                                                                
                                                            </div>
                                                            <div className="item cursor-pointer md:w-1/3 "  onClick={()=>this.state.togglerChangeState10()}>
                                                            <h2 className="text-white md:h-16 opacity-80 text-2xl">Elizangela | Franqueada Curso Idiomas</h2>
                                                            <div className="thumbnail-preview-mi flex items-center justify-center gap-4">
                                                                        <div className="relative">  
                                                                        <img src={NovaThumb3} alt="Testimonial" />    
                                                                                <div className="absolute m-auto inset-0 flex items-center justify-center w-full h-full">
                                                                                            <div className="bg-black rounded-full opacity-80 " >
                                                                                                    < PlayCircleFilledWhiteIcon style={{ fontSize: 60, color:'#ffffff' }} /> 
                                                                                            </div>
                                                                             
                                                                                </div>
                                                                                    
                                                                        </div> 

                                                            </div>
                                                                
                                                            </div>

                                     </div>   

                                  
                        </div>
                   </div> 



                   <div className="section-wrapper pictures-section-mi p-8 pb-32">  
                                    <div className="flex9-3rows">
                                                <div className="top flex flex-col md:flex-row items-center md:items-start justify-center gap-4 mb-4">
                                                            <div className="testimonial-image-w-mi md:w-1/3"><img src={ImageA} alt="Testimonial" /></div>
                                                            <div className="testimonial-image-w-mi md:w-1/3"><img src={ImageB} alt="Testimonial" /></div>
                                                            <div className="testimonial-image-w-mi md:w-1/3"><img src={ImageC} alt="Testimonial" /></div>
                                                            
                                                </div>


                                                <div className="mid flex flex-col md:flex-row items-center md:items-start justify-center gap-4  mb-4">
                                                            <div className="testimonial-image-w-mi md:w-1/3"><img src={ImageD} alt="Testimonial" /></div>
                                                            <div className="testimonial-image-w-mi md:w-1/3"><img src={ImageE} alt="Testimonial" /></div>
                                                            <div className="testimonial-image-w-mi md:w-1/3"><img src={ImageF} alt="Testimonial" /></div>

                                                </div>


                                                <div className="bot flex flex-col md:flex-row items-center md:items-start justify-center gap-4  mb-4">
                                                            <div className="testimonial-image-w-mi md:w-1/3"><img src={ImageG} alt="Testimonial" /></div>
                                                            <div className="testimonial-image-w-mi md:w-1/3"><img src={ImageH} alt="Testimonial" /></div>
                                                            <div className="testimonial-image-w-mi md:w-1/3"><img src={ImageI} alt="Testimonial" /></div>

                                                </div>


                                    </div>
                                    


                   </div>




                   <div className="section-wrapper intermediate-call-mi pb-32">
                       <h1 className="section-title-h1">Quer que sua turma leve seu nome? Faça como Jucélia Ribeiro</h1>
                       <p className="text-white text-center opacity-80">Observação: nome escolhido por meio de votação entre os alunos</p>

                   </div>  



               <div className="section-wrapper testimonials-6items"> 
               
                      <div className="flex6-2rows flex items-center justify-center flex-col">
                                    <div className="top">

                                                 <div className="mid flex flex-col md:flex-row items-center justify-center gap-4 p-8">
                                                            <div className="testimonial-image-w-mi"><img src={Image1} alt="Testimonial" /></div>
                                                            <div className="testimonial-image-w-mi"><img src={Image2} alt="Testimonial" /></div>
                                                            <div className="testimonial-image-w-mi"><img src={Image3} alt="Testimonial" /></div>
                                                </div>


                                    </div>

                                    <div className="bot">

                                                <div className="mid flex flex-col md:flex-row items-center justify-center gap-4 p-8">
                                                            <div className="testimonial-image-w-mi"><img src={Image1} alt="Testimonial" /></div>
                                                            <div className="testimonial-image-w-mi"><img src={Image5} alt="Testimonial" /></div>
                                                            <div className="testimonial-image-w-mi"><img src={Image6} alt="Testimonial" /></div>
                                                </div>

                                    </div>
                          
                      </div>              
               </div>


               <div className="section-wrapper gift-section-mi md:p-8 p-4"> 
               
                                        <div id="sectionwithbonushighlighted" className="left-right flex flex-col md:flex-row items-center border-2-green-200 justify-center bg-green-500 md:mt-0 mt-16 md:mb-0 mb-16">
                                                <div className="left md:w-1/2">
                                                    <img src={GiftWoman} alt="Higor Apontando Para Você" />
                                                </div>
                                                <div className="right md:w-1/2">
                                                    <h2 id="bonushighlighted" className="text-gray-100 text-4xl md:text-4xl font-bold md:p-8 p-6">Garanta sua vaga e receba <strong className="text-5xl p-2 font-bold text-green-900 rounded-md"> bônus exclusivos!</strong></h2>
                                                </div>
                                        </div>
               </div>


               <div className="section-wrapper flex6-2rows flex-col items-center justify-center border-l-2 border-white pt-32 pb-32 md:pt-32 md:pb-32 border-opacity-50">



                            <div className="top flex flex-col md:flex-row items-center md:items-stretch justify-center pl-4 pr-4 md:pl-8 md:pr-8"> 

                   
                                            <div className="item-3-pieces-w-mi md:w-1/3 flex-1">
                                                <div className="iconplaceholder1">
                                                    <FreeBreakfastIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                </div>
                                                <h2 className="boxes-h2-title text-white">Grupo do cafezinho</h2>
                                                <p className="alabaster"><strong>Único grupo do Brasil</strong> com Gestores e Matriculadores que não te vêem como um concorrente e sim um Parceiro. Sempre ajudando e trocando informações valiosas</p>
                                            </div>
                                            <div className="item-3-pieces-w-mi md:w-1/3 alabasterbg flex-1">
                                                <div className="iconplaceholder2">
                                                <PeopleIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                </div>
                                                <h2 className="boxes-h2-title">Aulas Ao Vivo</h2>
                                                <p>Ao final de cada fase você tem uma aula ao vivo para alinhar todo conteúdo que foi entregue.</p>
                                            </div>
                                            <div className="item-3-pieces-w-mi md:w-1/3 flex-1">
                                                <div className="iconplaceholder1">
                                                <GroupWorkIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                </div>
                                                <h2 className="boxes-h2-title text-white">Suporte Gerencial por 30 dias</h2>
                                                <p className="alabaster">Durante 30 dias você será acompanhado, poderá <strong>perguntar tudo o que quiser</strong> e esclarecer todas suas dúvidas sobre o treinamento no grupo de Alunos.</p>
                                            </div>

                            </div>

                            

                            <div className="bot flex flex-col md:flex-row md:items-stretch items-center justify-center pl-4 pr-4 md:pl-8 md:pr-8">

                                            <div className="item-3-pieces-w-mi alabasterbg md:w-1/3 flex-1 ">
                                                <div className="iconplaceholder2">
                                                <UpdateIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                </div>
                                                <h2 className="boxes-h2-title">Atualização da Próxima Turma</h2> 
                                                <h2 className="boxes-h2-title line-through">[R$997,00]</h2>
                                                <p>Todo o conteúdo novo que vier na Próxima Turma do método Matrícula Implacável você receberá SEM CUSTO. Isso significa que se entrar algum vídeo novo, aula nova, conteúdo novo ou atualização de material você receberá GRATUITAMENTE.</p>
                                            </div>
                                            <div className="item-3-pieces-w-mi text-white md:w-1/3 flex-1 ">
                                                <div className="iconplaceholder1">
                                                <AccessTimeIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                </div>
                                                <h2 className="boxes-h2-title">Acesso ao Treinamento por 1 Ano</h2>
                                                <p className="alabaster">O Objetivo deste treinamento não é apenas te gerar resultado rápido. Com o acessopor um ano você pode estudar quantas vezes precisar e ter um resultadopermanente.</p>
                                            </div>
                                            <div className="item-3-pieces-w-mi alabasterbg md:w-1/3 flex-1 ">
                                                <div className="iconplaceholder2">
                                                <CheckIcon style={{ fontSize: 75, color:'#ff6a00' }}/>
                                                </div>
                                                <h2 className="boxes-h2-title">Matrícula Express</h2>
                                                <h2 className="boxes-h2-title line-through">[R$997,00]</h2>

                                                <p>A gravação de todas as aulas do curso gratuito Matricula Express 2.0 com os temas mais relevantes para você que deseja matricular todos os dias. Agendamento, atendimento, estratégia matriculadora tudo o que você imaginar em 4 dias intensos de conteúdo.</p>
                                            </div>

                            </div> 



               </div>



               <div className="section-wrapper bonus-description-mi pt-32 kinda-gray-bg p-8">

                                    <h1 className=" text-center text-red-600 text-7xl md:pt-8 md:pb-8 font-bold bg-red-200 mb-8">ATENÇÃO</h1>
                                    <h1 className=" text-gray-200 text-4xl text-center font-sans font-bold mb-16">ESTE BÔNUS É APENAS PARA <strong className="text-red-600">OS 10 PRIMEIROS ALUNOS!</strong></h1>

                                   

               </div>


                    <div className="section-wrapper bonus-extra-section md:p-8 p-0">

                                 <div className="bonus-description-breakdown-mi pb-16">

                         
                                                  <div className="flex flex-col items-center justify-center p-4  ">
                                                            <div className="border-2 p-4 rounded-lg shadow-lg">
                                                                                    <p className={`text-2xl text-center  text-green-300 font-sans mb-8 mt-4`}>  OS PRIMEIROS 10 ALUNOS RECEBERÃO </p>
                                                                                <p className={`md:text-6xl text-4xl text-center font-bold pt-4 pb-4 text-custom-orange-default bg-custom-black-default md:border-2 p-2 font-sans mb-8`}>  1 Mentoria em Grupo sobre Gestão Estratégica Comercial </p>
                                                                            <p className="text-green-200 text-center text-4xl">R$8000,00</p>
                                                            </div>
                                                        </div>
                                                       
                                                     


                                                </div>

                                <div className="leftright flex flex-col md:flex-row items-center md:items-start justify-center kinda-gray-bg md:p-8 p-4 border-8 border-green-600">
                                            <div className="left md:w-1/2 border-2 shadow 2xl"><img src={SecondSmillingMan} alt="Smilling Confident Man" /></div>
                                            <div className="right md:w-1/2 p-8">
                                                        <h1 className="md:text-5xl text-4xl font-bold mb-8 text-green-800 opacity-80">BÔNUS EXTRA PARA O PRIMEIRO ALUNO QUE SE MATRICULAR</h1>
                                                         <h1 className="text-3xl font-bold mb-8 text-green-800 opacity-80 line-through">R$2.500,00</h1>
                                                        <p className="mb-4">Nesta consultoria você terá 1 HORA comigo para podermos tratar um problema pontual. Aquilo que tira o seu sono e você NÃO SABE A SOLUÇÃO. As vezes até imagina, mas não sabe se está certo ou não sabe como executar.</p>
                                                      <p>Como Extra eu entrego uma REVISÃO de mais 1 HORA, depois de 15 dias, para você me trazer as dificuldades na execução e eu REFINAR os seus processos.
                                                        Este bônus é cumulativo com todos os outros apresentados nesta página.</p>
                                            </div>
                                </div>
                        
                    </div>


                    <div className="section-wrapper save-money md:p-8 p-0 md:mt-0 mt-16">

                    
               <div className="section-wrapper bonus-description-mi pt-32 kinda-gray-bg p-8">

                                    <h1 className=" text-center text-red-600 text-7xl md:pt-8 md:pb-8 font-bold bg-red-200 mb-8">ATENÇÃO²</h1>
                                    <h1 className=" text-gray-200 text-4xl text-center font-sans font-bold mb-16"> PARA CADA DIA QUE VOCÊ DEMORAR PARA ENTRAR VOCÊ <strong className="text-red-600">PERDE UM BÔNUS!</strong></h1>
                                    <p className="text-white text-xl opacity-80">Observe o exemplo:</p>
              </div>                   


<div className="flex flex-col items-center justify-center border-2 border-yellow-400 pt-8 pl-8 pr-8 md:pt-16 md:pl-0 md:pr-0 rounded-xl ">
                            <div className="w-full mb-32 flex items-center justify-center">
                            <BonusDoDiaIcon/>
                            </div>  

         
                            <BonusFlag text="R$49,90/mês" paragraph="Biblioteca do Matriculador - o Google dos Matriculadores"/>
                            <BonusFlag text="R$997,00" paragraph="Treinamento Completo sobre Gestão de Tráfego para Escolas (anuncios online)"/>                                    
                            <BonusFlag text="R$697,00"  paragraph="MasterClass Whatsapp e as Matrículas – Por que o Whatsapp é seu maior inimigo"/>
                            <BonusFlag text="R$497,00"  paragraph="MasterClass: Faça matrícula com apenas uma ligação"/>
                            <BonusFlag text="R$497,00"  paragraph="MasterClass: Como fazer de 429 contatos com ações externas em apenas um dia"/>
                        
</div>

                 <div className="section-wrapper intermediate-call-mi pt-32 pb-32">
                       <h1 className="section-title-h1">Se ele matricula <strong>no primeiro dia</strong> ele recebe todos estes... mas se entra no segundo dia:</h1>
                     
                   </div>  


<div className="flex flex-col items-center justify-center border-2 border-yellow-400 pt-8 pl-8 pr-8 md:pt-16 md:pl-0 md:pr-0 rounded-xl ">
                            <div className="w-full mb-32 flex items-center justify-center">
                            <BonusDoDiaIcon/>
                            </div>  

         
                            <BonusFlag cancelled={true} text="R$49,90/mês" paragraph="1 Mês de Biblioteca do Matriculador - o Google dos Matriculadores"/>
                            <BonusFlag text="R$997,00" paragraph="Treinamento Completo sobre Gestão de Tráfego para Escolas (anuncios online)"/>                                    
                            <BonusFlag text="R$697,00"  paragraph="MasterClass Whatsapp e as Matrículas – Por que o Whatsapp é seu maior inimigo"/>
                            <BonusFlag text="R$497,00"  paragraph="MasterClass: Faça matrícula com apenas uma ligação"/>
                            <BonusFlag text="R$497,00"  paragraph="MasterClass: Como fazer de 429 contatos com ações externas em apenas um dia"/>
                        
              </div>
                   
                 <div className="section-wrapper intermediate-call-mi pt-32 pb-32">
                       <h1 className="section-title-h1">Você <strong className="text-red-600">perde</strong> um bônus... entendeu?</h1>
                       <h1 className=" text-gray-100 text-4xl font-sans font-bold mt-8 mb-4 ">Então, quanto mais tempo você demorar para se matricular <strong className="text-red-600"> menos bônus você recebe</strong>.</h1>
                       <h1 className=" text-gray-100 text-4xl font-sans font-bold mb-16">Se deixar para o último minuto, já era, você <strong className="text-red-600"> não receberá nenhum bônus</strong>.</h1>
        
                   </div>  

                   


                            <div className="leftright flex flex-col md:flex-row-reverse items-center justify-center bg-green-600 md:p-8 p-4">
                                <div className="left md:w-1/2 border-2 shadow-2xl"><img src={SaveMoney} alt="Higor Lhe Convidando a Dinheiro" /></div>
                                <div className="right md:w-1/2 md:p-8 p-0 ">
                                    <h2 className="md:text-4xl text-2xl mb-8 md:mt-0 mt-8 text-white">Valor total do investimento com bônus:</h2>
                                    <h1 className="line-through font-bold md:text-6xl text-4xl mb-8 text-white">R$15.135,00</h1>
                                </div>                                
                            </div>

                    </div>


                    <div className="section-wrapper captura">

                                <div className="leftright flex flex-col md:flex-row items-start justify-center">
                                            <div className="left w-full md:w-1/2 flex items-start justify-center">
                                                <img className="w-3/4 mt-8" src={JayissaTestimonial} alt="Testemunho de Estudante" />
                                               </div>

                                            <div className="right w-full md:w-1/2">
                                                <div className="topmidbot flex flex-col items-center justify-center">


                                                    <div className="top">
                                                                    <h1 className="section-title-h1 text-white md:mt-0 mt-16 md:mb-0 mb-16">Só você é capaz de mudar seus resultados</h1>
                                                                    <h2 className="text-2xl alabaster p-4">Ninguém no seu curso vai te pegar pela mão e ensinar o que fazer para matricular todo dia.</h2>
                                                                    <p className="text-base alabaster p-4">Mas eu vou! Eu estou aqui justamente para isso! Seu resultado é o meu resultado. Vem comigo!
            Essa decisão é 100% sua! Ou você decide mudar ou você continua com os mesmos resultados.</p>
                                                    </div>


                                                    <div className="mid pl-8 md:pl-0">

                                                            <div className="leftright w-full flex items-center justify-start flex-row mb-4">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">Treinamento Matrícula Implacável</h2></div>
                                                            </div>
                                                            <h1 className="text-white section-title-h1 mb-4" >Ganhe também os Bônus:</h1>

                                                            <div className="leftright w-full flex items-center justify-start flex-row">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">Grupo de Networking 30 dias</h2></div>
                                                            </div>

                                                            <div className="leftright flex items-center justify-start flex-row">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">Suporte Gerencial 30 dias</h2></div>
                                                            </div>

                                                            <div className="leftright flex items-center justify-start flex-row">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">Aulas de alinhamento ao vivo</h2></div>
                                                            </div>

                                                            <div className="leftright flex items-center justify-start flex-row">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">Acesso ao Treinamento por 1 Ano</h2></div>
                                                            </div>

                                                            <div className="leftright flex items-center justify-start flex-row">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">Atualização da Próxima Turma</h2></div>
                                                            </div>

                                                            <div className="leftright flex items-center justify-start flex-row">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">Matrícula Express - #soudotime Bônus para os primeiros
</h2></div>
                                                            </div>
                                                            <div className="leftright flex items-center justify-start flex-row">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">Biblioteca do Matriculador - o Google dos Matriculadores</h2></div>
                                                            </div>
                                                            <div className="leftright flex items-center justify-start flex-row">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">Treinamento Completo sobre Gestão de Tráfego para Escolas (anuncios online)</h2></div>
                                                            </div>
                                                       
                                                            <div className="leftright flex items-center justify-start flex-row">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">
                                                                    MasterClass Whatsapp e as Matrículas – Por que o Whatsapp é seu maior inimigo</h2></div>
                                                                  
                                                            </div>
                                                            <div className="leftright flex items-center justify-start flex-row">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">MasterClass: Faça matrícula com apenas uma ligação</h2></div>
                                                            </div>
                                                                 <div className="leftright flex items-center justify-start flex-row">
                                                                <div className="left"><LabelImportantIcon style={{ fontSize: 48, color:'#ff6a00' }} /></div>
                                                                <div className="right"><h2 className="sub-title-mi-list">MasterClass: Como fazer de 429 contatos com ações externas em apenas um dia</h2></div>
                                                            </div>

                                                    </div>

                                                    
                                                    <div className="bot flex flex-col items-center justify-center">

                                                        <p className="text-base alabaster p-4">...e se você não achar que é o suficiente, não tem problema, eu assumo essa responsabilidade. Eu te dou 7 dias de garantia para pedir TODO o seu dinheiro de volta. Ou seja, seu RISCO É ZERO.</p>
                                                       
                                                       {!this.props.esconderPrecoHotmart === true && <p className="text-2xl alabaster p-4 font-bold border-2 mb-4">{this.props.precoHotmart}</p>}
                                                        

                                                        <button onClick={()=>this.state.goToShop()} className="bg-green-400 hover:bg-green-600 p-12 text-white text-2xl font-bold mb-4">Quero viver de matrículas</button>

                                                        <div className="cardflags flex-row flex items-center justify-center">
                                                           <img src={PaymentFlags} alt="Bandeiras de Cartão" />
                                                        </div>

                                                    </div>


                                                </div>

                                            </div>
                                </div>


                    </div>


                    <div className="section-wrapper faq-mi flex flex-col items-center justify-center p-4">

                        <h1 className="section-title-h1 text-white mb-8">Alguma dúvida? Ela pode estar aqui.</h1>
                        
                        <div className="faq-w">
                                 <SimpleAccordion/>
                        </div>                
                     


                    </div>




                    <div className="section-wrapper footer p-8 md:p-30 text-white">
                        <p>© Copyright 2018 Matrícula Impacável ® Escola de Matrículas ®</p>
                        <p>Todos os Direitos Reservados</p>
                    </div>

           
        </div>
        </div>

    )
}
}

export default MatriculaImplacavel