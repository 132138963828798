import React from 'react'
import CloseIcon from '@material-ui/icons/Close';

export default function BonusFlag(props) {
    return (
    <div className="flex md:flex-row md:w-2/3 relative flex-col items-stretch justify-stretch md:mb-24 mb-12 border-2 border-gray-300 pt-4 ">
                   { props.cancelled ?
                    <div className="absolute w-full h-full flex items-center justify-center z-20">
                    <CloseIcon style={{color:'red', height:'200px', width:'200px', marginBottom:'30px'}} />
                    </div> : null
                    }   
                <div style={{width:'120px'}} className="flex drop-shadow-2xl shadow-2xl ml-4 md:ml-0 md:mr-8 items-center justify-center bg-red-600 h-24 transform md:-rotate-45 relative">
                    <p className="text-gray-100 p-8 font-lg">{props.text}</p>

                    <div style={{clipPath:' polygon(100% 50%, 0 0, 0 100%)'}} className="w-12 h-24 bg-red-600 absolute top-0 -right-12">
                            <div style={{backgroundColor:'#232528', height:'16px', width:'16px', borderRadius: '100%', top:'calc(50% - 8px)', right:'16px', position: 'absolute' }}> </div>
                    </div>
                </div>

            <div>
                <p className="text-gray-200 text-2xl font-bold p-4">{props.paragraph}</p>
           </div>
   </div>
    )
}
