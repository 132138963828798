import React from 'react'
import {AppBar, Toolbar, Typography, IconButton} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import MailIcon from '@material-ui/icons/Mail';
import {Badge, Drawer}  from '@material-ui/core';
import { useMUIBreakpointsContext } from '../../context/MUIBreakpointsContext';

export default function MainAppBar() {

    let barHeight = 40    

   const { smUp } = useMUIBreakpointsContext() 


    // Material Ui Styling
    const useStyles = makeStyles( (theme)=>({

        toolbar:{
            height: barHeight+'px'
        },
        pusher:{
            height: barHeight + 40 +'px'
        }

    }))

    const classes = useStyles();
    // End of Material Ui Styling

    return (
        <div>
        <AppBar  position="fixed" color="primary">
            <Toolbar className={classes.toolbar}>
<div className={`flex justify-between flex-row items-center w-full`}>
          <div className={`flex flex-row items-center justify-center gap-4`}>
                      <IconButton aria-label="">
                           <MenuIcon/>
                        </IconButton>
                     {smUp && <Typography variant="h6">
                        Escola de Matriculas                
                      </Typography> }
          </div>
                <div className={`flex flex-row items-center justify-center gap-2`}>
                      <IconButton aria-label="">
                            <Badge color="secondary" variant="dot" invisible={false}>
                                <MailIcon />
                            </Badge>
                      </IconButton>
                      <IconButton aria-label="">
                            <Badge color="secondary" variant="dot" invisible={false}>
                                <MailIcon />
                            </Badge>
                      </IconButton>
                     
                </div>
</div>
            </Toolbar>
          </AppBar>

          <div className={classes.pusher}>
          </div>

          <Drawer
            variant="permanent"
            anchor="left"
            
          >
            
          </Drawer>

            
        </div>
    )
}
