import React, {useEffect, useRef} from 'react'
import { gsap } from "gsap" 
import { makeStyles } from '@material-ui/styles';
import Badge from '@material-ui/core/Badge';
import ChatBubbleSharpIcon from '@material-ui/icons/ChatBubbleSharp';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player/lazy'
 
export default function Interfa(props) {

    const refMainIconButton = useRef(null)

    const [state, setState] = React.useState({ 
        rightDrawer: false,
        notificationNumber: 0,
        DrawerContentArray: []
        });

      const toggleDrawer = () => {    

        if (state.rightDrawer === false) {      
            document.getElementById('bottomDrawerAnchor').scrollIntoView({ behavior: "instant" })
            setState({...state, notificationNumber:0, rightDrawer:!state.rightDrawer})  
           

        } else {        
                setState({...state, rightDrawer:!state.rightDrawer})   
        }
      }  

      const tl = gsap.timeline()   

      tl.fromTo(refMainIconButton.current,{
        scale: 1,
      },{
        scale: 1.4,
        duration: 0.2,      
        yoyo:true,   
        repeat: 3,   
        ease: 'sine.inOut'
      }
      ).pause()   
      
 

       useEffect(() => {  
  
        if (state.notificationNumber !== 0 && tl.progress() === 0) {    
        tl.play()                  
        }          
 
      }, [tl, state.notificationNumber])

      const handleNotificationNumber = (value) => {    
        if (0 > value + state.notificationNumber) {
        setState({...state, notificationNumber:0})
        } else {
        setState({...state, notificationNumber:state.notificationNumber + value})
        }
      }  
    

      const handleAddCard = (element) => {

        let temporaryArray =[]
        temporaryArray = [...state.DrawerContentArray]
        temporaryArray.push(element)
        setState((i) => ({...i,DrawerContentArray:temporaryArray}),handleNotificationNumber(1))     
        
                
       setTimeout(() => {
        document.getElementById('bottomDrawerAnchor').scrollIntoView({ behavior: "smooth" })
       }, 100); 
      }


      const handleProgressTrigger = (progress) => {
       let progressInSeconds = Math.floor(progress.playedSeconds)     
 
       for (let index = 0; index < props.events.length; index++) {
           const arrayElement = props.events[index];

           if (progressInSeconds === arrayElement.timeInSeconds) {
            handleAddCard(arrayElement.element)
          }           
       }  

      } 

   
        
            // Material Ui Styling
            const useStyles = makeStyles( (theme)=>({
                announcementIcon:{
                        width: '80px',
                        height: '80px',
                },

               drawerPaper:{           
                  maxWidth:'350px',
                  width:'80%',    
                },
             
            }))
        
            const classes = useStyles();
            // End of Material Ui Styling
        

    return (
        <div>
           <ReactPlayer
          width='80vw'        
          height="80vh"  
          controls={false}
          onProgress={(progress) => {handleProgressTrigger(progress)}} 
          url={props.videoURL} 
          />


          <div className={`fixed md:bottom-10 md:right-10 bottom-2 right-5 z-50`}>


            <SwipeableDrawer 
              classes={{
                paper: classes.drawerPaper
              }}
              variant='persistent'           
              anchor='right'
              open={state.rightDrawer}
              onClose={() => {toggleDrawer()} }
              onOpen={() => {toggleDrawer()}}
            >
        
             <div className={`bg-gray-200 p-4 h-full w-full overflow-y-auto relative`}>

             <div className={`fixed top-2 right-2`}>
              <IconButton aria-label="Icon"onClick={() => {toggleDrawer()}}>
              <CloseIcon style={{height:40, width:40,}} />  
              </IconButton>
            </div>

    <div id="drawerContentazs" className="mt-16">
  

                 {  state.DrawerContentArray.map((elementInArray, i) => ( 
                  <div className="" key={i}> {elementInArray} </div> 
                  ))
                  }

               <div id="bottomDrawerAnchor" className={`relative h-2 w-full bottom-0 right-0`}>
          
               </div>
    </div>
             </div>
  
            </SwipeableDrawer>



          <IconButton style={{zIndex:50}}  ref={refMainIconButton} aria-label="Icon"onClick={() => {toggleDrawer()}}>
          <Badge 
          className={classes.badge}
               anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                max={999}  badgeContent={state.notificationNumber !== 0 ? <p className={`text-white`}>{state.notificationNumber}</p> : null} color="primary">
         
        <ChatBubbleSharpIcon color="primary" className={classes.announcementIcon} /> 
          </Badge>
          </IconButton>
            
          </div>
            
        </div>
    )
}
