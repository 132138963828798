import React from 'react'
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom'

export default function SinglePostPreview({
    date,
    tags,
    categories,
    title,
    description,
    author,
    authorImage,
    postThumbnail,
    linkToRespectiveSinglePost
}) {

let history = useHistory()


    return (
        <div className={`rounded-lg max-w-lg shadow-lg`}>
            <div className={`w-full h-48 bg-gray-800`}>

            </div>
        <div className={`p-6`}>
                <div className={`flex items-center justify-between`}>
                        <p>{tags}</p>
                        <p>{categories}</p>
                </div>

                <p className={`font-sans text-2xl text-gray-700`}>{title}</p>
                <p className={`font-sans text-md text-gray-600`}>{description}</p>
              
                <img src={authorImage} alt=""/>
                <img src={postThumbnail} alt=""/>
                <p>{linkToRespectiveSinglePost}</p>
               

                <div className={`flex mt-4 items-center justify-between`}>
                    <p>{author}</p>
                    <p>{date}</p>
                </div>

                <div className={``}>
                    <Button onClick={()=>{history.push(`/blog/${linkToRespectiveSinglePost}`)}} variant="contained" sx={{
                        backgroundColor: '#e66105',
                        '&:hover': {
                            background: "#e66105e6",
                         },
                    }}> Ler Mais </Button>
                </div>
        </div>
        </div>
    )
}
