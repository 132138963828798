import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useAuth } from './context/AuthContext'
import { useSnack } from './context/SnackContext'
import { Link, useHistory } from 'react-router-dom'
import Divider from '@material-ui/core/Divider';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useLocation } from "react-router-dom";
import { DataGrid } from '@material-ui/data-grid';
import firebase, { functions } from './Firestore';
import MainAppBar from './components/dbRelatedComponents/MainAppBar'





export default function Dashboard(props) {

  useEffect(() => {
    //make sure that people that are not logged dont fall in the dashboard area
    //have a little fix using location.state to make sure people just logging in
    //accidently get redirected to the login page

    if (location.state !== undefined) {
      if (location.state.status === "loggedWithSuccess") {
      }
    }
    else {
      if (!currentUser) {
        history.push('/login')
      }

    }
  }, [])

  // Set sweetalert

  const MySwal = withReactContent(Swal)

  const location = useLocation()
  const history = useHistory()
  const { currentUser, logout, userIdToken } = useAuth()

  const { snack, handleSnack } = useSnack()

  const grantReferralToUser = functions.httpsCallable('grantReferralToUser')



  const handleLogOut = async () => {
    await logout()
      .then(() => {
        handleSnack('success', 'Sua sessão foi encerrada.')
        history.push('/login')
      }
      )
      .catch(err => {
        handleSnack('error', err)
        console.log(err)
      })
  }

  const TicketsArea = () => {

    const db = firebase.firestore();
    const [dbTicketsDataFromThisUser, setDbTicketsDataFromThisUser] = useState([])

    useEffect(async () => {
      let temporaryArray = []
      await db.collection('tickets').where("userId", "==", currentUser.uid)
        .get()
        .then((snap) => {
          snap.forEach((element) => {
            temporaryArray.push(element.data())
          })
        })
        .catch((err) => { console.log(err); })

      setTimeout(() => {
        console.log(temporaryArray);
        console.log(currentUser);
      }, 3000);
    }, [])


    const ticketDataColumns = [
      { field: 'id', headerName: 'ID', width: 90 },
      {
        field: 'firstName',
        headerName: 'First name',
        width: 150,
        editable: true,
      },
      {
        field: 'lastName',
        headerName: 'Last name',
        width: 150,
        editable: true,
      },
      {
        field: 'fullName',
        headerName: 'Full name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 160,
        valueGetter: (params) =>
          `${params.getValue(params.id, 'firstName') || ''} ${params.getValue(params.id, 'lastName') || ''
          }`,
      },
    ];

    const ticketDataRows = [
      { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
      { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
      { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
      { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
      { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
      { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
      { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
      { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
      { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];

    return (

      <div>
        <h1>hello</h1>
        <div style={{ width: '100%' }}>
          <DataGrid
            rows={ticketDataRows}
            columns={ticketDataColumns}
            pageSize={5}
            checkboxSelection
            disableSelectionOnClick
            autoHeight

          />
        </div>
      </div>

    )

  }

  return (

    <div>
      <MainAppBar />

      {currentUser &&
        <div className={`card-w`}>
          <h1>Dashboard</h1>
          <CardHeader
            avatar={
              <Avatar aria-label="">

              </Avatar>
            }
            action={
              <IconButton aria-label="">
                <MoreVertIcon />
              </IconButton>
            }
            title=""
            subheader=""

          />
          <p>Email: {currentUser.email}</p>

          <Link to="/update-profile">
            <Button variant="contained" color="default">
              Update Profile
            </Button>
          </Link>

          <Link to="/referral">
            <Button variant="contained" color="default">
              Sistema de Indicações
            </Button>
          </Link>

          <Link to="/referral">
            <Button variant="contained" color="default">
              Suporte ao usuário
            </Button>
          </Link>


          <Divider variant="middle" />




          <Button onClick={handleLogOut} variant="text" color="default">
            Log Out
          </Button>


          <TicketsArea />
        </div>
      }
    </div>

  )
}
