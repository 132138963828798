import React, {useState} from 'react'
import { Section, ListaDeAulas, IconBoxList, HeroSection, CaptureSection, WhoAmI } from '../components/LancamentoDois/UI'
import Higor1 from '../images/higornovo2.png'
import Higor2 from '../images/higornovao.png'
import Logo from '../images/logonova1.png'


export default function LancamentoDois({paginaDeObrigado}) {

    // Data das aulas e dates
    const [classesAndDatesArray, setclassesAndDatesArray] = useState([
        {
            mode: "default",
            date: <span>09/05</span>,
            dateDescription:<span>Segunda - às 20:22h (horário de Brasília)</span>,
            id:1,
            tagTitle: <span>Aula</span>,
            headline: <span>Nunca fale do seu Curso durante uma prospecção</span>,
            description: <span>O Processo de Matrículas é dividido em várias etapas. 
            As mais Simples são: Captação, Prospecção e Atendimento. 
            Um erro comum é falar do seu curso durante a prospecção. 
            <strong>Não cometa este erro de amador</strong> e já de os primeiros passos para reduzir os bolos
            </span>
        },
        {
            mode: "default",
            date: <span>10/05</span>,
            dateDescription:<span>Terça - às 20:22h (horário de Brasília)</span>,
            id:2,
            tagTitle: <span>Aula</span>,
            headline: <span>O que você está marcando com seu cliente?</span>,
            description: <span>“Vem aqui fazer uma visita sem compromisso”, “Vamos marcar um horário para um atendimento, só para conhecer”. 
            Frases como estas são responsáveis pelos bolos que você toma hoje. 
            Assista essa aula e dê mais um passo em direção ao atendimento com seu cliente.            
            </span>
        }
        ,
        {
            mode: "default",
            date: <span>11/05</span>,
            dateDescription:<span>Quarta - às 20:22h (horário de Brasília)</span>,
            id:3,
            tagTitle: <span>Aula</span>,
            headline: <span>O que dizer nos 10 primeiros segundos de ligação</span>,
            description: <span>“Bom dia, aqui é o Higor, Consultor Comercial da Escola de Matrículas [ nome da sua Escola]....”
            Se você inicia sua ligação desse jeito ou parecido com isso esta aula foi feita para você.
            Aqui você vai entender a lógica por trás de uma abordagem persuasiva e que retém a atenção do seu cliente.                        
            </span>
        }
        ,
        {
            mode: "default",
            date: <span>12/05</span>,
            dateDescription:<span>Quinta - às 20:22h (horário de Brasília)</span>,
            id:4,
            tagTitle: <span>Aula</span>,
            headline: <span>O que fazer quando o cliente insiste em saber valor pelo telefone?
            E o cliente que só quer falar pelo Whatsapp?
            </span>,
            description: <span>Se você não quer passar por estas e outras situações você não pode faltar a esta aula.
            Aqui você vai descobrir tudo o que você precisa para <strong>reduzir drasticamente os bolos</strong> e finalmente <strong>dobrar o número de matrículas</strong> no seu curso e sua escola                                    
            </span>
        }
    ])
    // Data do iconBarArray
    const [iconBarArray, seticonBarArray] = useState([
        {
            id:1,
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>,
            text: <span>09 a 12 de maio</span>
        },
        {
            id:2,
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>,
            text: <span>20:22h (horário de Brasília)</span>
        },
        {
            id:3,
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
          </svg>,
            text: <span>Online pelo Youtube</span>
        },
        {
            id:4,
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
          </svg>,
            text: <span>Evento gratuito</span>
        },
        {
            id:5,
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>,
            text: <span>Sem Replay</span>
        },
        {
            id:6,
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>,
            text: <span>Com Exercicios Práticos</span>
        }        
    ])
    // Data do hero section
    const [heroSectionData, setheroSectionData] = useState({
        image1: Higor1,
        paginaDeObrigado: paginaDeObrigado,
        logo: Logo,
        icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>,
        title: <span>Descubra os erros que os amadores cometem na hora de ligar para o cliente e reduza até 80% dos bolos que você leva</span>,
        descriptiveDate: <span>Evento online e gratuito dos dias 09 a 12 de Maio</span>,   
        nome: <span>Higor Fabiano -  O Matriculador</span>,     
    })
    // Data do capture section
    const [captureSectionData, setcaptureSectionData] = useState({
        paginaDeObrigado: paginaDeObrigado,
        text: <span>Participe da Jornada do Matriculador e aprenda qual é o caminho mais fácil para você dobrar os número de matrículas na sua escola ainda este mês com uma metodologia validada por mais de 1000 alunos.</span>
    })
    // Data do WhoAmI
    const [dataWhoAmI, setdataWhoAmI] = useState({
        image1: Higor2,
        nome: <span>Higor Fabiano -  O Matriculador</span>,
        titulo: <span>Fundador da primeira Escola específica para quem vende cursos, a Escola de Matrículas</span>,
        text: <div>            
            Higor teve sua primeira experiência profissional aos 17 anos como Street, fazendo captação externa de clientes. <br/><br/>
            Durante todos esses anos Higor se aprofundou no comportamento humano e nas técnicas mais poderosas de Comunicação e Vendas. <br/><br/>
            Higor é líder de um movimento que luta através da excelência, desenvolvendo Consultores e Gestores Educacionais para que se tornem Matriculadores Implacáveis. Mais de 1000 alunos formados fazem parte dessa comunidade que cresce a cada dia. <br/>
            Pioneiro, desbravador e extremamente sedento por resultado, Higor criou o MÉTODO MATRÍCULA, que faz seus alunos matricularem todos os dias.<br/><br/>
            A Escola de Matrículas já transformou vidas, resgatou empresas e desenvolveu autoconfiança em profissionais que se sentiam perdidos e desvalorizados.<br/><br/>
            Não somos apenas uma Escola, somos a primeira Escola de Matrículas do Brasil pois carregamos conosco cada vida que passou e passará por aqui.<br/><br/>
        </div>
    })

  return (
    <div className={`bg-gray-900 grid p-4 py-4 md:py-16 gap-16 grid-cols-1 place-items-center justify-items-center`}>
        {/* Hero Section */}
        <Section>
            <HeroSection data={heroSectionData}/>
        </Section>
        {/* Lista de icones com descrição */}
        <Section>
            <IconBoxList list={iconBarArray}/>
        </Section>
        {/* Lista das com as datas e aulas */}  
        <Section>
            <ListaDeAulas list={classesAndDatesArray}/>          
        </Section>
         {/* Capture Section */}  
        <Section>
            <CaptureSection data={captureSectionData} />
        </Section>
        {/* WhoAmI Section */}  
        <Section>
            <WhoAmI data={dataWhoAmI}/>
        </Section>
    </div>

  )
}
