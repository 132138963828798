import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-functions';
    
    // Your web app's Firebase configuration
    var firebaseConfig = {
        apiKey: "AIzaSyAZaBcNV17x8lGH8QzFDeC0WUQMWYE9k0o",
        authDomain: "higorfabianorealtime.firebaseapp.com",
        databaseURL: "https://higorfabianorealtime-default-rtdb.firebaseio.com",
        projectId: "higorfabianorealtime",
        storageBucket: "higorfabianorealtime.appspot.com",
        messagingSenderId: "708904910071",
        appId: "1:708904910071:web:0a3545a34d3525249ba7f2"
      };
      // Initialize Firebase
     firebase.initializeApp(firebaseConfig);

     const GoogleProvider = new firebase.auth.GoogleAuthProvider();      
     const auth = firebase.auth();
     const db = firebase.firestore();
     const functions = firebase.functions();

     // eslint-disable-next-line no-restricted-globals
     if (location.hostname === 'localhost') {
      db.useEmulator('localhost', 8081);
      auth.useEmulator('http://localhost:9099/', { disableWarnings: true });
      functions.useEmulator('localhost', 5001);
    }

export {db, auth, GoogleProvider, functions}


export default firebase;
