import React from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export default function IconWithListItemBeside(props) {

    if (props.crossed === true) {
                
    }
    return (
        <div>
         <div className={`flex items-center justify-center gap-4`}>
                <div>
                { props.crossed ? <HighlightOffIcon style={{color: 'red', height:'40px', width:'40px'}} /> : <CheckCircleIcon style={{color: 'green', height:'40px', width:'40px'}} />} 
                </div>           
                 <div className={(props.crossed ? 'line-through' : '') + " p-2" }>
                 <p className="text-2xl font-sans">{props.children}</p>
                 </div>
         </div>
        
        </div>
    )
}
