import React, {useState, useRef, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import DownSellDefault from './components/UpSellDownSell/DownSellDefault'
import { Transition } from 'react-transition-group';
import gsap from 'gsap'
import { Container } from '@material-ui/core';
import Logo from './images/newlogo2.png'
import hotmartupsellScript from './scripts/hotmartupsell'
import FsLightbox from 'fslightbox-react';
import VideoComponent from './components/Perpetua/VideoComponent';
import Testemunhos from './components/Perpetua/Testemunhos';
import thumb1 from './images/0.jpg'
import ComQuemVaiAprender from './components/Perpetua/ComQuemVaiAprender';
import HeaderWarning from './components/UpSellDownSell/HeaderWarning';
import ThumbUp from './images/3 (5) (1).png'
import IconWithListItemBeside from './components/UpSellDownSell/IconWithListItemBeside';

export default function AmDown() {
 
   const lineThrough = useRef(null)

   useEffect(() => {

        const script = document.createElement("script");
        script.text = `
        /* eslint-disable */
if (!Upsell) {
  var Upsell = {};
}

if (!Logger) {
  var Logger = {};
}

Logger = {
  debugIsOn : false,
  setDebug : function(isOn) {
    this.debugIsOn = isOn;
  },
  error : function(msg) {
    if (this.debugIsOn) {
      window.alert(msg);
    }
  }
};

Upsell.Util = {
  getProtocol : function() {
    return document.location.protocol;
  },
  render : function(template, params) {
    return template.replace(/\#\{([^{}]*)\}/g, function(a, b) {
      var r = params[b] || '';
      return typeof r === 'string' || typeof r === 'number' ? r : a;
    });
  },
  toQueryString : function(params) {
    var pairs = [];
    for (var key in params) {
      if (params[key] !== null && params[key] !== '' && typeof params[key] !== 'function') {
        pairs.push( [ key, params[key] ].join('='));
      }
    }
    return pairs.join('&');
  },
  isIE : function(test) {
    if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
      if (typeof test === "function") {
        return test(Number(RegExp.$1));
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
};
Upsell.Page = {
  getDimensions : function() {
    var de = document.documentElement;
    var width = window.innerWidth || self.innerWidth || (de && de.clientWidth) || document.body.clientWidth;
    var height = window.innerHeight || self.innerHeight || (de && de.clientHeight) || document.body.clientHeight;
    return {
      width : width,
      height : height
    };
  }
};
Upsell.Frame = {
  urlFunnel : function(params){
    var queryString = window.location.search;
    console.log(params);
    var currentHost = window.location.hostname.indexOf('buildstaging.com') !== -1
      ? 'https://app-hotmart-checkout.buildstaging.com/funnel'
      : 'https://pay.hotmart.com/funnel';
    if (queryString.indexOf('?fsid') === -1) {
      currentHost += '?'
    }
    var url = currentHost + queryString + "&" + (params.buttonImage !== '' ? 'buttonImage=' + params.buttonImage + '&' : '') + (params.customStyle !== '' ? 'customStyle=' + params.customStyle + '&' : '');
    var funnel = '<iframe id="hotmart_upsell_iframe" class="hotmart_upsell_iframe" src="' + url + '#{query}" width="#{width}" height="#{height}" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>';

    return { funnelFrame: funnel };
  },
  content_template : function(params) {
    return { frame : this.urlFunnel(params).funnelFrame }
  },
  //'<iframe id="hotmart_upsell_iframe" src="' + document.querySelector('script[src$="upsell-window.js"]').getAttribute('src').split('js/widgets/upsell-window.js')[0] + '/widgets/funnel/upsell.html?#{query}" width="#{width}" height="#{height}" frameborder="0" scrolling="auto" allowtransparency="true" ></iframe>',
  //content_template : '<iframe id="hotmart_upsell_iframe" src="//www.hotmart.net.br/widgets/funnel/upsell.html?#{query}" width="#{width}" height="#{height}" frameborder="0" scrolling="auto" allowtransparency="true" ></iframe>',

  getBox : function() {
    return document.getElementById("box_hotmart");
  },
  getQuery : function(params) {
    return Upsell.Util.toQueryString( {
      key : params.key,
      launcherCode: params.launcherCode
    });
  },
  getFrameParams : function(params) {
    return {
      protocol : Upsell.Util.getProtocol(),
      query : this.getQuery(params),
      width : params.width,
      height : params.height,
      buttonImage: params.buttonImage || '',
      customStyle: params.customStyle || ''
    };
  },
  b64EncodeUnicode: function(str){
    // Read this - https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode('0x' + p1);
      }))
  },
  show : function(params) {
    params.buttonImage = this.b64EncodeUnicode(params.buttonImage || '');
    params.customStyle = this.b64EncodeUnicode(params.customStyle || '');

    var iframeParams = this.getFrameParams(params);
    var iframeHtml = Upsell.Util.render(this.content_template(params).frame, iframeParams);
    var box = this.getBox();

    var iframeStyles = document.createElement('style');
    iframeStyles.innerHTML = '@media only screen and (max-width: 720px) { .hotmart_upsell_iframe {width: 100%; height: 380px} }';
    document.head.appendChild(iframeStyles);

    box.innerHTML = iframeHtml;
  }
};
Upsell.Widget = {

  setupOptions : function(params, optDebug) {
    if (optDebug !== null && typeof (optDebug) !== 'undefined') {
      Logger.setDebug(optDebug);
    } if (typeof (params) === 'undefined' || params === null) {
      Logger.error("Nenhum parametro informado ao Widget do Hotmart. Verifique se a variavel 'opts' se ela esta preenchida ou com algum erro de sintaxe.");
      return;
    } if (params.key === null) {
      Logger.error("A chave ('key') do widget deve ser informada. Verifique na variavel 'opts' e adicione a propriedade 'key' com o valor retornado pelo Hotmart");
      return;
    }
    this.params = params;
  },
  show : function(options, optDebug) {
    this.setupOptions(options, optDebug);
    Upsell.Frame.show(this.params);
  }
};

function getQueryParameter( name, url ) {
  if (!url) url = location.href;
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
  var regexS = "[\\?&]"+name+"=([^&#]*)";
  var regex = new RegExp( regexS );
  var results = regex.exec( url );
  return results === null ? null : results[1];
}
/* eslint-disable */
        `;

        document.body.appendChild(script)
   


    setstate({ ...state, hotmartScript:true});

    
    
    return () => {
        document.body.removeChild(script);
    }
    }, [])

  const [lightBoxToggler, setLightBoxToggler] = useState({
      t1:false,
      t2:false,
      t3:false,
      t4:false,
  });

  const triggerLightBox = (t) => {

    setLightBoxToggler({
        ...lightBoxToggler,
        [t]:!lightBoxToggler[t]
    })      
}


   let tl = gsap.timeline()

   const [state, setstate] = useState({
    hotmartScript: false,    
  })




    return (
        <div className={`flex items-center justify-center flex-col min-h-screen`}>
         
         
        <FsLightbox
        toggler={lightBoxToggler.t1}
        sources={[
        'https://www.youtube.com/watch?v=Mj0CfUiW8xs&feature=youtu.be',
        ]}
        />
        <FsLightbox
        toggler={lightBoxToggler.t2}
        sources={[
        'https://www.youtube.com/watch?v=Mj0CfUiW8xs&feature=youtu.be',
        ]}
        />
        <FsLightbox
        toggler={lightBoxToggler.t3}
        sources={[
        'https://www.youtube.com/watch?v=Mj0CfUiW8xs&feature=youtu.be',
        ]}
        />
        <FsLightbox
        toggler={lightBoxToggler.t4}
        sources={[     
        'https://www.youtube.com/watch?v=nCGbF8sPJtk',
        ]}
        />

        <div className={`w-full bg-custom-orange-default`}>
              <Container maxWidth="md">
                <HeaderWarning alternativeBgColorClass='bg-gray-600' iconPhrase="NÃO FECHE ESTA PÁGINA" toAnimate={true}>              
                </HeaderWarning>         
              </Container>
        </div> 

        {/* <div className={`Header p-8 bg-custom-black-default w-full`}>
                <Container maxWidth="md">
              <div className={`flex items-center flex-col md:flex-row gap-4 justify-center md:justify-start`}>
                        <img src={Logo} alt=""></img>
                        <h1 className={`text-gray-300 font-bold text-2xl font-sans`}>#SOUDOTIME</h1>
              </div>
                </Container>
        </div> */}

        <div className={`w-full bg-gray-200`}>
        <Container maxWidth="md">

       
        <h1 className="text-center mb-16 text-5xl font-bold font-sans text-gray-700 mt-16">O maior poder de um <strong  className={`text-custom-orange-default`}>Matriculador Implacável</strong> é sua <strong  className={`text-custom-orange-default`}>Comunicação</strong></h1>
      <p className="text-center text-4xl font-sans text-gray-600 mt-8 mb-16">Descubra como <strong  className={`text-custom-orange-default`}>potencializar as suas matrículas</strong> e a do seu time com técnicas <strong  className={`text-custom-orange-default`}> avançadas </strong> de comunicação persuasiva</p> 

       <div className={`mb-8`}>
          <VideoComponent isCustomThumb={true} width={`w-full`} height={`sm:h-96 h-56`}  thumbnail={ThumbUp} callBackFunction={triggerLightBox} stateIdentifier='t4'/>

       </div> 
       <p className="text-center text-4xl font-bold font-sans text-gray-800 mt-8 mb-16">Presta atenção no que você vai receber: </p>     
<div className={` mb-8 flex flex-col items-start justify-center bg-green-100 bg-opacity-5 p-4`}>         
          <IconWithListItemBeside>Descubra como funciona a Mente de um Matriculador</IconWithListItemBeside> 
          <IconWithListItemBeside>Os gatilhos mentais aplicados na sua vida e na Matrícula</IconWithListItemBeside>
          <IconWithListItemBeside>Nunca mais dependa de ninguém e aprenda a captar Leads Qualificados</IconWithListItemBeside>
          <IconWithListItemBeside>Técnicas avançadas de comunicação para Matriculadores</IconWithListItemBeside>
          <IconWithListItemBeside>6 meses de acesso, podendo ser renovado por quantos semestres quiser</IconWithListItemBeside>
          {/* <IconWithListItemBeside crossed={true}>Manual Prático (em PDF) com as objeções e os argumentos para você imprimir e estudar</IconWithListItemBeside>
          <IconWithListItemBeside crossed={true}>1 ano de acesso ao treino</IconWithListItemBeside>
          <IconWithListItemBeside>6 meses de acesso ao treino</IconWithListItemBeside> */}

</div>
     
         {state.hotmartScript ? 
         <DownSellDefault/> : null
         }



         {/* <Testemunhos colorTextTitle="text-gray-600" colorTextSubTitle="text-gray-500" triggerLightBox={triggerLightBox} setLightBoxToggler={setLightBoxToggler} />
          */}
         <ComQuemVaiAprender/>
     
            </Container>
         </div>

         <div className={`Footer bg-custom-black-default flex items-center justify-center w-full p-16`}>
         <Container maxWidth="md">     
            <p className={`text-lg text-gray-200`}>Copyright 2015 - Todos os Direitos Reservados</p>     
        </Container>
        </div>
        </div>
    )
}
