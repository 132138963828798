import React, { useState } from 'react'
import {HeroSection, VideoHighlightSection, DefinitionsSection, CallToAction1, CallToAction2, CallToAction3, CallToAction4, CallToAction5, CallToAction6, FAQ, WhoAmI, Testimonials} from './UI'
import Higor2 from '../../images/higornovao.png'
import Higor1 from '../../images/terno_azul.png'
import {ReactComponent as SVG1} from '../../images/Visiwig-Dots (1).svg';
import Image1 from '../../images/imagem_pessoas_relogio.png'
import Image2 from '../../images/sem_nome_(6801080px)_(2).png'
import Visa from '../../images/visa.png'
import Hiper from '../../images/hiper.png'
import Master from '../../images/master.png'
import Amex from '../../images/amex.png'
import Diners from '../../images/diners.png'
import Tickets from '../../images/tickets.png'


export default function WorkshopIntensivao() {

    const [okIcon] = useState(
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
</svg>
    )  

    const [data] = useState({
        heroSection:{
          image1Url: Higor1,
          headline: <span>Workshop Intensivão de Matrículas:</span>,
          secondHeadline: <span>Descubra o passo a passo para criar um script de matrículas do absoluto zero, mesmo sem ter experiência na área</span>, 
          subHeadline:<span>Workshop Prático de 2 dias</span>,
          subHeadDescription:<span>Com Higor Fabiano</span>,
          format:<span>ONLINE E AO VIVO</span>,
          whenWillHappen: <span>
          Quando? Dias 18 e 19 de Junho
          das 9h as 16h (sábado e domingo)</span>,
          buttonText:<span>Quero meu Ingresso</span>,
        },
        videoHighlightSection:{
          youtubeVideoLink: "https://www.youtube.com/watch?v=WNsvxjWjLFc",
          headline: <span>Não Existe Matrícula sem Script </span>,
          description1: <span>A Única forma de fazer matrículas todos os dias do jeito certo e conseguir bater a sua meta todos os meses de forma segura e sem se preocupar com a sazonalidade (nos meses de alta bate meta e nos outros não) é tendo um Script de que converte em Matrículas</span>,
          description2: <span>Diariamente centenas, talvez milhares de Cursos e Escolas não batem suas metas de matrículas e correm o risco de não pagarem nem as contas. Sabe Porque?</span>,
          reasonsList: [
            {id: 1, title: <span>Não importa se seu método é bom…</span>},
            {id: 2, title: <span>Não importa se sua escola tem uma boa estrutura, se é moderna ou bem localizada</span>},
            {id: 3, title: <span>Não importa seu consultor estar motivado ou se ele faz um “ótimo atendimento”</span>},
          ],
          description3:<span>Se sua Escola usa o mesmo Script a mais de 1 ano e não consegue atualizar os elementos indispensáveis para uma alta  conversão conectando com o seu público alvo,  você está fadado a virar estatística antes mesmo do que você  imagina.</span>,
          buttonText:<span>Script que converte</span>,
        },
        definitionsSection:{
          imageUrl1:Image1,          
          headline1:<span>Um Script que conecta com seu cliente é a chave para matricular todos os dias</span>,
          description1:{
            part1:<span>Você precisa de um Script diferente do que você usa hoje. Eu sei que você está preso a este script a alguns meses (e até anos) e sente que precisa mudar alguma coisa e não sabe O QUE e nem COMO.</span>,
            part2: <span>Aqui está o problema</span>,
            part3: <span>Quando você sente esta necessidade de mudança, geralmente, você fica olhando o script dos outros, assiste vídeos na internet e vai mudando sem uma direção</span>
          },
          imageUrl2:Image2,
          headline2PowerWord: <span>CUIDADO!</span>,
          headline2: <span>você está montando o monstro do Frankstein no seu Script</span>,
          description2: <span>Ter uma direção, um passo a passo do que fazer vai te dar clareza, assertividade e com isso você vai conseguir converter seus cliente em alunos em alta quantidade e com qualidade.</span>,     
          

        },
        callToAction1:{
          itemsArray1:[
            {id:1, icon:okIcon , item:<span>Passo a Passo para a criação de Script</span>},
            {id:2, icon:okIcon , item:<span>Estratégias para transformar seu Script Antigo em um Script que converte</span>},
            {id:3, icon:okIcon , item:<span>Elementos de conexão com o Cliente</span>},
            {id:4, icon:okIcon , item:<span>Estruturas persuasivas prontas para converter mais matrículas</span>},
          ],
          buttonText: <span>CONVERTER MAIS ALUNOS</span>,
        },
        callToAction2:{
          headline: <span>Saiba como construir um Script Implacável, criar uma conexão  e converter 80% dos seus clientes em matrículas para sua Escola.</span>,
          subheadline2: <span>Aprenda na prática como criar ou alterar o seu script do absoluto zero de acordo com seu público com base nas técnicas de persuasão utilizadas por grandes nomes do mercado.</span>,
          itemsArray1:[
            {id:1, icon:<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
</svg> , item:<span>Identificar a Estrutura</span>},
            {id:2, icon:<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg> , item:<span>Descobrir os Erros</span>},
            {id:3, icon:<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
          </svg> , item:<span>Estruturas Persuasivas</span>},
            {id:4, icon:<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
          </svg> , item:<span>Potencializadores de conversão</span>},
          ],
          buttonText: <span>Criar Script do ZERO</span>

        },
        callToAction3:{
          headline1:<span>Com o Hexágono da Persuasão você saberá identificar qual parte do seu Script precisa melhorar para se tornar mais conectivo</span>,
          paragraph1:<span>Esta ferramenta vai te ajudar a encontrar onde está a principal falha do seu Script. São 6 lados, com 6 princípios e 6 linguagens que seu cliente precisa entender para fazer a matrícula no final do atendimento.</span>,
          headline2:<span>O que mais vou aprender na Prática?</span>,
          paragraph2:<span>Na prática você vai descobrir como transformar clientes comuns em alunos fiéis e que indicam outros alunos para sua Escola.

          Isso significa que você vai saber como:
          </span>,
          itemsArray1:[
            {id:1, icon:okIcon , item:<span>Identificar se a Estrutura do seu Script está correta</span>},
            {id:2, icon:okIcon , item:<span>Como aumentar a taxa de conversão do seu time</span>},
            {id:3, icon:okIcon , item:<span>Descobrir quais são os principais erros existentes em Todos os scripts de hoje</span>},
            {id:4, icon:okIcon , item:<span>Como matricular mais sem precisar das desconto</span>},
            {id:5, icon:okIcon , item:<span>Quais são as principais estruturas persuasivas de um script que converte</span>},
            {id:6, icon:okIcon , item:<span>Principais ativadores de um script que converte</span>},
            {id:7, icon:okIcon , item:<span>Como potencializar seu script</span>},
            {id:8, icon:okIcon , item:<span>Construa um Script que converte do absoluto zero</span>}
          
          ],
          buttonText: <span>Quero Converter até 80%</span>
        },
        callToAction4:{
          headline1:<span>Workshop 100% prático de 2 dias ao vivo e online com Higor Fabiano - O Matriculador Na caixinha: 
          </span>,
          date1: <span>Quando: 18 e 19 de junho</span>,
          hours1: <span>das 9h as 16h</span>,
          headline2: <span>Participe com uma condição especial por tempo limitado</span>,
          crossedPrice: <span>De: <span className={`line-through`}>R$ 997,00</span> por apenas:</span>,
          price:<span>R$97,00 à vista</span>,
          alternativePrice:<span>Ou 12x de R$9,68</span>,
          buttonText:<span>QUERO MINHA VAGA E A VAGA DE CORTESIA</span>,
          safeData:[
            {id:1, icon:<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
          </svg> , headline:<span>Compra Segura</span>, paragraph: <span>Seus dados estão protegidos</span>},
            {id:2, icon:<svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
          </svg> , headline:<span>Risco Zero</span>, paragraph: <span>7 dias de garantia</span>},
          ],
          cardOptions:[
            {id:1, cardImageUrl: Visa},
            {id:2, cardImageUrl: Master},
            {id:3, cardImageUrl: Diners},
            {id:4, cardImageUrl: Hiper},
            {id:5, cardImageUrl: Amex},
          ],          
        },
        callToAction5:{
          headline1:<span>E ainda tem mais: garanta 1 ingresso e leve outro de CORTESIA. São 2x1!</span>,
          imageUrl1: Tickets,
          buttonText:<span>QUERO MINHA VAGA AGORA!</span>,
        },
        callToAction6:{
          headline: <span>Entre em ação IMEDIATAMENTE 
          com este BÔNUS EXCLUSIVO</span>,
          subheadline: <span>Comece AGORA a gerar resultados com este bônus especial liberado imediatamente após sua compra.</span>,  
          headline2: <span>Treinamento Viver de Matrículas de R$197,00 por: GRÁTIS!</span>,
          itemList: [
            {id:1, icon: okIcon, item:<span>Como ter uma mentalidade de um matriculador</span>},
            {id:2, icon:okIcon, item:<span>Tenha uma comunicação magnética que engaja e converte seu cliente em matrícula</span>},
            {id:3, icon:okIcon, item:<span>Princípios e estratégias para captar lead no mundo offline sem gastar 1 real (captação externa)</span>},
            {id:4, icon:okIcon, item:<span>Gatilhos mentais aplicados nas Matrículas</span>},
            {id:5, icon:okIcon, item:<span>São mais de 20 aulas com uma média de 25 minutos, de forma prática</span>},
            {id:6, icon:okIcon, item:<span>Simples e aplicável para você começar aumentar sua conversão de matrículas em 30% desde já!</span>},
          ],
          buttonText: <span>LIBERAR MEU BÔNUS</span>,
        },
        whoAmI:{
          svg1:<SVG1/>,
          image1: Higor2,
          nome: <span>Higor Fabiano -  O Matriculador</span>,
          titulo: <span>Fundador da primeira Escola específica para quem vende cursos, a Escola de Matrículas</span>,
          text: <div>            
              Higor teve sua primeira experiência profissional aos 17 anos como Street, fazendo captação externa de clientes. <br/><br/>
              Durante todos esses anos Higor se aprofundou no comportamento humano e nas técnicas mais poderosas de Comunicação e Vendas. <br/><br/>
              Higor é líder de um movimento que luta através da excelência, desenvolvendo Consultores e Gestores Educacionais para que se tornem Matriculadores Implacáveis. Mais de 1000 alunos formados fazem parte dessa comunidade que cresce a cada dia. <br/>
              Pioneiro, desbravador e extremamente sedento por resultado, Higor criou o MÉTODO MATRÍCULA, que faz seus alunos matricularem todos os dias.<br/><br/>
              A Escola de Matrículas já transformou vidas, resgatou empresas e desenvolveu autoconfiança em profissionais que se sentiam perdidos e desvalorizados.<br/><br/>
              Não somos apenas uma Escola, somos a primeira Escola de Matrículas do Brasil pois carregamos conosco cada vida que passou e passará por aqui.<br/><br/>
          </div>,
          buttonText:<span>CONVERTER MAIS MATRICULAS</span>,          
        },
        testimonials:{
          headline: <span>Veja o que dizem os alunos do workshop</span>,
          testimonialList:[
            {id:1, imageUrl: ''},
            {id:2, imageUrl: ''},
            {id:3, imageUrl: ''},
            {id:4, imageUrl: ''},
            {id:5, imageUrl: ''},
            {id:6, imageUrl: ''},
          ]
        },
        faq:{
          headline: <span>Perguntas Frequentes Respondidas</span>,
          itemList: [
            {id:1, question: <span>PARA QUEM É ESTE TREINAMENTO?</span>,
            resposta:<span>Se você trabalha em um Curso Livre, Técnico, Gradução/Pós ou Escola Regular, seja Presencial Híbrido ou Online, este workshop foi feito para você.
            Aqui você vai entender o que precisa ser alterado no seu script para você conseguir <strong>aumentar suas matrículas de 30% a 70%</strong> ou para você que não tem um script e deseja construir um script que converte do zero</span>},
            { id:2, question: <span>SERVE PARA VENDA DE CURSO ONLINE?</span>, resposta:<span>Sim! As táticas, estratégias e processos de criação de script ensinados no Workshop servem para vender qualquer tipo de curso, seja presencial ou online, independente deles serem caros ou baratos, simples ou complexos.</span> },
            { id:3, question: <span>ESTOU PENSANDO EM DEIXAR PARA DEPOIS.</span>, resposta:<span>Depois pode ser tarde demais. Este evento tem previsão de ser realizado apenas 1x por ano. Inclusive, lembre-se: quanto antes você melhorar o seu script, mais rápido você conseguirá mudar sua realidade e aumentar sua taxa de conversão em matrículas.</span> },
            { id:4, question: <span>SERÁ QUE VOU CONSEGUIR APLICAR?</span>, resposta:<span>Sim! Além do conteúdo ser simples, ele será muito prático e com exemplos do seu dia a dia. O ponto principal é seguir o método, a estratégia e ir aplicando no seu ritmo. O que não pode é entrar no Workshop e não aplicar nada.
            Se aplicar, vai gerar resultados rápidos, consistentes e acima da média.</span> },
            { id:5, question: <span>O QUE RECEBO ASSIM QUE FINALIZO MINHA COMPRA?</span>, resposta:<span>Ao comprar, você recebe seus dados de acesso para participar do Workshop <strong>ao vivo</strong> (que será realizado nos dias 18 e 19 de Junho), instruções para o convite do 2º ingresso cortesia e acesso imediato e completo ao seu bônus.</span> },
            { id:6, question: <span>O TREINAMENTO FICARÁ GRAVADO?</span>, resposta:<span>Não. Este evento será 100% ao vivo e online, sem gravação. Sendo assim, programe-se com antecedência e reserve sua agenda para participar <strong>ao vivo</strong> durante o período do Workshop.</span> },
                
          ],
          buttonText: <span>TIREI AS MINHAS DÚVIDAS E QUERO ENTRAR AGORA</span>,      
        }
    })
    
  return (
    <div className={`bg-gray-800 p-2 text-gray-100 grid grid-cols-1 place-items-center justify-center`}>
        <HeroSection data={data.heroSection}/> 
        <VideoHighlightSection data={data.videoHighlightSection}/>
        <DefinitionsSection data={data.definitionsSection}/>
        <CallToAction1 data={data.callToAction1}/>
        <CallToAction2 data={data.callToAction2}/>
        <CallToAction3 data={data.callToAction3}/>
        <CallToAction4 data={data.callToAction4}/>
        <CallToAction5 data={data.callToAction5}/>
        <CallToAction6 data={data.callToAction6}/>
        <WhoAmI data={data.whoAmI}/>
        <Testimonials data={data.testimonials}/>
        <FAQ data={data.faq}/>
    </div>
  )
}
