import React, {useEffect} from 'react'

export default function DownSellDefault() {

    useEffect(() => {
        const script = document.createElement("script");
        script.text = `
        opts = {
            key: 'USL-52124-126922-7ccee19f573c14cff1586e6ca740d33e',
            width: '650' ,
            height: '260'
            }
            Upsell.Widget.show(opts);
        `;
        script.async = true;
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        }
    }, [])


    return (
        <div className={`w-full flex items-center justify-center`}>
            <div id="box_hotmart"></div>               
            
        </div>
    )
}
