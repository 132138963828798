import React, { useState, useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles';
import {useReferralContext} from './context/ReferralContext'
import { useAuth } from './context/AuthContext';
import { useHistory } from 'react-router-dom'
import {useSnack} from './context/SnackContext'
import Container from '@material-ui/core/Container'
import gsap from 'gsap';
import ReferralProgress from './components/ReferralUi/ReferralProgress';
import MainAppBar from './components/dbRelatedComponents/MainAppBar';

export default function ReferralUI() {

    const [isReferralActivated, setisReferralActivated] = useState(false)
    const {currentUser, receiveCurrentUser, dbUser, isDbUserLoading}  = useAuth()
    const history = useHistory()    
    const {handleUpdateReferralProgramOnUser} = useReferralContext()
    const { handleSnack } = useSnack()

    const orangeFixedRef = useRef(null)
    const blackFixedRef = useRef(null)

    let tl = gsap.timeline({})
    tl.paused( true )
    tl.to(orangeFixedRef.current, 1.6,{
      x:'100%',
      ease: 'sine.inOut'
    })
    tl.to(blackFixedRef.current, 1.5,{
      x:'100%',
      ease: 'sine.inOut',
      delay: '-1.4',
    })

    // Material Ui Styling
    const useStyles = makeStyles( (theme)=>({
        button:{
            color: "#fff"
        }
    }))

    const classes = useStyles();
// End of Material Ui Styling

// Check if the user has indications activated or not
// Only for logged in members

// 1- Generate special link if someone access the app through a referral link
// 2- Notify through snack a welcome sign if referral is identified for easy feedback that the system is working
// 3- If referral is enabled in a user, this user should have a special
// ID added to the user DB that will be used as reference for the referral.   
// 4- Special dashboard that show how many accounts have been created using that referral link in the format of points


// Optional:
// 1- Admin dashboard where all referrals can be seen.
// 2- 


useEffect(() => {      
      if (!receiveCurrentUser) {
        history.push('/login')        
      }     

    }, [])
  

    return (
        <div className={`bg-gray-100`}>

        <MainAppBar />
         <div ref={blackFixedRef} className={`transform -translate-x-full h-screen w-screen bg-custom-black-default fixed top-0 right-0 z-50`}></div>
         <div ref={orangeFixedRef} className={`transform -translate-x-full h-screen w-screen bg-custom-orange-default fixed top-0 right-0 z-50`}></div>

               <Container maxWidth="md"> 
             
              { !isDbUserLoading &&
          <>
              { dbUser.referralSystemEnabledStatus !== undefined ? 
              <div>

                <ReferralProgress/>
                
                </div> :                                  
              
              <div>
                   <div className={`flex items-center justify-center flex-col gap-4`}>
                        <h1 className={`text-6xl font-sans font-bold text-gray-800`}> {`Olá ${currentUser.displayName !== null & !currentUser.displayName !== null ? currentUser.displayName : 'Matriculador(a)'}!`}</h1>   
                        <h2 className={`text-4xl font-sans font-bold text-gray-700`}>Seja Bem-Vindo a sua página de indicações!</h2>
                        <img className={`rounded-full`} src={currentUser.photoURL} alt="" />                  
    
                        <h1>{JSON.stringify(dbUser)}</h1>    
                   </div>
                  <div className={`bg-custom-black-default h-screen w-full`}>
                                
                  </div>
  
                  <div className={`flex items-center justify-center pt-16 pb-16`}>
                    <Button onClick={() => {
                      tl.play()                     
                      handleUpdateReferralProgramOnUser(currentUser)
                      }} size="large" variant="contained" color="primary">
                      <p className={`text-gray-100`}>Ativar Meu Sistema de Indicações!</p>
                    </Button>
                  </div>
  
               </div>
              }  
          </>
            }               
               
               </Container>
            
        </div>
    )
}
