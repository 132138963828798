import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TituloPadrao from './TituloPadrao';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

export default function FAQ(props) {

     // Material Ui Styling
     const useStyles = makeStyles((theme)=>({

        accordionRoot: {
          width: '100%',
        },
        heading: {
          fontSize: theme.typography.pxToRem(15),
          fontWeight: theme.typography.fontWeightRegular,
        },
            timelineRoot:{
              padding: 0,
            },
            paper: {
              padding: '12px 20px',
            },      
            secondaryTail: {
              backgroundColor: theme.palette.secondary.main,
            },
            media: {
              height: 140,
            },
            missingOppositeContent:{
              '&:before':{
                [theme.breakpoints.up('xs')]:{display:'none'}, 
                [theme.breakpoints.up('sm')]:{display:'block'},
                            
                
              }
            },
     
  
      }))
  
      const classes = useStyles();
      // End of Material Ui Styling

    return (
        <div className={`pt-16 pb-16 md:pt-0`}>
            
{ !props.perpetua && <TituloPadrao textColor="gray-900">FAQ- DÚVIDAS FREQUENTES</TituloPadrao> }

<div className={classes.accordionRoot}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Vou ter acesso imediato após a compra?
</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Se a compra foi realizada por cartão de crédito, sim. No caso do boleto, o acesso será liberado logo após a identificação de pagamento.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Quanto tempo terei acesso ao curso?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Por 12 meses.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Para quem é indicado o treinamento?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Para todos aqueles que trabalham em um curso. Seja no segmento de Idiomas, Técnicos, Graduação, Pós ou MBA.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography className={classes.heading}>Consigo “Copiar e colar” os argumentos?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Sim, você terá todas as respostas na ponta da língua para o seu cliente.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography className={classes.heading}>Quais as formas de pagamento?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Você poderá pagar com cartão de crédito e parcelar em até 12x no cartão de crédito. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography className={classes.heading}>Terei apoio durante o treinamento?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Teremos um grupo exclusivo no Telegram, onde você poderá tirar suas dúvidas.
          </Typography>
        </AccordionDetails>
      </Accordion>

      { !props.perpetua && <>
        <p className={`text-2xl font-sans mt-8 mb-8`}>Ainda tem dúvidas? Entre em contato com o nosso suporte:</p>
     <div className={`mt-8 mb-8`}>
              <Button fullWidth={true} variant="contained" size="large" color="primary">
                <div className={`text-gray-200`}>
          Quero Falar com o Suporte
                </div>
              </Button>
     </div>
</>}
    </div>

        </div>
    )
}
