//This context uses AuthContext and SnackContext  

import React, {useContext, createContext} from 'react'
import { useAuth } from './AuthContext'
import { useSnack } from './SnackContext'


const ReferralContext = createContext()

export const useReferralContext = () => {
       return useContext(ReferralContext)
}

export const ReferralContextProvider = (props) => {
    const { handleSnack } = useSnack()
    const {currentUserInDb, userDbUpdater, dbUser} = useAuth()
   

      const handleDbTest = async (user) => {
        //get everything from the user in the db
        userDbUpdater(user)
        handleSnack("success", "Dados foram atualizados!")
      }   
 

    const handleReferralLinkGeneration = (user) => {
      //Link that will be uploaded in the server and also
      //added in the state
      let temporaryCustomReferralLink = `/cadastrar?ref=${user.uid}`

      if (dbUser.customReferralLink !== undefined) {         
        handleSnack("warning", "Link para indicações já existente!")    
      } else{
        console.log(user.customReferralLink);
        currentUserInDb.toApplyMethodOn     
        .update({'customReferralLink':temporaryCustomReferralLink})
        .then( 
          handleSnack("success", "Seu link de indicação foi gerado com sucesso!")
          )
        .catch((err) => {console.log(err);}) 
         userDbUpdater(user)
      }

     

    }

    
    const handleUpdateReferralProgramOnUser= async (user) => {
      if (dbUser.referralSystemEnabledStatus === undefined) {
        currentUserInDb.toApplyMethodOn     
        .update({'referralSystemEnabledStatus':true})
        .then(() => {
          handleSnack("success", "Beneficios de indicação ativados!")
          userDbUpdater(user)
        })
        .catch((err) => {console.log(err);}) 
       
      } else {
        handleSnack("warning", "Esta conta ja tem os beneficios de indicação ativados!")
      }
     
    }

    const handleUpdateReferralCount= (user,type,amount) => {
      // Is used to either add or remove referral counts 
      console.log('clicked!');
    }


  const value={
    handleUpdateReferralProgramOnUser,
    handleDbTest,
    handleUpdateReferralCount,
    handleReferralLinkGeneration,
    
  }

    return(
        <ReferralContext.Provider value={value}>
        <div>
            {props.children}
        </div>
        </ReferralContext.Provider>
    )
}

