import React from 'react'
import TouchAppRoundedIcon from '@material-ui/icons/TouchAppRounded';

export default function BonusDoDiaIcon() {
    return (
        <div style={{height: '100px', width:'150px'}} className="relative w-42 h-auto">
        <div className="border-2 border-black rounded-lg">
        <div className="text-center font-bold text-3xl p-6 border-b-8 shadow-md rounded-lg bg-green-500 border-green-800">BONUS</div>
        
        </div>
        <div className="text-white absolute top-1/2 right-1/3">
        <TouchAppRoundedIcon style={{height:"90px", width:"90px"}}/>
        </div>

        <p className="bg-white text-2xl transform font-bold h-12 rotate-12 w-auto p-2 text-center absolute -right-12 -bottom-4">DO DIA</p>
        </div>
    )
}
