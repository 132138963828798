import React, {useContext, createContext} from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';

const MUIBreakpointsContext = createContext()

export function useMUIBreakpointsContext(){
    return useContext(MUIBreakpointsContext)
}



export function MUIBreakpointsProvider(props) {

// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl

    const theme = useTheme();    

    const xsUp = useMediaQuery(theme.breakpoints.up('xs'))   
    const smUp = useMediaQuery(theme.breakpoints.up('sm'))   
    const mdUp = useMediaQuery(theme.breakpoints.up('md')) 
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'))     
    const xlUp = useMediaQuery(theme.breakpoints.up('xl'))   
 
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'))   
    const smDown = useMediaQuery(theme.breakpoints.down('sm'))   
    const mdDown = useMediaQuery(theme.breakpoints.down('md'))   
    const lgDown = useMediaQuery(theme.breakpoints.down('lg'))   
    const xlDown = useMediaQuery(theme.breakpoints.down('xl'))   


        const value ={
            xsUp,
            smUp,
            mdUp,
            lgUp,
            xlUp,
            xsDown,
            smDown,
            mdDown,
            lgDown,
            xlDown,
        }

    return (
        <MUIBreakpointsContext.Provider value={value}>
        <div>
            {props.children}            
        </div>
        </MUIBreakpointsContext.Provider>
    )
}
