import React, {useState} from 'react'
import {functions} from '../../Firestore';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useHistory } from 'react-router-dom';
import { useSnack } from '../../context/SnackContext';
import { makeStyles } from '@material-ui/styles';



export default function ActiveCampaignExperimentalForm() { 
  
    const handleLogInBackend= functions.httpsCallable('handleLogInBackend')
    const handleActiveCampaignRequest = functions.httpsCallable('handleActiveCampaignRequest')
    const handleFacebookRequest = functions.httpsCallable('handleFacebookRequest')

    const [isLoading, setIsLoading] = useState(false)
    const [pathForApiRequest, setpathForApiRequest] = useState('')
    const [state, setstate] = useState({
        email:'',
        name:''
    })   
    const { handleSnack } = useSnack()
    const history = useHistory()  

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).replace(/\s/g, '').toLowerCase());
    }
    
    const handleSubmit = async () => {
                  
          setIsLoading(true)   
          if (validateEmail(state.email)) {
              if (state.name !== '' && state.name !== undefined && state.name !== null ) {
                await handleActiveCampaignRequest({
                    'method': 'POST',  
                    'path': 'contacts',
                    'list': 23,
                    'body': {
                        "contact": {
                            "email": state.email.replace(/\s/g, '').toLowerCase(),
                            "firstName": state.name,
                        }
                    }                         
               })                   
               .then((doc) => {

                   if (doc.data.messageType !== 'success') {
                    handleSnack(doc.data.messageType, doc.data.message) 
                    history.push({
                        'pathname':'/obrigado',
                        'state': {name: state.name, email:state.email.replace(/\s/g, '').toLowerCase()}
                        })
                   }   
                   
                   if (doc.data.messageType === 'success') {   
                    handleFacebookRequest({
                        eventName:"Lead",
                        email: state.email.replace(/\s/g, '').toLowerCase()
                       })                      
                   history.push({
                    'pathname':'/obrigado',
                    'state': {name: state.name, email:state.email.replace(/\s/g, '').toLowerCase()}
                    })
                   }
                   setIsLoading(false)  
                })
               .catch((err) => {
                   handleSnack('error', 'Algo de errado ocorreu. Confira sua conexão ou tente mais tarde.')
                    console.log(err);
                   setIsLoading(false)  
                })

              } else {
                handleSnack('warning', 'Nome Invalido')
                setIsLoading(false)  
              }

        
          } else {
              handleSnack('warning', 'Email Invalido')
              handleLogInBackend({                   
                  message: `O email "${state.email}" foi apresentado como invalido`,
                  email: state.email  
              })
              setIsLoading(false)  
          }
           
       
    } 




    // Material Ui Styling
    const useStyles = makeStyles( (theme)=>({
        button: {width:'auto', height:'auto'}

    }))

    const classes = useStyles();
    // End of Material Ui Styling
 

     return (
        <div className={`md:mt-0 -mt-4`}>   
            {/* <TextField
              id=""
              label="Path"
              onChange={(e) => {setpathForApiRequest(e.target.value)}}              
            />
            <Button onClick={() => {handleActiveCampaignRequest(
                {
                    'method': 'GET',        
                    'path': pathForApiRequest,            
                }
            )}} variant="contained" color="primary">
              Test Active Campaign
            </Button> */}

            <div className={`bg-grey-200 flex flex-col item-center justify-center w-full h-auto gap-4 p-4`}>
            
                <TextField
                  fullWidth={true}
                  id=""
                  label="Nome Completo"                  
                  onChange={(e) => {setstate((i) =>({...i, name: e.target.value}))}}
                  
                />
                   <TextField
                  fullWidth={true}                  
                  data-name="email"
                  name="email"
                  id="email"
                  label="E-mail"                  
                  onChange={(e) => {
                      setstate((i) =>({...i, email: e.target.value}))                    
                    }}
                  
                />

                <Button fullWidth={true} className={classes.button} disabled={isLoading} onClick={() => {handleSubmit()}} variant="contained" color="primary">
                  <p className={`text-white`}>
                      { isLoading ? 'Processando' :'Quero me Inscrever Agora'}</p>
                </Button>
            </div>

        </div>
    )
}

